import React, { useEffect, useState } from 'react';
import styles from './website-links.module.css';
import { Button, Input, Select, Spin } from 'antd';
import axios from 'axios';
import { BASE_URL, getMobileOrPC } from '../utils/Constants';
import '../MenuShare/styles.css';
import QRCode from 'qrcode.react';

const WebsiteLinks = (): JSX.Element => {
  const isPC = getMobileOrPC();
  const { Option } = Select;

  // const url = 'https://backend.noshpass.com/api';
  const url = BASE_URL;

  const [loading, setLoading] = useState(false);
  const [locationId, setLocationId] = useState<string>('');
  const [locations, setLocations] = useState<any[]>([]);
  const [abTest, setAbTest] = useState<string>('B');
  const [zeroAnyFee, setZeroAnyFee] = useState('false');
  const [promo, setPromo] = useState<number>(0);
  const [id, setId] = useState('');
  const [aptLocation, setAptLocation] = useState('');

  useEffect(() => {
    refreshLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshLocations = async () => {
    const { data } = await axios.get(`${url}/pickupLocations`);
    const res = data.data;
    res.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setLocations(res);
  };

  const refreshWebSiteLinks = async (cpId: string) => {
    setLocationId(cpId);
    setLoading(true);
    setAbTest('B');
    setPromo(0);
    setZeroAnyFee('false');
    setId('');
    const { data } = await axios.get(
      `${url}/tools/get-website-links?id=${cpId}`
    );
    if (data) {
      setAbTest(data.abTest);
      setPromo(data.promo);
      setZeroAnyFee(String(data.isZeroAnyFee));
      setId(data.id);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    await axios.post(`${url}/tools/save-website-links`, {
      id,
      abTest,
      aptLocation: aptLocation,
      aptDBId: locationId,
      promo,
      isZeroAnyFee: zeroAnyFee === 'true' ? true : false,
    });
    refreshWebSiteLinks(locationId);
    setLoading(false);
  };

  return (
    <div
      className={styles.container}
      style={{ maxWidth: isPC ? '450px' : '50000px' }}
    >
      {loading && <Spin size="large" className={styles.loading} />}
      <Select
        showSearch
        defaultValue=""
        style={{ width: '100%', marginBottom: 10, marginTop: 40 }}
        onChange={(e) => {
          const cp = JSON.parse(e);
          console.log(cp.id);
          refreshWebSiteLinks(cp.id);
          setAptLocation(cp.name);
        }}
        filterOption={(input, option) =>
          ((option!.children as unknown) as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {locations.map((v, i) => (
          <Option value={JSON.stringify(v)} key={i}>
            {v.name}
          </Option>
        ))}
      </Select>
      {locationId && (
        <div>
          <div>QRCode</div>
          <QRCode
            value={locationId}
            renderAs="canvas"
            style={{ width: 200, height: 200, marginTop: 20 }}
          />
          {id && (
            <div className={styles.row}>
              <div style={{ textAlign: 'left' }}>
                link: https://home.noshpass.com/{id}
              </div>
            </div>
          )}
          <div className={styles.row}>
            <div style={{ width: 200, textAlign: 'left' }}>abTest</div>
            <Select
              defaultValue=""
              style={{ width: 200, marginLeft: 10 }}
              value={abTest}
              onChange={(e) => {
                console.log(e);
                setAbTest(e);
              }}
            >
              <Option value="A">A</Option>
              <Option value="B">B</Option>
            </Select>
          </div>
          <div className={styles.row}>
            <div style={{ width: 200, textAlign: 'left' }}>isZeroAnyFee</div>
            <Select
              defaultValue=""
              value={zeroAnyFee}
              style={{ width: 200, marginLeft: 10 }}
              onChange={(e) => {
                setZeroAnyFee(e);
              }}
            >
              <Option value="true">true</Option>
              <Option value="false">false</Option>
            </Select>
          </div>
          <div className={styles.row}>
            <div style={{ width: 200, textAlign: 'left' }}>promo</div>
            <Input
              placeholder="Basic usage"
              value={promo}
              onChange={(e) => {
                console.log(e);
              }}
            />
            <input
              style={{
                width: '100%',
                opacity: 0,
                position: 'absolute',
                right: 0,
              }}
              onChange={(e) => setPromo(Number(e.target.value))}
            />
          </div>
          <Button
            type="primary"
            style={{ width: '100%', marginTop: 40 }}
            onClick={() => {
              handleSave();
            }}
          >
            Confirm
          </Button>
        </div>
      )}
    </div>
  );
};

export default WebsiteLinks;
