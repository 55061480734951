import React, { useEffect, useState } from 'react';

const ProgressContainer = (props: {
  helpUsers: any[];
  isFullDownTime: boolean;
  fullDownIndexes: number[];
  activeIndexes: number[];
}): JSX.Element => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const random = Math.floor(Math.random() * 6);
      setIndex(random);
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="main-center-container">
      {new Array(3).fill('').map((v, i) => {
        return (
          <div
            key={i}
            style={{
              position: 'relative',
              marginTop: 5,
              marginLeft: 10,
              marginRight: 10,
              width: 80,
              height: 80,
            }}
          >
            <img
              className="mian-center-img"
              style={{
                animation:
                  index === i ? 'scaleDraw 2s ease-in-out infinite' : undefined,
              }}
              src={require('../assets/images/game/stamp1.png')}
              alt=""
            ></img>
            {props.isFullDownTime && props.activeIndexes.includes(i) && (
              <img
                className="mian-center-img"
                style={{
                  position: 'absolute',
                }}
                src={require('../assets/images/game/stamp2.png')}
                alt=""
              ></img>
            )}
            {props.isFullDownTime && props.fullDownIndexes.includes(i) && (
              <img
                className="mian-center-img"
                style={{
                  position: 'absolute',
                  animation: 'fallDown 2s ease-in-out 1',
                }}
                src={require('../assets/images/game/stamp2.png')}
                alt=""
              ></img>
            )}
            {!props.isFullDownTime && i < props.helpUsers.length && (
              <img
                className="mian-center-img"
                style={{
                  position: 'absolute',
                  animation:
                    index === i
                      ? 'scaleDraw 2s ease-in-out infinite'
                      : undefined,
                }}
                src={require('../assets/images/game/stamp2.png')}
                alt=""
              ></img>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressContainer;
