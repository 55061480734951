import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import axios from 'axios';
import { PublicHolidays } from '../utils/Constants';
import './styles.css';
import {
  getNextOrderWindow,
  isHolidayOrWeekend,
  getCutoffTime,
} from '../utils/time';
import { Spin } from 'antd';
import { useParams } from 'react-router';
import HolidayNoService from './HolidayNoService';
import { useLocation, useHistory } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { appStore, getMobileOrPC, googlePlay } from '../utils/Constants';
import styles from './share-menu.module.css';
import _ from 'lodash';

const Cookie = (): JSX.Element => {
  const { hashKey } = useParams<any>();
  const [selectedDate, setSelectedDate] = useState<Moment>();
  const [weekDays, setWeekDays] = useState<{ orderDate: Moment }[]>();
  const [loading, setLoading] = useState(false);
  const [isHoliday, setIsHoliday] = useState(false);
  const [isAvalible, setIsAvalible] = useState(true);
  const [meals, setMeals] = useState<any>();
  const [cookieInfo, setCookieInfo] = useState<any>(null);
  const [countDown, setCountDown] = useState('');

  const isPC = getMobileOrPC();
  moment.updateLocale('zh', {
    weekdays: [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六',
    ],
  });
  const url = 'https://backend.noshpass.com/api';
  // const url = 'http://localhost:8000/api';

  const amplitudeKey = '21b140df766a7752effb4df18dacad23';
  amplitude.getInstance().init(amplitudeKey);

  const holidays = PublicHolidays;

  const params = new URLSearchParams(useLocation().search);

  const history = useHistory();

  useEffect(() => {
    amplitude.getInstance().logEvent('cookie_web_collectStarted');
    refreshDates();
    getCookie();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cookieInfo) {
      const expiration = moment
        .tz(cookieInfo.createdAt, 'US/Central')
        .add(48, 'hour');
      const timer = setInterval(() => {
        const timeLeft = expiration.diff(
          moment.tz('US/Central'),
          'millisecond'
        );
        setCountDown(secondsTimeFormat(timeLeft / 1000));

        if (timeLeft <= 0) {
          setCookieInfo(null);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [cookieInfo]);

  const secondsTimeFormat = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const second = Math.floor(seconds - hours * 3600 - minutes * 60);

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${second < 10 ? `0${second}` : second}`;
  };

  const getCookie = async () => {
    const { data } = await axios.get(`${url}/gift/hash-key?hashKey=${hashKey}`);
    console.log(111, data);
    setCookieInfo(data);
  };

  const refreshDates = async () => {
    const nextDate = getNextOrderWindow(moment(), holidays, true);
    setSelectedDate(nextDate);
    const monday = nextDate.clone().startOf('isoWeek');

    const { data } = await axios.get(`${url}/serviceDays`, {});

    const wDays = data.map((index) => {
      return {
        orderDate: monday.clone().add(index - 1, 'day'),
      };
    });
    setWeekDays(wDays);
    refreshMenu(nextDate);
  };

  const refreshMenu = async (pickUpDate: Moment) => {
    setLoading(true);
    try {
      // TODO: CHANGE TO ANOTHER ENDPOINT
      const { data } = await axios.get(`${url}/mealsMenu`, {
        params: {
          pickUpDate: pickUpDate?.format('YYYY-MM-DD'),
          lat: params.get('lat') || 29.72861563,
          lng: params.get('lng') || -95.4575555,
        },
      });
      setIsHoliday(false);
      const mealByReso = _.toArray(
        _.groupBy(data.meals, (o) => o.Restaurant.id)
      );
      setMeals(mealByReso);
    } catch (e) {
      setIsHoliday(true);
    } finally {
      setLoading(false);
      const now = moment();
      setIsAvalible(true);
      if (now.isSame(pickUpDate, 'date')) {
        const LEReservationEnd = getCutoffTime(now.clone(), true);
        setIsAvalible(now.isBefore(LEReservationEnd));
      } else if (now.isAfter(pickUpDate, 'date')) {
        setIsAvalible(false);
      }
    }
  };

  const gotoAppStore = (position: 'header' | 'footer') => {
    return (
      <div className="app-store">
        <img
          className="app-store-img"
          onClick={() => {
            window.open(googlePlay);
          }}
          alt="google-play"
          src={require('../assets/images/menu-share/google-play.png')}
        />
        <img
          className="app-store-img"
          onClick={() => {
            window.open(appStore);
          }}
          alt="app-store"
          src={require('../assets/images/menu-share/app-store.png')}
        />
      </div>
    );
  };

  const otherTag = (title: string) => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          padding: '2px 10px',
          backgroundColor: '#F06A20',
          borderTopLeftRadius: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {title === 'NEW' && (
          <img
            src={require('../assets/icons/newHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'HOT' && (
          <img
            src={require('../assets/icons/popularHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'CHEF CHOICE' && (
          <img
            src={require('../assets/icons/chef_choiceHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'REHEATABLE' && (
          <img
            src={require('../assets/icons/reheatableHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        <span
          style={{
            fontSize: 10,
            fontWeight: 'bold',
            color: '#ffffff',
          }}
        >
          {title}
        </span>
      </div>
    );
  };

  return (
    <div
      className="container-share"
      style={{ maxWidth: isPC ? '450px' : '50000px' }}
    >
      <div style={{ height: '30px', width: '100%' }}>
        <div className="head-logo">
          <img
            className="share-logo-img"
            alt=""
            src={require('../assets/images/menu-share/plain-logo.png')}
          />
          <span>Delivery without the Fees.</span>
        </div>
      </div>
      <div className="content">
        <div className="tabs">
          {weekDays &&
            weekDays.map((data) => {
              const { orderDate: tagDate } = data;
              const now = moment();
              let tabClass = '';
              if (selectedDate?.isSame(tagDate, 'date')) {
                tabClass = 'tab-active';
              } else if (isHolidayOrWeekend(tagDate, holidays)) {
                tabClass = 'tab-inactive';
              } else if (now.isSame(tagDate, 'date')) {
                const LEReservationEnd = getCutoffTime(now.clone(), true);
                tabClass = now.isBefore(LEReservationEnd) ? '' : 'tab-inactive';
              } else if (now.isAfter(tagDate, 'date')) {
                tabClass = 'tab-inactive';
              }

              return (
                <div
                  className={`tab ${tabClass}`}
                  key={data.orderDate.toISOString()}
                  onClick={async () => {
                    amplitude.getInstance().logEvent('home_screen_tag');
                    setSelectedDate(tagDate);
                    await refreshMenu(tagDate);
                  }}
                >
                  {tagDate.format('DD')} {tagDate.format('ddd')}
                </div>
              );
            })}
        </div>
        {isHoliday ? (
          <HolidayNoService />
        ) : (
          <div className="list-container">
            <div className="list">
              {!!meals &&
                meals.length &&
                meals.map((v) => (
                  <div className={styles.resoList} key={v[0].Restaurant.id}>
                    {!!v.length &&
                      v.map((val) => (
                        <div
                          className={styles.listCard}
                          key={val.id}
                          onClick={() => {
                            history.push(`../meal-detail?id=${val.id}`);
                          }}
                        >
                          {val.color && (
                            <div
                              className="card-color"
                              style={{ backgroundColor: val.color }}
                            >
                              <span>{'Meal ' + val.mealSetChar}</span>
                              {val.tags.length ? (
                                <span className="card-cusine">
                                  {val.tags[0]}
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </div>
                          )}
                          <div className="card-img-container">
                            {!val.isOriginalPrice && (
                              <div className="card-sales">
                                <div className="tag-origin-price">
                                  <span className="card-price">
                                    ${val.estimatedMealPrice.toFixed(2)}
                                  </span>
                                </div>
                                <img
                                  alt=""
                                  className="card-sales-img"
                                  src="https://rp-public-assets-us.s3.us-west-2.amazonaws.com/gallery/low8.99.png"
                                />
                              </div>
                            )}
                            {!!val.isOriginalPrice && (
                              <div className="card-sales2">
                                <div className="tag-origin-price">
                                  <span className="card-price2">
                                    ${val.estimatedMealPrice.toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            )}
                            {!!val.isOriginalPrice && (
                              <img
                                src="https://rp-public-assets-us.s3.us-west-2.amazonaws.com/gallery/ZeroAnyFee.png"
                                style={{
                                  width: 180,
                                  height: 28,
                                  position: 'absolute',
                                  top: 10,
                                }}
                                alt=""
                              />
                            )}
                            {!!val.isNew && otherTag('NEW')}
                            {!!val.isPopular && otherTag('HOT')}
                            {!!val.isChefChoice && otherTag('CHEF CHOICE')}
                            {!!val.isReheatable && otherTag('REHEATABLE')}
                            {!isAvalible && (
                              <div className="card-img-avalible">
                                <img
                                  alt=""
                                  className="no-avalible-img"
                                  src={require('../assets/images/menu-share/time.png')}
                                />
                                <div className="no-avalible">
                                  This meal has passed the reservation window.
                                </div>
                              </div>
                            )}
                            <img
                              className="card-img"
                              alt=" "
                              src={val.firebaseImgFilename}
                            />
                          </div>
                          <div className="card-resturant">{val.title}</div>
                          <div className="card-meal">
                            <span>{val.Restaurant.title}</span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                              flexDirection: 'row',
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 4,
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {val.tags.includes('Halal') && (
                                <img
                                  className="card-portion-meal"
                                  alt=""
                                  src={require(`../assets/icons/halal.png`)}
                                />
                              )}
                              {val.tags.includes('Gluten') && (
                                <img
                                  className="card-portion-meal"
                                  alt=""
                                  src={require(`../assets/icons/gluten-free.png`)}
                                />
                              )}
                              {val.tags.includes('Veg') && (
                                <img
                                  className="card-portion-meal"
                                  alt=""
                                  src={require(`../assets/icons/veg.png`)}
                                />
                              )}
                              {val.tags.includes('Nut-Free') && (
                                <img
                                  className="card-portion-meal"
                                  alt=""
                                  src={require(`../assets/icons/nut-free.png`)}
                                />
                              )}
                              {val.tags.includes('Dairy-Free') && (
                                <img
                                  className="card-portion-meal"
                                  alt=""
                                  src={require(`../assets/icons/dairy-free.png`)}
                                />
                              )}
                              {val.tags.includes('Vegan') && (
                                <img
                                  className="card-portion-meal"
                                  alt=""
                                  src={require(`../assets/icons/vegan.png`)}
                                />
                              )}
                            </div>

                            <div
                              className="protion"
                              style={{ marginLeft: 6, marginBottom: 4 }}
                            >
                              Portion:
                              <img
                                className="card-portion"
                                alt=""
                                src={require(`../assets/images/menu-share/portion-${val.reviewPortion}.png`)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}

              {!!meals?.length && (
                <div className="bottom">
                  <div className="bottom-txt1">
                    Want to stop paying any fees?
                  </div>
                  <div className="bottom-txt2">Download NoshPass Now!</div>
                  {gotoAppStore('footer')}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {loading && (
        <div className="loading">
          <Spin
            spinning={true}
            delay={100}
            size="large"
            className="spin"
          ></Spin>
        </div>
      )}
      {!!cookieInfo && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#00000080',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div
            style={{
              width: 340,
              minHeight: 440,
              background: '#ffffff',
              borderRadius: 20,
              justifyContent: 'center',
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <img
              style={{
                width: 200,
                height: 140,
                marginTop: 30,
              }}
              onClick={() => {}}
              alt="google-play"
              src={require('../assets/images/menu-share/gift_purchase.png')}
            />
            <div style={{ width: 300, marginTop: 10 }}>
              {!cookieInfo.isExpired ? (
                <span role="img" aria-label="donut">
                  {`Hi ${cookieInfo.toName}! You received a real 🍪 Crumble cookie
          free gift from ${cookieInfo.fromName}. To claim: \n(1) tap button
          below to download app \n(2) tap "register", then follow steps
          specify delivery details.`}
                </span>
              ) : (
                <span role="img" aria-label="donut">
                  You had received a real 🍪 Crumble cookie from Tarzan. Sadly,
                  you didn’t accept it
                  <span style={{ fontWeight: 600 }}> FREE</span> and the gift
                  expired. Fear not, you can still send a gift back to{' '}
                  {cookieInfo.toName} and say thanks!
                </span>
              )}
            </div>
            <div
              onClick={() => {
                window.location.href = 'https://nosh.page.link/6ih7';
              }}
              style={{
                width: 300,
                background: '#F06A20',
                height: 48,
                borderRadius: 24,
                color: '#ffffff',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                fontWeight: 'bold',
              }}
            >
              <span role="img" aria-label="donut">
                COLLECT CRUMBL 🍪 GIFT
              </span>
            </div>
            {!cookieInfo.isExpired && (
              <div style={{ fontSize: 14, marginTop: -10 }}>
                You’ll download an app to accept gift.
              </div>
            )}
            {!!countDown && !cookieInfo.isExpired && (
              <div style={{ fontSize: 14, color: '#F06A20', marginTop: -20 }}>
                <span style={{ fontWeight: 'bold', color: '#D8317E' }}>
                  {countDown}
                </span>{' '}
                time left before forfeiting gift
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Cookie;
