import React, { useEffect, useState } from 'react';
import amplitude from 'amplitude-js';
import { useLocation } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';

import './styles.css';

const ReferralStatic = (): JSX.Element => {
  const [isPC, setIsPc] = useState(true);
  const userAgentInfo = navigator.userAgent;
  const params = new URLSearchParams(useLocation().search);
  const [value] = useState(params.get('value'));

  const href = window.location.href;

  const amplitudeKey =
    href.indexOf('stage.ricepass') < 0
      ? '21b140df766a7752effb4df18dacad23'
      : 'ca7407f6985c43ce3ff6fa4c4ee1a71d';
  amplitude.getInstance().init(amplitudeKey);

  useEffect(() => {
    const Agents = [
      'Android',
      'iPhone',
      'SymbianOS',
      'Windows Phone',
      'iPad',
      'iPod',
    ];
    let flag = true;
    for (const item of Agents) {
      if (userAgentInfo.indexOf(item) > 0) {
        flag = false;
        break;
      }
    }
    setIsPc(flag);
    amplitude.getInstance().logEvent('referral_static_screen');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const copyLink = () => {
    if (!value) {
      toast.error('Please upgrade the app to activate the feature.');
      return;
    }
    copy(
      `Enjoy a $4.99/combo lunch delivered FREE! 😉🍱 https://nosh.page.link/${
        value || ''
      }`
    );
    toast.success('Copied!');
  };

  return (
    <div
      className="container-referral"
      style={{
        maxWidth: isPC ? '450px' : '50000px',
      }}
    >
      <Toaster position="bottom-center" />
      <img
        className="referral-head"
        alt=""
        src={require('../assets/images/referral/Clapping.png')}
      />
      <img
        className="referral-head2"
        alt=""
        src={require('../assets/images/referral/Gift.png')}
      />
      <div className="referral-h1">How to Earn FREE Lunch</div>
      <div className="referral-h2">(Every Day)</div>
      <div className="referral-btn" onClick={() => copyLink()}>
        Tap to Copy Link
      </div>
      <div className="referral-block">
        <div className="referral-step">Step 1</div>
        <div className="referal-stap-text">
          Get <span className="bold-text">any</span> friends to enjoy the
          <span className="bold-text"> $4.99/Combo</span> promo code and order a
          lunch.
        </div>
      </div>
      <div className="referral-block">
        <div className="referral-step">Step 2</div>
        <div className="referal-stap-text">
          Text friend's registered mobile number to NoshPass at 832-583-2900.
        </div>
      </div>
      <div className="referral-block">
        <div className="referral-step">Step 3</div>
        <div className="referal-stap-text">
          We’ll give you a FREE lunch credit (1 credit = 1 of any lunch combo)
          upon verification.
        </div>
      </div>
      <div className="referral-h3">
        Repeat Steps 1 to 3 to get{' '}
        <span style={{ fontWeight: 'bold' }}>unlimited</span> free lunches every
        day. <span style={{ fontWeight: 'bold' }}>Limited time only.</span>
      </div>
    </div>
  );
};

export default ReferralStatic;
