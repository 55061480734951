import React from 'react';
import { Spin, Row } from 'antd';

const Loading = (): JSX.Element => {
  return (
    <Row style={{ height: '100vh' }} justify="center" align="middle">
      <Spin size="large" />
    </Row>
  );
};

export default Loading;
