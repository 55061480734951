export const PublicHolidays = [
  '2021-10-01T00:00:00.000Z',
  '2021-10-14T00:00:00.000Z',
  '2021-12-27T00:00:00.000Z',
  '2022-01-01T00:00:00.000Z',
  '2022-02-01T00:00:00.000Z',
  '2022-01-01 00:00:00.00Z',
  '2022-02-01 00:00:00.00Z',
  '2022-02-02 00:00:00.00Z',
  '2022-02-03 00:00:00.00Z',
  '2022-04-05 00:00:00.00Z',
  '2022-04-15 00:00:00.00Z',
  '2022-04-18 00:00:00.00Z',
  '2022-05-01 00:00:00.00Z',
  '2022-05-02 00:00:00.00Z',
  '2022-05-09 00:00:00.00Z',
  '2022-06-03 00:00:00.00Z',
  '2022-07-01 00:00:00.00Z',
  '2022-09-10 00:00:00.00Z',
  '2022-10-01 00:00:00.00Z',
  '2022-10-04 00:00:00.00Z',
  '2022-12-26 00:00:00.00Z',
  '2022-12-27 00:00:00.00Z',
];

export const googlePlay =
  'https://play.google.com/store/apps/details?id=com.ricepass.mobile.nosh&referrer=micro-site';
export const appStore =
  'https://apps.apple.com/us/app/noshpass-lunch-express/id1590803053';

export const getMobileOrPC = () => {
  const userAgentInfo = navigator.userAgent;
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ];
  let flag = true;
  for (const item of Agents) {
    if (userAgentInfo.indexOf(item) > 0) {
      flag = false;
      break;
    }
  }

  return flag;
};

export const MENU_SORT = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  // 'A7',
  // 'A8',
  // 'A9',
  // 'A10',
  // 'A11',
  // 'A12',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'B6',
  // 'B7',
  // 'B8',
  // 'B9',
  // 'B10',
  // 'B11',
  // 'B12',
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'C6',
  // 'C7',
  // 'C8',
  // 'C9',
  // 'C10',
  // 'C11',
  // 'C12',
  'D1',
  'D2',
  'D3',
  'D4',
  'D5',
  'D6',
  // 'D7',
  // 'D8',
  // 'D9',
  // 'D10',
  // 'D11',
  // 'D12',
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
  'E6',
  // 'E7',
  // 'E8',
  // 'E9',
  // 'E10',
  // 'E11',
  // 'E12',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  // 'F7',
  // 'F8',
  // 'F9',
  // 'F10',
  // 'F11',
  // 'F12',
  'G1',
  'G2',
  'G3',
  'G4',
  'G5',
  'G6',
  // 'G7',
  // 'G8',
  // 'G9',
  // 'G10',
  // 'G11',
  // 'G12',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  // 'H7',
  // 'H8',
  // 'H9',
  // 'H10',
  // 'H11',
  // 'H12',
];

export const WESTERN_TAGS = [
  'french',
  'greek',
  'italian',
  'japanese',
  'korean',
  'malaysian',
  'mexican',
  'spanish',
  'tex mex',
  'american',
  'australian',
  'brishish',
  'pasta',
  'pizza',
  'taco',
  'turkey',
];
export const ASIAN_TAGS = [
  'chinese',
  'hong kong',
  'indian',
  'indonesian',
  'korean',
  'japanese',
  'thai',
  'vietnamese',
  'sushi',
  'filipino',
];

export const S3_IMAGE_URL =
  'https://rp-public-assets-us.s3.us-west-2.amazonaws.com/gallery/pickupLocations/';

export const BASE_URL = 'https://backend.noshpass.com/api';
// export const BASE_URL = 'http://localhost:8000/api';

// export const SIMPLE_URL = 'https://rpass.co/test/api';
export const SIMPLE_URL = 'https://rpass.co/api';
// export const SIMPLE_URL = 'http://localhost:8000/api';

// export const BASE_URL = 'http://52.39.180.97:8000/api';
