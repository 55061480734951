import React, { useEffect, useState } from 'react';
import styles from './setting.module.css';
import { Button, Select, Spin } from 'antd';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import { BASE_URL, getMobileOrPC } from '../utils/Constants';
// import 'draft-js/dist/Draft.css';
import TextArea from 'antd/lib/input/TextArea';

const ResoInstruction = (): JSX.Element => {
  const isPC = getMobileOrPC();
  const { Option } = Select;

  const url = BASE_URL;

  const [loading, setLoading] = useState(false);
  const [reso, setReso] = useState<any[]>([]);
  const [resoInfo, setResoInfo] = useState<any>();
  const [instruction, setInstruction] = useState('');

  useEffect(() => {
    getRestaurant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRestaurant = async () => {
    const { data } = await axios.get(`${url}/restaurants/list`);
    const resos = data.data;
    resos.sort((a, b) => {
      return a.title?.localeCompare(b.title);
    });
    setReso(resos);
  };

  const changeCPInfo = async (id) => {
    const c = reso.find((v) => v.id === id);
    setResoInfo(c);

    setInstruction(c.instruction);
  };

  return (
    <div
      className={styles.container}
      style={{ maxWidth: isPC ? '650px' : '50000px', padding: 0 }}
    >
      {loading && <Spin size="large" className={styles.loading} />}
      <Toaster position="top-center" />
      <div
        style={{
          marginTop: 40,
          width: '100%',
          textAlign: 'left',
        }}
      >
        Restaurant:
      </div>
      <Select
        defaultValue=""
        style={{
          width: '100%',
          marginBottom: 10,
          marginTop: 10,
        }}
        onChange={(e) => {
          changeCPInfo(e);
        }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          ((option!.children as unknown) as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {reso.map((v, i) => (
          <Option value={v.id} key={i}>
            {v.title}
          </Option>
        ))}
      </Select>

      <div
        style={{
          width: '100%',
          marginTop: 10,
          marginBottom: 10,
          boxSizing: 'border-box',
        }}
      >
        <div style={{ textAlign: 'left', marginBottom: 10 }}>
          Parking instructions
        </div>
        <TextArea
          style={{ height: 400 }}
          value={instruction}
          onChange={(e) => {
            setInstruction(e.target.value);
          }}
        />
      </div>
      <Button
        style={{
          width: '100%',
          alignItems: 'center',
          height: 40,
          padding: 0,
          textAlign: 'center',
          marginBottom: 50,
        }}
        onClick={async () => {
          setLoading(true);
          try {
            await axios.post(`${url}/user/save-restaurant-instruction`, {
              id: resoInfo.id,
              instruction,
            });
            await getRestaurant();
            alert('update success');
          } catch (e) {
            alert('someting wrong');
          }
          setLoading(false);
        }}
        type="primary"
        className={`black-button`}
        size="small"
        htmlType="submit"
      >
        Submit
      </Button>
    </div>
  );
};

export default ResoInstruction;
