import React, { FC } from 'react';
import 'react-awesome-animated-number/dist/index.css';
import styles from './meal-card.module.css';

import { MealType } from '../Restaurant';
import { DeleteOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

export interface MealCardProps {
  meal: MealType;
  showClose: boolean;
  onClose?: () => void;
}

export const MealCard: FC<MealCardProps> = (props: MealCardProps) => {
  const content = (
    <div style={{ width: 300 }}>
      <p>
        <span style={{ fontWeight: 700 }}>tags: </span>{' '}
        {props.meal.tags.join(', ')}
      </p>
      <p>
        <span style={{ fontWeight: 700 }}>description: </span>{' '}
        {props.meal.description}
      </p>
    </div>
  );

  return (
    <div className={styles.mealItem2}>
      <div className={styles.mealContent}>
        {props.showClose && (
          <div className={styles.close} onClick={props.onClose}>
            <DeleteOutlined />
          </div>
        )}
        <div className={styles.mealHead}>
          <div
            className={styles.mealType2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>{props.meal.mealTypeId}</span>
            <div>
              <Popover content={content}>
                <UnorderedListOutlined
                  style={{
                    color: 'white',
                    marginBottom: 10,
                    marginLeft: 10,
                  }}
                />
              </Popover>
            </div>
          </div>
          {!!props.meal.tags && !!props.meal.tags.length && (
            <div className={styles.mealTag1}>{props.meal.tags[0]}</div>
          )}
        </div>
        <img
          src={props.meal.firebaseImgFilename}
          className={styles.mealImg}
          alt=""
        />
        <div className={styles.mealTags}>
          <div>
            {props.meal.tags.includes('Halal') && (
              <img
                className="card-portion-meal"
                alt=""
                src={require(`../../assets/icons/halal.png`)}
              />
            )}
            {props.meal.tags.includes('Gluten') && (
              <img
                className="card-portion-meal"
                alt=""
                src={require(`../../assets/icons/gluten-free.png`)}
              />
            )}
            {props.meal.tags.includes('Veg') && (
              <img
                className="card-portion-meal"
                alt=""
                src={require(`../../assets/icons/veg.png`)}
              />
            )}
            {props.meal.tags.includes('Nut-Free') && (
              <img
                className="card-portion-meal"
                alt=""
                src={require(`../../assets/icons/nut-free.png`)}
              />
            )}
            {props.meal.tags.includes('Dairy-Free') && (
              <img
                className="card-portion-meal"
                alt=""
                src={require(`../../assets/icons/dairy-free.png`)}
              />
            )}
            {props.meal.tags.includes('Vegan') && (
              <img
                className="card-portion-meal"
                alt=""
                src={require(`../../assets/icons/vegan.png`)}
              />
            )}
          </div>
          <div className={styles.portion}>
            Portion:
            <img
              className="card-portion"
              alt=""
              src={require(`../../assets/images/menu-share/portion-${props.meal.reviewPortion}.png`)}
            />
          </div>
        </div>
        <div className={styles.mealTitle}>
          <div
            style={{
              color: '#F06A20',
              fontWeight: 'bold',
              width: 200,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {props.meal.title}
          </div>
        </div>
        <div className={styles.mealPriceContext}>
          <img
            className={styles.mealPrice}
            alt=""
            src={require(`../../assets/icons/mealPrice.png`)}
          />
          <div className={styles.price1}>
            ${props.meal.isOriginalPrice ? props.meal.estimatedMealPrice : 9.99}
          </div>
          <div className={styles.price2}>{props.meal.doordashPrice}</div>
        </div>
      </div>
    </div>
  );
};

export default MealCard;
