import React from 'react';
import './noservice.css';

const NoInServiceArea = (): JSX.Element => {
  return (
    <div className="no-service">
      <img
        className="no-service-img"
        alt=""
        src={require('../assets/images/menu-share/noMenu.png')}
      />
      <div className="happy-holiday">
        Sorry, you are not in our service area.
      </div>
      <div>We are working hard to expand!</div>
    </div>
  );
};

export default NoInServiceArea;
