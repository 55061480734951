import moment, { Moment } from 'moment-timezone';

/**
 * Contains all the time constants we use in the system.
 */
const TimeConstants = {
  RerservationEarlyDeadline: () => {
    return moment().set({ hours: 11, minutes: 0, second: 0 });
  },

  OrderChangeDeadline: () => {
    return moment().set({ hours: 11, minutes: 0, second: 0 });
  },

  EarlyPickupTime: () => {
    return moment().set({ hour: 12, minute: 0, second: 0 });
  },

  LatePickupTime: () => {
    return moment().set({ hour: 13, minute: 0, second: 0 });
  },

  GlobalPickupEndTime: () => {
    return moment().set({ hour: 15, minute: 10, second: 0 });
  },

  NextDayReservationStart: () => {
    return moment().set({ hours: 12, minutes: 0, second: 0 });
  },

  /**
   * We use the date on the now Moment object, but set the hour to 11AM.
   */
  LunchExpressReservationCutoffTime: (now?: Moment) => {
    return (now?.clone() || moment()).set({ hours: 11 }).startOf('hour');
  },

  PUCutoffTime: (now?: Moment) => {
    return (now?.clone() || moment()).set({ hours: 12 }).startOf('hour');
  },

  LunchExpressEndTime: () => {
    return moment().set({ hours: 14, minutes: 15, second: 0 });
  },
};

export default TimeConstants;
