import React, { useEffect, useState } from 'react';
import styles from './reso-meals.module.css';
import { Select, Spin } from 'antd';
import axios from 'axios';
import { BASE_URL, getMobileOrPC } from '../utils/Constants';
import '../MenuShare/styles.css';

const ResoMeals = (): JSX.Element => {
  const isPC = getMobileOrPC();
  const { Option } = Select;

  // const url = 'https://backend.noshpass.com/api';
  const url = BASE_URL;

  const [loading, setLoading] = useState(false);
  const [meals, setMeals] = useState<any[]>([]);
  const [restaurants, setRestaurants] = useState<any[]>([]);

  useEffect(() => {
    refreshRestaurant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshRestaurant = async () => {
    const { data } = await axios.get(`${url}/restaurants/list`);
    const res = data.data;
    res.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
    setRestaurants(res);
  };

  const refreshMenu = async (restaurantId) => {
    setLoading(true);
    const { data } = await axios.get(
      `${url}/meal-by-restaurant?restaurantId=${restaurantId}`
    );
    setLoading(false);
    setMeals(data);
  };

  return (
    <div
      className={styles.container}
      style={{ maxWidth: isPC ? '450px' : '50000px' }}
    >
      {loading && <Spin size="large" className={styles.loading} />}
      <Select
        defaultValue=""
        style={{ width: '100%', marginBottom: 10, marginTop: 40 }}
        onChange={(e) => {
          refreshMenu(e);
        }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          return (
            !!option!.children &&
            ((option!.children as unknown) as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          );
        }}
      >
        {restaurants.map((v, i) => (
          <Option value={v.id} key={i}>
            {v.title}
          </Option>
        ))}
      </Select>
      {meals.map((val) => {
        return (
          <div className="list-card" key={val.id}>
            <div className="card-color" style={{ backgroundColor: 'red' }}>
              <span></span>
              {val.tags.length ? (
                <span className="card-cusine">{val.tags[0]}</span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="card-img-container">
              <div className="card-sales">
                <div className="tag-origin-price">
                  <span className="card-price">
                    ${val.estimatedMealPrice.toFixed(1)}
                  </span>
                </div>
                <img
                  alt=""
                  className="card-sales-img"
                  src={require('../assets/images/menu-share/priceLow.png')}
                />
              </div>
              <img
                className="card-img"
                style={{ height: 220 }}
                alt=" "
                src={val.firebaseImgFilename}
              />
            </div>
            <div className="card-resturant">{val.title}</div>
            <div className="card-meal">
              <span>{val.Restaurant.title}</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <span className="protion" style={{ marginLeft: 6 }}>
                  Portion:
                  <img
                    className="card-portion"
                    alt=""
                    src={require(`../assets/images/menu-share/portion-${val.reviewPortion}.png`)}
                  />
                </span>
              </div>
            </div>
            <div className={styles.description} style={{ marginTop: 10 }}>
              {!!val.tags && val.tags.length && (
                <div>
                  {val.tags.includes('Halal') && (
                    <img
                      className="card-portion"
                      alt=""
                      src={require(`../assets/icons/halal.png`)}
                    />
                  )}
                  {val.tags.includes('Gluten') && (
                    <img
                      className="card-portion"
                      alt=""
                      src={require(`../assets/icons/gluten-free.png`)}
                    />
                  )}
                  {val.tags.includes('Veg') && (
                    <img
                      className="card-portion"
                      alt=""
                      src={require(`../assets/icons/veg.png`)}
                    />
                  )}
                  {val.tags.includes('Nut-Free') && (
                    <img
                      className="card-portion"
                      alt=""
                      src={require(`../assets/icons/nut-free.png`)}
                    />
                  )}
                  {val.tags.includes('Dairy-Free') && (
                    <img
                      className="card-portion"
                      alt=""
                      src={require(`../assets/icons/dairy-free.png`)}
                    />
                  )}
                  {val.tags.includes('Vegan') && (
                    <img
                      className="card-portion"
                      alt=""
                      src={require(`../assets/icons/vegan.png`)}
                    />
                  )}
                  {val.tags.includes('Keto') && (
                    <img
                      className="card-portion"
                      alt=""
                      src={require(`../assets/icons/keto.png`)}
                    />
                  )}
                </div>
              )}
            </div>
            <div className={styles.description} style={{ marginTop: 10 }}>
              promoWeek: {val.promoWeek}
            </div>
            <div className={styles.description} style={{ marginTop: 10 }}>
              totalOrders: {val.totalOrders}
            </div>
            <div className={styles.description} style={{ marginTop: 10 }}>
              favoriteRate: {val.favoriteRate}
            </div>
            <div className={styles.description} style={{ marginTop: 10 }}>
              description:
            </div>
            <div className={styles.description}>{val.description}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ResoMeals;
