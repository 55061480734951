import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEOHeader = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>NoshPass - Delivery without the Fees</title>

      {/* google tags */}
      <meta name="author" content="xeptagon pvt ltd" />
      <meta name="title" content="NoshPass - Food Hall at Your Doorstep" />
      <meta name="description" content={t('seo.a2.description')} />
      <meta
        name="keywords"
        content="Noshpass, NoshPass, Nosh Pass, Food Takeaway, Lunch Deal, Oakland, San Jose"
      />

      {/* facebook tags */}
      <meta
        property="og:title"
        content="NoshPass - Food Hall at Your Doorstep"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://noshpass.com" />
      <meta property="og:site_name" content="NoshPass" />
      <meta
        property="og:image"
        content="https://noshpass.com/meta-preview.jpg"
      />
      <meta
        property="og:image"
        content="http://stage.noshpass.com/meta-preview.jpg"
      />
      <meta
        property="og:image"
        content="https://noshpass.com/meta-preview.jpg"
      />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:description" content={t('seo.a2.description')} />
      {/* twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={t('seo.a2.description')} />
      <meta name="twitter:url" content="https://noshpass.com" />
      <meta
        name="twitter:title"
        content="NoshPass - Food Hall at Your Doorstep"
      />
      <meta
        name="twitter:image"
        content="https://noshpass.com/meta-preview.jpg"
      />
      <meta name="twitter:image:alt" content="NoshPass" />
    </Helmet>
  );
};

export default SEOHeader;
