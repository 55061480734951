import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

const CountDown = (): JSX.Element => {
  const [countDown, setCountDown] = useState('--:--:--');
  const [ms, setMs] = useState(9);

  useEffect(() => {
    const timer = setInterval(() => {
      const day = moment
        .tz('US/Central')
        .isAfter(
          moment.tz('US/Central').set({ hour: 10, minute: 30, second: 0 })
        )
        ? moment.tz('US/Central').add(1, 'day')
        : moment.tz('US/Central');
      const timeLeft = day
        .set({ hour: 10, minute: 30, second: 0 })
        .diff(moment.tz('US/Central'), 'milliseconds');

      setCountDown(secondsTimeFormat(timeLeft));
    }, 1000);

    setMs(ms - 1);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (ms === 0) {
        setMs(9);
      } else {
        setMs(ms - 1);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms]);

  const secondsTimeFormat = (millisecond: number) => {
    const seconds = millisecond / 1000;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const second = Math.floor(seconds - hours * 3600 - minutes * 60);

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${second < 10 ? `0${second}` : second}`;
  };

  return (
    <>
      <span style={{ color: '#BD4400' }}>
        {countDown}.{countDown !== '--:--:--' && <span>{ms}</span>}
      </span>
    </>
  );
};

export default CountDown;
