import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styles from './driver.module.scss';
import Loading from '../Components/Loading';
import Restaurant from './components/restaurant';
import moment from 'moment-timezone';

const DriverRoleReso = (): JSX.Element => {
  const { hashKey } = useParams<any>();

  const url = 'https://noshinc.com/api';
  const [loading, setLoading] = useState(false);
  const [driverRoutes, setDriverRoutes] = useState<any>();
  const [invalidLink, setInvalidLink] = useState(false);
  const [enableGeo, setEnableGeo] = useState(false);
  const [geoErrMsg, setGeoErrMsg] = useState('');
  const [showTip, setShowTip] = useState(false);

  useEffect(() => {
    const currentTime = moment
      .tz('US/Central')
      .set({ hour: 10, minute: 30, second: 0 });
    if (moment.tz('US/Central').isAfter(currentTime)) {
      setShowTip(true);
    }

    getDriverRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      setEnableGeo(true);
    } else {
      setEnableGeo(false);
      alert('Location services are not supported');
    }
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (v) => {
        console.log(v.coords.latitude, v.coords.longitude);
        setGeoErrMsg('');
      },
      (err) => {
        console.log(err);
        setGeoErrMsg(
          'Failed to obtain location, please enable location permission'
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableGeo, driverRoutes]);

  const getDriverRoutes = async () => {
    setLoading(true);
    const uri = `${url}/driver/routes/${hashKey}`;
    const { data } = await Axios.get(uri);
    setLoading(false);
    if (data === null || !!data.totalOrders) {
      setInvalidLink(true);
      return;
    }

    // 控制按钮loading
    data.u1ArrivedLoading = false;
    data.u1LeftLoading = false;
    data.orderInRestaurant.forEach((v) => {
      v.arrivedLoading = false;
      v.leftLoading = false;
      v.delayLoading = false;
    });
    data.orderInLocations.forEach((v) => {
      v.arrivedLoading = false;
      v.leftLoading = false;
      v.uploadLoading = false;
    });

    setDriverRoutes([data]);
  };

  return invalidLink ? (
    <div style={{ margin: '50px 20px' }}>
      Link invalid, make sure you are using link for today.
    </div>
  ) : (
    <div className={styles.shiftBody}>
      {loading && <Loading />}
      {!loading && (
        <div className={styles.container}>
          <div className={styles.warnTxt}>{geoErrMsg}</div>
          {showTip && (
            <div className={styles.warnTxt} style={{ color: 'blue' }}>
              New NoshStop Instruction Link Provided, Switch Over if On Duty for
              NoshStops
            </div>
          )}
          {driverRoutes && <Restaurant driverRoutes={driverRoutes} />}
        </div>
      )}
    </div>
  );
};

export default DriverRoleReso;
