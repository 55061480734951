import React, { useState, createContext } from 'react';

interface IUserStatus {
  isVerified: boolean;
  isSurvey: boolean;
  mobileNumber?: string;
  setIsVerified: (value: boolean) => void;
  setIsSurvey: (value: boolean) => void;
  // fetchUser: (email: string, phoneNumber: string) => Promise<any>;
  isLoading: boolean;
}

interface IProps {
  children: JSX.Element;
}

const UserContext = createContext<IUserStatus>({
  isVerified: false,
  isSurvey: false,
  mobileNumber: '',
  setIsVerified: (value: boolean) => console.log(value),
  setIsSurvey: (value: boolean) => console.log(value),
  // fetchUser: (email: string) => Promise.resolve(email),
  isLoading: false,
});

const UserProvider = ({ children }: IProps): JSX.Element => {
  const [user, setUser] = useState<{
    isVerified: boolean;
    mobileNumber?: string;
  }>({
    isVerified: false,
    mobileNumber: '',
  });
  const [isSurvey, setIsSurvey] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   const email = localStorage.getItem('email');
  //   const phoneNumber = localStorage.getItem('phone');
  //   if (email || phoneNumber) {
  //     fetchUser(email || '', phoneNumber || '');
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // const fetchUser = async (email: string, phoneNumber: string) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await API.post('/clientStatus', { email, phoneNumber });
  //     if (response) {
  //       // console.log(`fetchUser User is: ${JSON.stringify(response)}`);
  //       const { clientStatus: status } = response.data;
  //       // console.log(`status User is: ${JSON.stringify(status)}`);
  //       localStorage.setItem('referalCode', response.data.referalValue);
  //       if (!cookies.id) {
  //         setCookie('id', response.data.id, { path: '/' });
  //       }
  //       setUser(response.data);
  //       // setIsSurvey(status.isSurvey);
  //       setIsLoading(false);

  //       return status;
  //     }
  //   } catch (e) {
  //     // Client does not exist;
  //     localStorage.clear();
  //     setIsLoading(false);
  //   }
  // };

  const setIsVerified = (value: boolean) => {
    setUser({ ...user, isVerified: value });
  };

  return (
    <UserContext.Provider
      value={{
        ...user,
        setIsVerified,
        setIsSurvey,
        // fetchUser,
        isLoading,
        isSurvey,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
