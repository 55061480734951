import React, { useEffect, useState } from 'react';
import styles from './setting.module.css';
import { Button, Checkbox, Select, Spin } from 'antd';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { BASE_URL, getMobileOrPC } from '../utils/Constants';

const DriverRouteSetting = (): JSX.Element => {
  const isPC = getMobileOrPC();
  const { Option } = Select;

  const url = BASE_URL;

  const [loading, setLoading] = useState(false);
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [cps, setCPs] = useState<any[]>([]);
  const [cpInfo, setCPInfo] = useState<any>();
  const [stayTime, setStayTime] = useState(0);
  const [bufferTime, setBufferTime] = useState(0);
  const [isFixTime, setIsFixTime] = useState(false);
  const [period, setPeriod] = useState('A');
  const [region, setRegion] = useState('Other');
  const [pickupStart, setPickupStart] = useState('');
  const [pickupEnd, setPickupEnd] = useState('');

  const [resoInfo, setResoInfo] = useState<any>();
  const [resoUhual, setResoUhaul] = useState(0);
  const [isDriverSolo, setIsDriverSolo] = useState(false);

  const [nearResoDuration, setNearResoDuration] = useState(0);
  const [maxDrivingToRestaurant, setMaxDrivingToRestaurant] = useState(0);
  const [maxDeliveryMeals, setMaxDeliveryMeals] = useState(0);

  useEffect(() => {
    refreshFeatureGate();
    refreshRestaurant();
    getPickupLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshFeatureGate = async () => {
    const { data } = await axios.get(`${url}/features`);
    setNearResoDuration(data.nearResoDuration);
    setMaxDrivingToRestaurant(data.maxDrivingToRestaurant);
    setMaxDeliveryMeals(data.maxDeliveryMeals);
  };

  const refreshRestaurant = async () => {
    const { data } = await axios.get(`${url}/restaurants/list`);
    const res = data.data;
    res.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
    setRestaurants(res);
  };

  const getPickupLocations = async () => {
    const { data } = await axios.get(`${url}/pickupLocations`);
    const cp = data.data;
    cp.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setCPs(cp);
  };

  const changeReso = async (restaurantId) => {
    const reso = restaurants.find((v) => v.id === restaurantId);
    setResoInfo(reso);
    setIsDriverSolo(reso.isDriverSolo);
    setResoUhaul(reso.drivingUhaulDuration);
  };

  const changeCPInfo = async (cpId) => {
    const c = cps.find((v) => v.id === cpId);
    console.log(c);
    setCPInfo(c);
    setStayTime(c.stayTime);
    setBufferTime(c.bufferTime);
    setIsFixTime(c.isFixTime);
    setPeriod(c.deliveryPeriod);
    setRegion(c.region);
    setPickupStart(c.pickupStart);
    setPickupEnd(c.pickupEnd);
  };

  const updateFeatureGates = async () => {
    setLoading(true);
    const { data } = await axios.post(`${url}/routes-setting/update`, {
      nearResoDuration,
      maxDrivingToRestaurant,
      maxDeliveryMeals,
    });
    setLoading(false);
    if (data) {
      toast.success('Success!');
    } else {
      toast.error('Error!');
    }
  };

  const updateSite = async (type: string, id: string) => {
    setLoading(true);
    const { data } = await axios.post(`${url}/ns-or-reso/update`, {
      type,
      id,
      resoUhual,
      isDriverSolo,
      stayTime,
      bufferTime,
      isFixTime,
      period,
      region,
      pickupStart,
      pickupEnd,
    });
    setLoading(false);
    if (data) {
      toast.success('Success!');
      refreshRestaurant();
      getPickupLocations();
    } else {
      toast.error('Error!');
    }
  };

  return (
    <div
      className={styles.container}
      style={{ maxWidth: isPC ? '450px' : '50000px' }}
    >
      {loading && <Spin size="large" className={styles.loading} />}
      <Toaster position="top-center" />
      <div style={{ marginTop: 40, width: '100%' }}>
        <div className={styles.txtC}>
          Maximum drive for two restaurants
          <input
            className={styles.txt}
            value={nearResoDuration}
            onChange={(e) => setNearResoDuration(Number(e.target.value))}
          />
        </div>
        <div className={styles.txtC}>
          Maximum drive to restaurant
          <input
            className={styles.txt}
            value={maxDrivingToRestaurant}
            onChange={(e) => setMaxDrivingToRestaurant(Number(e.target.value))}
          />
        </div>
        <div className={styles.txtC}>
          Maximum delivery quantity
          <input
            className={styles.txt}
            value={maxDeliveryMeals}
            onChange={(e) => setMaxDeliveryMeals(Number(e.target.value))}
          />
        </div>
        <Button
          type="primary"
          style={{ width: '100%' }}
          onClick={() => updateFeatureGates()}
        >
          Update
        </Button>
      </div>
      <div style={{ marginTop: 40, width: '100%', textAlign: 'left' }}>
        NoshStop:
      </div>
      <Select
        defaultValue=""
        style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
        onChange={(e) => {
          changeCPInfo(e);
        }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          ((option!.children as unknown) as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {cps.map((v, i) => (
          <Option value={v.id} key={i}>
            {v.name}
          </Option>
        ))}
      </Select>
      {!!cpInfo && (
        <div style={{ width: '100%' }}>
          <div className={styles.txtC}>
            Stay Time
            <input
              className={styles.txt}
              value={stayTime}
              onChange={(e) => setStayTime(Number(e.target.value))}
            />
          </div>
          <div className={styles.txtC}>
            Buffer Time
            <input
              className={styles.txt}
              value={bufferTime}
              onChange={(e) => setBufferTime(Number(e.target.value))}
            />
          </div>
          <div className={styles.txtC}>
            <Checkbox
              onChange={(e) => setIsFixTime(e.target.checked)}
              checked={isFixTime}
            >
              IsFixTime
            </Checkbox>
            {isFixTime && (
              <>
                <input
                  className={styles.txt}
                  value={pickupStart}
                  onChange={(e) => setBufferTime(Number(e.target.value))}
                />
                <input
                  className={styles.txt}
                  value={pickupEnd}
                  onChange={(e) => setBufferTime(Number(e.target.value))}
                />
              </>
            )}
          </div>
          <div className={styles.txtC}>
            Delivery Time
            <Select
              defaultValue={period}
              value={period}
              style={{ width: 200, marginBottom: 10, marginTop: 10 }}
              onChange={(e) => {
                setPeriod(e);
              }}
            >
              <Option value="A">A (11:50 - 12:20)</Option>
              <Option value="B">B (12:20 - 12:50)</Option>
              <Option value="C">C (12:50 - 1:20)</Option>
            </Select>
          </div>
          <div className={styles.txtC}>
            Region
            <Select
              defaultValue={region}
              value={region}
              style={{ width: 200, marginBottom: 10, marginTop: 10 }}
              onChange={(e) => {
                setRegion(e);
              }}
            >
              <Option value="Downtown">Downtown</Option>
              <Option value="Midtown">Midtown</Option>
              <Option value="Museum District">Museum District</Option>
              <Option value="Medical Center">Medical Center</Option>
              <Option value="Other">Other</Option>
            </Select>
          </div>
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={() => updateSite('noshStop', cpInfo.id)}
          >
            Update
          </Button>
        </div>
      )}

      <div style={{ marginTop: 40, width: '100%', textAlign: 'left' }}>
        Restaurant:
      </div>
      <Select
        defaultValue=""
        style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
        onChange={(e) => {
          changeReso(e);
        }}
      >
        {restaurants.map((v, i) => (
          <Option value={v.id} key={i}>
            {v.title}
          </Option>
        ))}
      </Select>
      {!!resoInfo && (
        <div style={{ width: '100%' }}>
          <div className={styles.txtC}>
            Driving to uhaul
            <input
              className={styles.txt}
              value={resoUhual}
              onChange={(e) => setResoUhaul(Number(e.target.value))}
            />
          </div>
          <div className={styles.txtC}>
            <Checkbox
              onChange={(e) => setIsDriverSolo(e.target.checked)}
              checked={isDriverSolo}
            >
              IsDriverSolo
            </Checkbox>
          </div>
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={() => updateSite('reso', resoInfo.id)}
          >
            Update
          </Button>
        </div>
      )}
    </div>
  );
};

export default DriverRouteSetting;
