import React, { useEffect, useState } from 'react';
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons';
import 'react-awesome-animated-number/dist/index.css';
import styles from './driver-update.module.css';
import Loading from '../Components/Loading';
import { BASE_URL } from '../utils/Constants';
import Axios from 'axios';
import { Select } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment-timezone';

const DirverUpdate = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [driverData, setDriverData] = useState<any[]>([]);
  const [updateType, setUpdateType] = useState('restaurant');
  const [deadline, setDeadline] = useState('');
  const url = BASE_URL;

  const { Option } = Select;

  useEffect(() => {
    queryManagerView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setInterval(async () => {
      if (
        moment
          .tz('US/Central')
          .isAfter(
            moment.tz('US/Central').set({ hours: 10, minutes: 40, second: 0 })
          )
      ) {
        setDeadline('not editable');
      } else {
        const timeleft = moment
          .tz('US/Central')
          .set({ hours: 10, minutes: 40, second: 0 })
          .diff(moment.tz('US/Central'), 'second');

        const t = moment.duration(timeleft, 'second');

        const countdown =
          timeleft <= 0
            ? '--:--:--'
            : moment({ h: t.hours(), m: t.minutes(), s: t.seconds() }).format(
                'HH:mm:ss'
              );

        setDeadline(countdown);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryManagerView = async () => {
    setLoading(true);
    try {
      const data = (await Axios.get(`${url}/driver/routes`)).data;
      console.log(data);
      if (!data) return;
      console.log(data.driverRoutes);
      setDriverData(data.driverRoutes);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleMove = (params, type, routeIndex, resoIndex) => {
    if (routeIndex === 0 && type === 'left') return;
    if (routeIndex === driverData.length - 1 && type === 'right') return;

    const item = driverData[routeIndex][params].splice(resoIndex, 1);
    const currentIndex = type === 'left' ? routeIndex - 1 : routeIndex + 1;
    driverData[currentIndex][params].push(item[0]);
    setDriverData([...driverData]);
  };

  const handleSort = (params, type, routeIndex, resoIndex) => {
    const resoData = driverData[routeIndex][params];

    if (type === 'up' && resoIndex === 0) return;
    if (type === 'down' && resoIndex === resoData.length - 1) return;

    const currentIndex = type === 'up' ? resoIndex - 1 : resoIndex + 1;
    resoData[resoIndex] = resoData.splice(
      currentIndex,
      1,
      resoData[resoIndex]
    )[0];
    setDriverData([...driverData]);
  };

  const handleInputChange = (value, index, inx, type) => {
    driverData[index].orderInLocations[inx][type] = value;
    setDriverData([...driverData]);
  };

  const handleSave = async () => {
    if (
      moment
        .tz('US/Central')
        .isAfter(
          moment.tz('US/Central').set({ hours: 10, minutes: 40, second: 0 })
        )
    ) {
      toast.error('Dead Line!');
      return;
    }

    const driverRoutes = JSON.parse(JSON.stringify(driverData));
    driverRoutes.forEach((v) => {
      const meals: any[] = [];
      v.orderInLocations.forEach((o) => {
        o.Order.forEach((m) => {
          const ml = meals.find((v) => v.dishName === m.dishName);
          if (ml) {
            ml.number = ml.number + m.total;
          } else {
            meals.push({
              dishName: m.dishName,
              label: m.label,
              number: m.total,
            });
          }
        });
      });
      v.storageCenter.meals = JSON.parse(JSON.stringify(meals));
    });

    setLoading(true);
    try {
      const { data } = await Axios.post(`${url}/driver/update/routes`, {
        driverRoutes,
      });
      if (data) {
        toast.success('Success');
      } else {
        toast.error('Error');
      }
    } catch (error) {
      // toast.error(error);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className={styles.content}>
      <Toaster position="top-center" />
      <div className={styles.head}>
        <div className={styles.components}>
          <Select
            defaultValue="restaurant"
            style={{ width: 160, marginRight: 10, marginLeft: 10 }}
            onChange={(e) => {
              setUpdateType(e);
            }}
          >
            <Option value="restaurant">restaurant</Option>
            <Option value="noshStop">noshStop</Option>
          </Select>
        </div>
        <div className={styles.btn} onClick={handleSave}>
          save
        </div>
        <div className={styles.deadline}>deadline: {deadline}</div>
      </div>
      <div className={styles.container}>
        {loading && (
          <div className={styles.mask}>
            <Loading />
          </div>
        )}
        {driverData.map((val, index) => (
          <div className={styles.listContainer} key={index}>
            <div className={styles.title}>DR {val.driverId}</div>
            <div className={styles.list}>
              {updateType === 'restaurant' &&
                val.orderInRestaurant.map((v, i) => (
                  <div className={styles.item} key={i}>
                    <div style={{ fontSize: 14 }}>{v.restaurantName}</div>
                    <div className={styles.iconList}>
                      <LeftOutlined
                        className={styles.icon}
                        onClick={() =>
                          handleMove('orderInRestaurant', 'left', index, i)
                        }
                      />
                      <RightOutlined
                        className={styles.icon}
                        onClick={() =>
                          handleMove('orderInRestaurant', 'right', index, i)
                        }
                      />
                      <UpOutlined
                        className={styles.icon}
                        onClick={() =>
                          handleSort('orderInRestaurant', 'up', index, i)
                        }
                      />
                      <DownOutlined
                        className={styles.icon}
                        onClick={() =>
                          handleSort('orderInRestaurant', 'down', index, i)
                        }
                      />
                    </div>
                  </div>
                ))}
              {updateType === 'noshStop' &&
                val.orderInLocations.map((v, i) => (
                  <div className={styles.item} key={i}>
                    <div style={{ marginBottom: 10, fontSize: 14 }}>
                      {v.name}
                    </div>
                    <div className={styles.txtC}>
                      pickupStart:{' '}
                      <input
                        className={styles.txt}
                        value={v.pickupStart}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            index,
                            i,
                            'pickupStart'
                          )
                        }
                      />
                    </div>
                    <div className={styles.txtC}>
                      pickupEnd:{' '}
                      <input
                        className={styles.txt}
                        value={v.pickupEnd}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            index,
                            i,
                            'pickupEnd'
                          )
                        }
                      />
                    </div>
                    <div className={styles.iconList}>
                      <LeftOutlined
                        className={styles.icon}
                        onClick={() =>
                          handleMove('orderInLocations', 'left', index, i)
                        }
                      />
                      <RightOutlined
                        className={styles.icon}
                        onClick={() =>
                          handleMove('orderInLocations', 'right', index, i)
                        }
                      />
                      <UpOutlined
                        className={styles.icon}
                        onClick={() =>
                          handleSort('orderInLocations', 'up', index, i)
                        }
                      />
                      <DownOutlined
                        className={styles.icon}
                        onClick={() =>
                          handleSort('orderInLocations', 'down', index, i)
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DirverUpdate;
