import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useParams } from 'react-router';
import Loading from '../Components/Loading';
import toast, { Toaster } from 'react-hot-toast';

const TheDayReminder = (): JSX.Element => {
  const [summaryInfo, setSummaryInfo] = useState<any>();
  const [loading, setLoading] = useState(false);

  const { restaurantId, hashKey } = useParams<any>();

  const url = 'https://backend.noshpass.com/api';
  // const url = 'http://localhost:8000/api';

  useEffect(() => {
    querySummary();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const querySummary = async () => {
    setLoading(true);

    const orderSummary = (
      await Axios.get(
        `${url}/orders/restaurant/daily-summary/${restaurantId}/${hashKey}`
      )
    ).data;

    if (orderSummary.restaurantName) {
      setSummaryInfo({
        Restaurant: orderSummary.restaurantName,
        Date: orderSummary.date,
        'Total Orders': orderSummary.totalOrder,
        isConfirm: orderSummary.isConfirm,
      });
    }

    setLoading(false);
  };

  const handleConfrim = async () => {
    if (window.confirm(`Please make sure you are ready !`)) {
      const { data } = await Axios.post(
        `${url}/orders/restaurant/daily-summary-confirm/${restaurantId}/${hashKey}`
      );
      if (data === 'OK') {
        window.location.reload();
      } else {
        toast.error('error! please try again');
      }
    }
  };

  return (
    <div className="summary">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Toaster position="top-center" />
          <h3 style={{ marginTop: 30 }}>NoshPass</h3>
          {summaryInfo ? (
            <div>
              <div style={{ textAlign: 'left' }}>
                <br />
                Friendly reminder that you're being featured today on NoshPass.
                The order summary will be texted to you at 10am & at 10:30 am
                (the final order cut-off). Please reply to confirm you received
                this, or we'll be following up with a courtesy call shortly.
                <br />
                <br />
              </div>
              {!summaryInfo.isConfirm ? (
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    backgroundColor: '#F06A20',
                    borderRadius: 4,
                    marginBottom: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#ffffff',
                  }}
                  onClick={handleConfrim}
                >
                  I Hereby Confirm
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    backgroundColor: '#CCCCCC',
                    borderRadius: 4,
                    marginBottom: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#ffffff',
                  }}
                >
                  Confirmed! Thanks.
                </div>
              )}
              <br />
            </div>
          ) : (
            <div>
              <br />
              <br />
              Link invalid, make sure your link is correct.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TheDayReminder;
