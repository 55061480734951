import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

import './App.scss';
import 'antd/dist/antd.css';

import { UserProvider } from './Providers/user.provider';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Routes from './Routes';
import SEOHeader from './Components/SEO';

declare global {
  interface Window {
    MSStream: any;
  }
}

const App = (): JSX.Element => {
  let advancedMatching;
  const options = {
    autoConfig: true,
    debug: process.env.NODE_ENV === 'development',
  };

  if (process.env.NODE_ENV !== 'development') {
    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
      advancedMatching,
      options
    );
    ReactPixel.pageView();
  }

  const referal = new URLSearchParams(window.location.search).get('r') || '';
  const referalId = referal?.replace('YUM', '').replace('LUNCH', '');
  localStorage.setItem('referalId', referalId);
  localStorage.setItem('fullReferal', referal);

  return (
    <div className="App">
      <SEOHeader />
      <Router>
        <UserProvider>
          <Routes />
        </UserProvider>
      </Router>
    </div>
  );
};

export default App;
