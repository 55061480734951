import React from 'react';
import { useLocation } from 'react-router-dom';

const DriverImage = (): JSX.Element => {
  const params = new URLSearchParams(useLocation().search);

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <img
        style={{ width: '100%' }}
        src={params.get('imageUrl') || ''}
        alt=""
      />
    </div>
  );
};

export default DriverImage;
