import React, { FC, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import axios from 'axios';
import { BASE_URL } from '../utils/Constants';

const Test: FC = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyCBNikdUtrJOcjYbrL5ioPG1l_06BHjcLU',
    authDomain: 'noshpass.firebaseapp.com',
    projectId: 'noshpass',
    storageBucket: `noshpass.appspot.com`,
    messagingSenderId: '49054821185',
    appId: '1:49054821185:web:645e398c21ffd367e24fc0',
    measurementId: 'G-579G07S3LJ',
  };
  // const firebaseConfig = {
  //   apiKey: 'AIzaSyCRdRD0PuRm1NdycMUgGBuLBhuInKkT1Ks',
  //   authDomain: 'ricepass-315e2.firebaseapp.com',
  //   projectId: 'ricepass-315e2',
  //   storageBucket: 'ricepass-315e2.appspot.com',
  //   messagingSenderId: '387249033452',
  //   appId: '1:387249033452:web:0c2d82473615c3d972d5c9',
  //   measurementId: 'G-5V2713Z8F1',
  // };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const [meals, setMeals] = useState<any[]>([]);
  useEffect(() => {}, []);

  const confirm = async () => {
    const { data } = await axios.get(`${BASE_URL}/meals/list`);
    console.log(meals);
    setMeals(data);

    // download(data[2]);
    setInterval(async () => {
      console.log('检查是否有图片', data.length);
      if (data.length) {
        console.log('开始下载图片');
        const meal = data.shift();
        if (meal) {
          await download(meal);
        }
      }
    }, 1000);

    // for await (const meal of data) {
    //   await download(meal);
    // }
  };

  const download = async (meal: any) => {
    // const url =
    //   'https://ik.imagekit.io/kaju6ub6d1x/ricepassassets/' + meal.imgFilename;
    // const url =
    //   'https://ik.imagekit.io/kaju6ub6d1x/rp-public-assets-us/' +
    //   meal.imgFilename;
    const url =
      'https://ik.imagekit.io/kaju6ub6d1x/rp-public-assets-us/restaurantLogo/' +
      meal.logoImage;
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
    image.onload = async () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(image, 0, 0, image.width, image.height);
        await canvas.toBlob(async (blob) => {
          console.log(blob);
          if (blob) {
            await changePic(meal, blob);
          }
        });
      }
    };
  };

  const changePic = async (meal, e) => {
    const imgId = v4().slice(-8);
    const storageRef = ref(storage, `Restaurants/${imgId}.png`);
    try {
      const res = await uploadBytes(storageRef, e);
      console.log(res);
      const imageLink = `https://firebasestorage.googleapis.com/v0/b/noshpass.appspot.com/o/Restaurants%2F${res.metadata.name}?alt=media`;
      await axios.post(`${BASE_URL}/meals/update-meal-image`, {
        id: meal.id,
        imageLink: imageLink,
      });
      console.log(`${meal.title}修改成功`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          confirm();
        }}
      >
        下载
      </button>
    </div>
  );
};

export default Test;
