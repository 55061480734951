import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { JsonToTable } from 'react-json-to-table';
import styles from '../driver.module.scss';
import { openLocationInMap } from '../../utils/geo';
import { FaDirections } from 'react-icons/fa';
import AppButton from './appButton';
import Axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { BASE_URL, S3_IMAGE_URL } from '../../utils/Constants';
import {
  CameraOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import Compressor from 'compressorjs';
import { v4 } from 'uuid';
import { Collapse } from 'antd';
// import 'draft-js/dist/Draft.css';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

const NoshStop = (props: {
  driverRoutes: any;
  isRedundant?: boolean;
}): JSX.Element => {
  const { Panel } = Collapse;
  const url = BASE_URL;
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const firebaseConfig = {
    apiKey: 'AIzaSyBfQogCed6AY7NDM2mvy2DDDM1_8mi8KqA',
    authDomain: 'noshpass-new.firebaseapp.com',
    projectId: 'noshpass-new',
    storageBucket: 'noshpass-new.appspot.com',
    messagingSenderId: '652195764522',
    appId: '1:652195764522:web:3a546ca07c568c885ce653',
    measurementId: 'G-HVXE3B1ZG6',
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const [driverRoutes, setDriverRoutes] = useState(props.driverRoutes);
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const editor = React.useRef<any>();

  const convertKeys = (data) => {
    return _.mapKeys(data, (v, key) => _.startCase(_.lowerCase(key)));
  };

  const editSite = async (d: any, nosh: any, type: string) => {
    if (!!d.endTime || !!nosh[`${type}Time`]) return;

    if (window.confirm(`Are you sure to confirm it?`)) {
      nosh[`${type}Loading`] = true;
      setDriverRoutes([...driverRoutes]);

      const { data } = await Axios.post(`${url}/driver/routes/site/edit`, {
        hashKey: d.hashKey,
        id: nosh.id,
        editType: `${type}Time`,
        siteType: 'noshStop',
      });
      if (!data) {
        toast.error(`Someting wrong, Please try again`);
        nosh[`${type}Loading`] = false;
        setDriverRoutes([...driverRoutes]);
        return;
      }

      nosh[`${type}Time`] = moment
        .tz('US/Central')
        .format('YYYY-MM-DD HH:mm:ss');
      nosh[`${type}Loading`] = false;
      setDriverRoutes([...driverRoutes]);
    }
  };

  // const arrivedNoshStop = async (d: any, nosh: any, type: string) => {
  //   if (!!d.endTime || !!nosh[`${type}Time`]) return;

  //   nosh[`${type}Loading`] = true;
  //   setDriverRoutes([...driverRoutes]);

  //   const { data } = await Axios.post(`${url}/driver/routes/site/edit`, {
  //     hashKey: d.hashKey,
  //     id: nosh.id,
  //     editType: `${type}Time`,
  //     siteType: 'noshStop',
  //   });
  //   if (!data) {
  //     toast.error(`Someting wrong, Please try again`);
  //     nosh[`${type}Loading`] = false;
  //     setDriverRoutes([...driverRoutes]);
  //     return;
  //   }

  //   nosh[`${type}Time`] = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
  //   nosh[`${type}Loading`] = false;
  //   setDriverRoutes([...driverRoutes]);
  // };

  const editText = (d: any, nosh: any, type: string) => {
    if (!!d.endTime)
      return nosh[`${type}Time`] ? `${type.toUpperCase()}` : type.toUpperCase();

    return nosh[`${type}Time`] ? `${type.toUpperCase()}` : type.toUpperCase();
  };

  const editClass = (d: any, nosh: any, type: string) => {
    if (!d.endTime && !!nosh[`${type}Time`]) {
      const isTimeOut = moment
        .tz(`${nosh[`${type}Time`]}`, 'US/Central')
        .isAfter(
          moment.tz(
            `${d.date} ${
              type === 'arrived' ? nosh.pickupStart : nosh.pickupEnd
            }`,
            'US/Central'
          )
        );

      return `${styles.nsArriveBtn} ${
        isTimeOut ? styles.timeOut : styles.onTime
      }`;
    }

    if (!!d.endTime) {
      return styles.inactiveBtn;
    }

    const style = {
      arrived: styles.nsArriveBtn,
      left: styles.leftBtn,
    }[type];

    return style;
  };

  const showCamera = (d: any, nosh: any) => {
    return (
      !!d.startTime &&
      !d.endTime &&
      // moment.tz('US/Central').isSame(moment.tz(d.date, 'US/Central'), 'day') &&
      !nosh.uploadTime &&
      !!nosh.arrivedTime
    );
  };

  const changePic = (e, d: any, nosh: any) => {
    nosh.uploadLoading = true;
    setDriverRoutes([...driverRoutes]);
    new Compressor(e.target.files[0], {
      quality: 0.6,
      maxWidth: 1000,
      maxHeight: 1000,
      success: async (res) => {
        const imgId = v4();
        const storageRef = ref(storage, `Driver/${imgId}.png`);
        try {
          const result = await uploadBytes(storageRef, res);
          const imageUrl = `https://firebasestorage.googleapis.com/v0/b/noshpass-new.appspot.com/o/Driver%2F${result.metadata.name}?alt=media&token=faad9c36-65fc-4695-a3c8-a274c0375b5b`;
          saveImage(d, nosh, imageUrl);
        } catch (e) {
          console.log(e);
          alert(e);
          nosh.uploadLoading = false;
          setDriverRoutes([...driverRoutes]);
        }
      },
      error(err) {
        alert(err.message);
        nosh.uploadLoading = false;
        setDriverRoutes([...driverRoutes]);
      },
    });
  };

  const saveImage = async (d: any, nosh: any, imageUrl: string) => {
    const { data } = await Axios.post(`${url}/driver/routes/site/edit`, {
      hashKey: d.hashKey,
      id: nosh.id,
      editType: 'uploadTime',
      siteType: 'noshStop',
      imageUrl,
    });
    if (!data) {
      toast.error(`Someting wrong, Please try again`);
      nosh.uploadLoading = false;
      setDriverRoutes([...driverRoutes]);
      return;
    }

    nosh.uploadTime = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
    nosh.uploadLoading = false;
    setDriverRoutes([...driverRoutes]);

    // arrivedNoshStop(d, nosh, 'arrived');
  };

  return (
    <>
      <Toaster position="top-center" />
      {driverRoutes.map((d, i) => {
        return (
          <div key={i}>
            {props.isRedundant ? (
              <h3 className={styles.h3}>
                Redundant Driver Shift Details [
                {moment.tz(d.date, 'US/Central').format('MMM DD')}]
              </h3>
            ) : (
              <h3 className={styles.h3}>
                Driver Shift Details [DR#{d.driverId}] [
                {moment.tz(d.date, 'US/Central').format('MMM DD')}]
              </h3>
            )}

            {!d.orderInLocations.length && (
              <div className={styles.section}>
                <div className={styles.sectionTitle}>
                  Orders to Distribute at NoshStop
                </div>
                No Order Yet
              </div>
            )}
            {!!d.orderInLocations.length &&
              d.orderInLocations.map((v, inx) => {
                const noshJSON = JSON.parse(JSON.stringify(v));
                const imageIds = Array.isArray(noshJSON.imagesIds)
                  ? noshJSON.imagesIds.filter((v) => v)
                  : [];
                noshJSON.Order.forEach((o) => {
                  o.dishName = o.dishName.substring(0, 10);
                  delete o.deliveryStatus;
                });

                delete noshJSON.id;
                delete noshJSON.imagesIds;
                delete noshJSON.total;
                delete noshJSON.imageUrl;
                delete noshJSON.period;
                delete noshJSON.arrivedTime;
                delete noshJSON.leftTime;
                delete noshJSON.uploadTime;
                delete noshJSON.arrivedLoading;
                delete noshJSON.leftLoading;
                delete noshJSON.uploadLoading;
                delete noshJSON.sms;
                delete noshJSON.instruction;

                return (
                  <div
                    className={styles.section}
                    key={inx}
                    style={{ padding: 0 }}
                  >
                    <Collapse
                      bordered={false}
                      ghost
                      expandIconPosition="right"
                      style={{ color: 'red' }}
                    >
                      <Panel
                        header={
                          <div
                            style={{
                              color: '#f06a20',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            NOSHSTOP {inx + 1}
                            <InfoCircleOutlined
                              style={{ marginLeft: 10 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                alert(v.name);
                              }}
                            />
                          </div>
                        }
                        key={inx}
                        className={styles.sectionTitle}
                      >
                        <div className={styles.noshstopTitle}>
                          <div>Stop {inx + 1}</div>
                          <div
                            className={`${styles.simpleButton} ${styles.navBtn}`}
                            onClick={() =>
                              openLocationInMap(
                                `${noshJSON.lat},${noshJSON.lng}`
                              )
                            }
                          >
                            Map <FaDirections size={'1.2rem'} />
                          </div>
                        </div>

                        {imageIds.map((o, i) => (
                          <div className={styles.noshstopTitle} key={o}>
                            <span style={{ marginRight: 10 }}>
                              {i === 0
                                ? 'Location Photo:'
                                : 'Drop Off Area Photo:'}
                            </span>
                            <div
                              className={styles.linkBtn}
                              onClick={() =>
                                window.open(
                                  `https://noshpass.com/en/driver-image?imageUrl=${S3_IMAGE_URL}${o}`
                                )
                              }
                            >
                              Tap to Open
                            </div>
                          </div>
                        ))}

                        {!!v.uploadTime && (
                          <div className={styles.noshstopTitle}>
                            <span style={{ marginRight: 10 }}>
                              Upload Photo:
                            </span>
                            <div
                              className={styles.linkBtn}
                              onClick={() => window.open(v.imageUrl)}
                            >
                              Tap to Open (
                              {moment
                                .tz(v.uploadTime, 'US/Central')
                                .format('HH:mm')}
                              )
                            </div>
                          </div>
                        )}

                        {!!d.startTime && (
                          <div className={styles.nsBtnList}>
                            <AppButton
                              text={editText(d, v, 'arrived')}
                              className={editClass(d, v, 'arrived')}
                              loading={v.arrivedLoading}
                              onClick={() => {
                                editSite(d, v, 'arrived');
                              }}
                            />
                            <AppButton
                              text={editText(d, v, 'left')}
                              className={editClass(d, v, 'left')}
                              loading={v.leftLoading}
                              onClick={() => editSite(d, v, 'left')}
                            />
                            {showCamera(d, v) && (
                              <>
                                {v.uploadLoading && (
                                  <Spin
                                    indicator={antIcon}
                                    className={styles.uploadLoading}
                                  />
                                )}
                                <CameraOutlined className={styles.camera} />
                                <input
                                  type="file"
                                  id="file1"
                                  accept="image/*"
                                  className={styles.uploadTxt}
                                  onChange={(e) => changePic(e, d, v)}
                                />
                              </>
                            )}
                          </div>
                        )}
                        <Collapse
                          bordered={false}
                          ghost
                          expandIconPosition="right"
                          style={{ color: 'red' }}
                        >
                          <Panel
                            header={
                              <div
                                style={{
                                  color: '#f06a20',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                Parking instructions
                              </div>
                            }
                            key={inx}
                            className={styles.sectionTitle}
                          >
                            <div
                              style={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {v.instruction}
                            </div>
                          </Panel>
                        </Collapse>
                        {/* <div
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            cursor: 'pointer',
                            color: '#f06a20',
                          }}
                          onClick={() => {
                            if (v.instruction) {
                              const e = EditorState.createWithContent(
                                convertFromRaw(JSON.parse(v.instruction))
                              );
                              setEditorState(e);
                            } else {
                              setEditorState(EditorState.createEmpty());
                            }
                            setIsModalOpen(true);
                          }}
                        >
                          Instruction
                        </div> */}
                        <div style={{ overflow: 'auto' }}>
                          <JsonToTable json={convertKeys(noshJSON)} />
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                );
              })}
            <hr />
          </div>
        );
      })}
      {/* <Modal
        title="Instruction"
        visible={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div
          style={{
            height: 500,
            overflow: 'auto',
          }}
        >
          <Editor
            ref={editor}
            readOnly
            editorState={editorState}
            placeholder="null..."
          />
        </div>
      </Modal> */}
    </>
  );
};

export default NoshStop;
