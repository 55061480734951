import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import styles from '../noshsimple-driver.module.scss';
import { JsonToTable } from 'react-json-to-table';
import { Collapse, Spin } from 'antd';
import {
  CameraOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { openLocationInMap } from '../../utils/geo';
import { FaDirections } from 'react-icons/fa';
import AppButton from '../../DriverRoute/components/appButton';
import Axios from 'axios';
import { SIMPLE_URL } from '../../utils/Constants';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';

const Address = (props: { driverRoutes: any }): JSX.Element => {
  const { Panel } = Collapse;
  const [driverRoutes, setDriverRoutes] = useState<any>({
    ordersInLocations: [],
  });
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const firebaseConfig = {
    apiKey: 'AIzaSyCBNikdUtrJOcjYbrL5ioPG1l_06BHjcLU',
    authDomain: 'noshpass.firebaseapp.com',
    projectId: 'noshpass',
    storageBucket: 'noshpass.appspot.com',
    messagingSenderId: '49054821185',
    appId: '1:49054821185:web:645e398c21ffd367e24fc0',
    measurementId: 'G-579G07S3LJ',
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const convertKeys = (data) => {
    return _.mapKeys(data, (v, key) => _.startCase(_.lowerCase(key)));
  };

  useEffect(() => {
    setDriverRoutes({ ...props.driverRoutes });
  }, [props.driverRoutes]);

  const editClass = (address: any, type: string) => {
    if (!props.driverRoutes.endTime && !!address[`${type}Time`]) {
      const isTimeOut = moment
        .tz(`${address[`${type}Time`]}`, 'US/Central')
        .isAfter(
          moment.tz(
            `${props.driverRoutes.date} ${
              type === 'arrived' ? address.pickupStart : address.pickupEnd
            }`,
            'US/Central'
          )
        );
      return `${styles.nsArriveBtn} ${
        isTimeOut ? styles.timeOut : styles.onTime
      }`;
    }
    if (!!props.driverRoutes.endTime) {
      return styles.inactiveBtn;
    }
    const style = {
      arrived: styles.nsArriveBtn,
      left: styles.leftBtn,
    }[type];
    return style;
  };

  const editAddress = async (address: any, type: string) => {
    if (!!driverRoutes.endTime || !!address[`${type}Time`]) return;

    if (window.confirm(`Are you sure to confirm it?`)) {
      address[`${type}Loading`] = true;
      setDriverRoutes({ ...driverRoutes });
      const { data } = await Axios.post(
        `${SIMPLE_URL}/driver/update-locations-time`,
        {
          hashKey: driverRoutes.hashKey,
          timeType: `${type}Time`,
          lat: address.lat,
          lng: address.lng,
        }
      );
      if (!data) {
        toast.error(`Someting wrong, Please try again`);
        address[`${type}Loading`] = false;
        setDriverRoutes({ ...driverRoutes });
        return;
      }
      address[`${type}Time`] = moment
        .tz('US/Central')
        .format('YYYY-MM-DD HH:mm:ss');
      address[`${type}Loading`] = false;
      setDriverRoutes({ ...driverRoutes });
    }
  };

  const showCamera = (address: any) => {
    return (
      !!driverRoutes.startTime &&
      !driverRoutes.endTime &&
      !address.uploadTime &&
      !!address.arrivedTime
    );
  };

  const changePic = async (e, address: any) => {
    address.uploadLoading = true;
    setDriverRoutes({ ...driverRoutes });
    const imgId = v4();
    const storageRef = ref(storage, `Driver/${imgId}.png`);
    try {
      const result = await uploadBytes(storageRef, e.target.files[0]);
      const imageUrl = `https://firebasestorage.googleapis.com/v0/b/noshpass.appspot.com/o/Driver%2F${result.metadata.name}?alt=media&token=fd39b2b8-dc8d-429d-ae75-ac2231e44003`;
      saveImage(address, imageUrl);
    } catch (e) {
      console.log(e);
      alert(e);
      address.uploadLoading = false;
      setDriverRoutes({ ...driverRoutes });
    }
  };

  const saveImage = async (address: any, imageUrl: string) => {
    const { data } = await Axios.post(
      `${SIMPLE_URL}/driver/update-locations-time`,
      {
        hashKey: driverRoutes.hashKey,
        timeType: 'uploadTime',
        lat: address.lat,
        lng: address.lng,
        imageUrl,
      }
    );
    if (!data) {
      toast.error(`Someting wrong, Please try again`);
      address.uploadLoading = false;
      setDriverRoutes({ ...driverRoutes });
      return;
    }

    address.uploadTime = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
    address.uploadLoading = false;
    setDriverRoutes({ ...driverRoutes });
  };

  return (
    <>
      <div style={{ marginBottom: 80 }}>
        <h3 className={styles.h3}>
          Driver Shift Details [B#{driverRoutes.driverId}] [
          {moment.tz(driverRoutes.date, 'US/Central').format('MMM DD')}]
        </h3>
        {!driverRoutes.ordersInLocations.length && (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Orders to Distribute at Location
            </div>
            No Order Yet
          </div>
        )}
        {!!driverRoutes.ordersInLocations.length &&
          driverRoutes.ordersInLocations.map((o, inx) => {
            const route = JSON.parse(JSON.stringify(o));
            const orders = JSON.parse(JSON.stringify(route.orders));
            const os = orders.map((k) => {
              return {
                'dish name': k.mealTitle,
                amount: k.mealAmount,
                phone: k.mobilePhone,
                name: k.name,
              };
            });

            delete route.totalOrders;
            delete route.drivingTime;
            delete route.orders;
            delete route.arrivedTime;
            delete route.leftTime;
            delete route.uploadTime;
            delete route.arrivedLoading;
            delete route.leftLoading;
            delete route.uploadLoading;
            delete route.contactNumber;
            delete route.imageUrl;
            delete route.sms;

            route.orders = os;

            return (
              <div
                className={styles.section}
                style={{ padding: 0, marginBottom: 20 }}
                key={inx}
              >
                <Collapse
                  bordered={false}
                  ghost
                  expandIconPosition="right"
                  style={{ color: 'red' }}
                >
                  <Panel
                    header={
                      <div
                        style={{
                          color: '#0bc180',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        NOSHSTOP{inx + 1}
                        <InfoCircleOutlined
                          style={{ marginLeft: 10 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            alert(o.address);
                          }}
                        />
                      </div>
                    }
                    key={inx}
                    className={styles.sectionTitle}
                  >
                    <div className={styles.noshstopTitle}>
                      <div>NoshStop {inx + 1}</div>
                      <div
                        className={`${styles.simpleButton} ${styles.navBtn}`}
                        onClick={() =>
                          openLocationInMap(`${route.lat},${route.lng}`)
                        }
                      >
                        Map <FaDirections size={'1.2rem'} />
                      </div>
                    </div>
                    {!!o.uploadTime && (
                      <div className={styles.noshstopTitle}>
                        <span style={{ marginRight: 10 }}>Upload Photo:</span>
                        <div
                          className={styles.linkBtn}
                          onClick={() => window.open(o.imageUrl)}
                        >
                          Tap to Open (
                          {moment
                            .tz(o.uploadTime, 'US/Central')
                            .format('HH:mm')}
                          )
                        </div>
                      </div>
                    )}
                    {!!driverRoutes.startTime && (
                      <div className={styles.nsBtnList}>
                        <AppButton
                          text="ARRIVED"
                          className={editClass(o, 'arrived')}
                          onClick={() => {
                            editAddress(o, 'arrived');
                          }}
                          loading={o.arrivedLoading}
                        />
                        <AppButton
                          text="LEFT"
                          className={editClass(o, 'left')}
                          onClick={() => {
                            editAddress(o, 'left');
                          }}
                          loading={o.leftLoading}
                        />
                        {showCamera(o) && (
                          <>
                            {o.uploadLoading && (
                              <Spin
                                indicator={antIcon}
                                className={styles.uploadLoading}
                              />
                            )}
                            <CameraOutlined className={styles.camera} />
                            <input
                              type="file"
                              id="file1"
                              accept="image/*"
                              className={styles.uploadTxt}
                              onChange={(e) => changePic(e, o)}
                            />
                          </>
                        )}
                      </div>
                    )}
                    <div style={{ overflow: 'auto' }}>
                      <JsonToTable json={convertKeys(route)} />
                    </div>
                  </Panel>
                </Collapse>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Address;
