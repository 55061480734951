import React, { useEffect, useState } from 'react';
import styles from './setting.module.css';
import { Button, Select, Spin } from 'antd';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import { BASE_URL, getMobileOrPC } from '../utils/Constants';
// import { EditorState } from 'draft-js';
// import 'draft-js/dist/Draft.css';
import TextArea from 'antd/lib/input/TextArea';

const Instruction = (): JSX.Element => {
  const isPC = getMobileOrPC();
  const { Option } = Select;

  const url = BASE_URL;

  // const BLOCK_TYPES = [
  //   { label: 'H1', style: 'header-one' },
  //   { label: 'H2', style: 'header-two' },
  //   { label: 'H3', style: 'header-three' },
  //   { label: 'H4', style: 'header-four' },
  //   { label: 'H5', style: 'header-five' },
  //   { label: 'H6', style: 'header-six' },
  //   { label: 'Blockquote', style: 'blockquote' },
  //   { label: 'UL', style: 'unordered-list-item' },
  //   { label: 'OL', style: 'ordered-list-item' },
  //   { label: 'Code Block', style: 'code-block' },
  // ];
  // const INLINE_STYLES = [
  //   { label: 'Bold', style: 'BOLD' },
  //   { label: 'Italic', style: 'ITALIC' },
  //   { label: 'Underline', style: 'UNDERLINE' },
  //   { label: 'Monospace', style: 'CODE' },
  // ];

  const [loading, setLoading] = useState(false);
  const [cps, setCPs] = useState<any[]>([]);
  const [cpInfo, setCPInfo] = useState<any>();
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [description, setDescription] = useState<any[]>([]);
  const [instruction, setInstruction] = useState('');

  const editor = React.useRef<any>();

  const focusEditor = () => {
    editor.current?.focus();
  };

  useEffect(() => {
    focusEditor();
  }, []);

  useEffect(() => {
    getPickupLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPickupLocations = async () => {
    const { data } = await axios.get(`${url}/pickupLocations`);
    const cp = data.data;
    cp.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setCPs(cp);
  };

  const changeCPInfo = async (cpId) => {
    const c = cps.find((v) => v.id === cpId);
    console.log(c);
    setCPInfo(c);

    if (c.description) {
      const d = JSON.parse(c.description);
      setDescription((d as unknown) as any[]);
    } else {
      setDescription([]);
    }

    setInstruction(c.instruction);

    // if (c.instruction) {
    //   const e = EditorState.createWithContent(
    //     convertFromRaw(JSON.parse(c.instruction))
    //   );
    //   setEditorState(e);
    // } else {
    //   setEditorState(EditorState.createEmpty());
    // }
  };

  // const BlockStyleControls = (props) => {
  //   return (
  //     <div
  //       style={{
  //         width: '100%',
  //         height: 50,
  //         display: 'flex',
  //         justifyContent: 'flex-start',
  //         alignItems: 'center',
  //         flexWrap: 'wrap',
  //       }}
  //     >
  //       {BLOCK_TYPES.map((type) => (
  //         <StyleButton
  //           key={type.label}
  //           label={type.label}
  //           onToggle={props.onToggle}
  //           style={type.style}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  // const InlineStyleControls = (props) => {
  //   return (
  //     <div
  //       style={{
  //         width: '100%',
  //         height: 25,
  //         display: 'flex',
  //         justifyContent: 'flex-start',
  //         alignItems: 'center',
  //         flexWrap: 'wrap',
  //         marginTop: 10,
  //       }}
  //     >
  //       {INLINE_STYLES.map((type) => (
  //         <StyleButton
  //           key={type.label}
  //           label={type.label}
  //           onToggle={props.onToggle}
  //           style={type.style}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  // const StyleButton = (props) => {
  //   const onClickButton = (e) => {
  //     e.preventDefault();
  //     props.onToggle(props.style);
  //   };
  //   return (
  //     <div
  //       style={{
  //         marginRight: 20,
  //         height: 25,
  //         cursor: 'pointer',
  //       }}
  //       onClick={onClickButton}
  //     >
  //       {props.label}
  //     </div>
  //   );
  // };

  // const onInlineClick = (e) => {
  //   const nextState = RichUtils.toggleInlineStyle(editorState, e);
  //   setEditorState(nextState);
  // };

  // const onBlockClick = (e) => {
  //   const nextState = RichUtils.toggleBlockType(editorState, e);
  //   setEditorState(nextState);
  // };

  return (
    <div
      className={styles.container}
      style={{ maxWidth: isPC ? '650px' : '50000px', padding: 0 }}
    >
      {loading && <Spin size="large" className={styles.loading} />}
      <Toaster position="top-center" />
      <div
        style={{
          marginTop: 40,
          width: '100%',
          textAlign: 'left',
        }}
      >
        NoshStop:
      </div>
      <Select
        defaultValue=""
        style={{
          width: '100%',
          marginBottom: 10,
          marginTop: 10,
        }}
        onChange={(e) => {
          changeCPInfo(e);
        }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          ((option!.children as unknown) as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {cps.map((v, i) => (
          <Option value={v.id} key={i}>
            {v.name}
          </Option>
        ))}
      </Select>

      <div
        style={{
          width: '100%',
          marginTop: 10,
        }}
      >
        <div style={{ textAlign: 'left', marginBottom: 10 }}>
          Details filled in by the user
        </div>
        <div
          style={{
            width: '100%',
            height: 200,
            overflow: 'auto',
          }}
        >
          <div
            style={{
              width: '90%',
              textAlign: 'left',
            }}
          >
            {description.map((v, i) => {
              return (
                <div key={i}>
                  <div>name: {v.name}</div>
                  <div>phone: {v.phone}</div>
                  <div>{v.description}</div>
                  <br />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          marginTop: 10,
          marginBottom: 10,
          boxSizing: 'border-box',
        }}
      >
        <div style={{ textAlign: 'left', marginBottom: 10 }}>Instruction</div>
        <TextArea
          style={{ height: 400 }}
          value={instruction}
          onChange={(e) => {
            setInstruction(e.target.value);
          }}
        />
        {/* <div
          style={{
            borderTop: '1px solid rgb(204, 204, 204)',
            borderBottom: '1px solid rgb(204, 204, 204)',
            padding: 20,
          }}
        >
          <BlockStyleControls onToggle={(e) => onBlockClick(e)} />
          <InlineStyleControls onToggle={(e) => onInlineClick(e)} />
        </div>
        <div
          style={{
            borderTop: '1px solid rgb(204, 204, 204)',
            padding: 20,
            minHeight: 400,
          }}
        >
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={(editorState) => setEditorState(editorState)}
            placeholder="Enter..."
          />
        </div> */}
      </div>
      <Button
        style={{
          width: '100%',
          alignItems: 'center',
          height: 40,
          padding: 0,
          textAlign: 'center',
          marginBottom: 50,
        }}
        onClick={async () => {
          // const content = editorState.getCurrentContent();
          // const dataToSaveBackend = convertToRaw(content);
          setLoading(true);
          try {
            await axios.post(`${url}/user/save-noshstop-instruction`, {
              id: cpInfo.id,
              instruction,
            });
            await getPickupLocations();
            alert('update success');
          } catch (e) {
            alert('someting wrong');
          }
          setLoading(false);
        }}
        type="primary"
        className={`black-button`}
        size="small"
        htmlType="submit"
      >
        Submit
      </Button>
    </div>
  );
};

export default Instruction;
