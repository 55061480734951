import React, { useState } from 'react';
import styles from './invite.module.css';
import 'react-awesome-animated-number/dist/index.css';
import { useLocation } from 'react-router';
import { getMobileOrPC } from '../utils/Constants';
import LogoMenuSvg from '../assets/icons/logoMenu.svg';
import { ArrowDownOutlined, CopyOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';

const Invite = (): JSX.Element => {
  const params = new URLSearchParams(useLocation().search);
  const value = params.get('value');
  const v = params.get('v');
  const [visible, setVisible] = useState(false);

  const isPC = getMobileOrPC();

  return (
    <div
      className={styles.container}
      style={{
        maxWidth: isPC ? '450px' : '50000px',
      }}
    >
      <img src={LogoMenuSvg} alt="" className={styles.logo} />
      <div className={styles.title}>Get the App!</div>
      <div className={styles.block}>
        <div>Clicking below will copy this page</div>
        <div className={styles.blockImg}>
          <CopyOutlined className={styles.icon2} />
          <img
            src={require('../assets/icons/appstore.png')}
            alt=""
            className={styles.appstore}
          />
          <ArrowDownOutlined className={styles.down} />
        </div>

        <div>You'll be redirected to this page in the app</div>
      </div>
      <div className={styles.bottom}>
        <div
          className={styles.btn}
          onClick={() => {
            setVisible(true);
          }}
        >
          <CopyOutlined className={styles.icon} />
          Get the App
        </div>
        <div className={styles.copying}>Get the App without Copying</div>
      </div>
      {visible && (
        <div className={styles.mask}>
          <div className={`${styles.modal} ${styles.popIn}`}>
            <div className={styles.modalTxt}>
              This page will open in another application.
            </div>
            <div
              className={styles.modalBtn1}
              onClick={() => {
                let url = 'https://nosh.page.link/6SuK';
                if (value) {
                  const [linkValue, referelValue] = value.split('_');
                  copy(referelValue);
                  url = `https://nosh.page.link/${linkValue || '6SuK'}`;
                }
                if (v) {
                  copy(v);
                }
                window.location.href = url;
              }}
            >
              Open
            </div>
            <div className={styles.modalBtn2} onClick={() => setVisible(false)}>
              Cancel
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invite;
