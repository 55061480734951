import { LeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './mealdetail.css';
import styles from './share-menu.module.css';

const RestaurantMeals = (): JSX.Element => {
  const history = useHistory();
  const userAgentInfo = navigator.userAgent;
  const [isPC, setIsPc] = useState(true);
  const [resoMeals, setResoMeals] = useState<any[]>([]);

  useEffect(() => {
    const Agents = [
      'Android',
      'iPhone',
      'SymbianOS',
      'Windows Phone',
      'iPad',
      'iPod',
    ];
    let flag = true;
    for (const item of Agents) {
      if (userAgentInfo.indexOf(item) > 0) {
        flag = false;
        break;
      }
    }
    setIsPc(flag);
    const data = localStorage.getItem('resoMeals');
    if (data) {
      console.log(JSON.parse(data));
      setResoMeals(JSON.parse(data));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const otherTag = (title: string) => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          padding: '2px 10px',
          backgroundColor: '#F06A20',
          borderTopLeftRadius: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {title === 'NEW' && (
          <img
            src={require('../assets/icons/newHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'HOT' && (
          <img
            src={require('../assets/icons/popularHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'CHEF CHOICE' && (
          <img
            src={require('../assets/icons/chef_choiceHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'REHEATABLE' && (
          <img
            src={require('../assets/icons/reheatableHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        <span
          style={{
            fontSize: 10,
            fontWeight: 'bold',
            color: '#ffffff',
          }}
        >
          {title}
        </span>
      </div>
    );
  };

  return (
    <div>
      {!resoMeals.length && <></>}
      {!!resoMeals.length && (
        <div
          className="container-detail"
          style={{ maxWidth: isPC ? '450px' : '50000px' }}
        >
          <div
            className="meal-back"
            style={{
              left: 10,
              top: 30,
            }}
            onClick={() => history.go(-1)}
          >
            <LeftOutlined
              style={{ color: '#FFFFFF', fontSize: 30 }}
              size={30}
            />
          </div>
          <div
            style={{
              width: '100%',
              height: 120,
              background: '#f06a20',
            }}
          ></div>
          <div>
            <img
              alt=""
              style={{
                width: 80,
                height: 80,
                borderRadius: '100%',
                border: '1px solid #CCCCCC',
                marginTop: -40,
                marginBottom: 10,
              }}
              src={resoMeals[0].Restaurant.firebaseLogoImage}
            />
            <div style={{ fontWeight: 700, fontSize: 20 }}>
              {resoMeals[0].Restaurant.title}
            </div>
            <div>
              {resoMeals.map((val) => {
                return (
                  <div
                    className={styles.resoList}
                    style={{
                      overflow: 'hidden',
                      marginBottom: 20,
                      height: 300,
                    }}
                    key={val.id}
                  >
                    <div
                      className={styles.listCard}
                      key={val.id}
                      style={{ marginRight: 0, width: 340, height: 290 }}
                      onClick={() => {
                        history.push(`meal-detail?id=${val.id}`);
                      }}
                    >
                      {val.color && (
                        <div
                          className="card-color"
                          style={{ backgroundColor: val.color }}
                        >
                          <span>{'Meal ' + val.mealSetChar}</span>
                          {val.tags.length ? (
                            <span className="card-cusine">{val.tags[0]}</span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      )}
                      <div className="card-img-container">
                        {!val.isOriginalPrice && (
                          <div className="card-sales">
                            <div className="tag-origin-price">
                              <span className="card-price">
                                ${val.estimatedMealPrice.toFixed(2)}
                              </span>
                            </div>
                            <img
                              alt=""
                              className="card-sales-img"
                              src="https://rp-public-assets-us.s3.us-west-2.amazonaws.com/gallery/low8.99.png"
                            />
                          </div>
                        )}
                        {!!val.isOriginalPrice && (
                          <div className="card-sales2">
                            <div className="tag-origin-price">
                              <span className="card-price2">
                                ${val.estimatedMealPrice.toFixed(2)}
                              </span>
                            </div>
                          </div>
                        )}
                        {!!val.isOriginalPrice && (
                          <img
                            src="https://rp-public-assets-us.s3.us-west-2.amazonaws.com/gallery/ZeroAnyFee.png"
                            style={{
                              width: 180,
                              height: 28,
                              position: 'absolute',
                              top: 10,
                            }}
                            alt=""
                          />
                        )}
                        {!!val.isNew && otherTag('NEW')}
                        {!!val.isPopular && otherTag('HOT')}
                        {!!val.isChefChoice && otherTag('CHEF CHOICE')}
                        {!!val.isReheatable && otherTag('REHEATABLE')}
                        <img
                          className="card-img"
                          style={{ height: 180 }}
                          alt=" "
                          src={val.firebaseImgFilename}
                        />
                      </div>
                      <div className="card-resturant">{val.title}</div>
                      <div className="card-meal">
                        <span>{val.Restaurant.title}</span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection: 'row',
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginTop: 4,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {val.tags.includes('Halal') && (
                            <img
                              className="card-portion-meal"
                              alt=""
                              src={require(`../assets/icons/halal.png`)}
                            />
                          )}
                          {val.tags.includes('Gluten') && (
                            <img
                              className="card-portion-meal"
                              alt=""
                              src={require(`../assets/icons/gluten-free.png`)}
                            />
                          )}
                          {val.tags.includes('Veg') && (
                            <img
                              className="card-portion-meal"
                              alt=""
                              src={require(`../assets/icons/veg.png`)}
                            />
                          )}
                          {val.tags.includes('Nut-Free') && (
                            <img
                              className="card-portion-meal"
                              alt=""
                              src={require(`../assets/icons/nut-free.png`)}
                            />
                          )}
                          {val.tags.includes('Dairy-Free') && (
                            <img
                              className="card-portion-meal"
                              alt=""
                              src={require(`../assets/icons/dairy-free.png`)}
                            />
                          )}
                          {val.tags.includes('Vegan') && (
                            <img
                              className="card-portion-meal"
                              alt=""
                              src={require(`../assets/icons/vegan.png`)}
                            />
                          )}
                        </div>

                        <div
                          className="protion"
                          style={{ marginLeft: 6, marginBottom: 4 }}
                        >
                          Portion:
                          <img
                            className="card-portion"
                            alt=""
                            src={require(`../assets/images/menu-share/portion-${val.reviewPortion}.png`)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestaurantMeals;
