import React, { useEffect, useRef, useState } from 'react';
import './game.css';
import { LuckyWheel } from '@lucky-canvas/react';
import copy from 'copy-to-clipboard';
// import AnimatedNumber from 'react-awesome-animated-number';
import 'react-awesome-animated-number/dist/index.css';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styles from './wheel.module.css';
import { Carousel, Spin } from 'antd';
import moment from 'moment-timezone';
import axios from 'axios';
import { useLocation } from 'react-router';
import Loading from '../Components/Loading';
import CountDown from './countDown';
import amplitude from 'amplitude-js';
import ProgressContainer from './progressContainer';
import toast, { Toaster } from 'react-hot-toast';
import AnimatedNumber from 'animated-number-react';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const url = 'https://backend.noshpass.com/api';
// const url = 'http://localhost:8000/api';
// const url = 'http://52.39.180.97:8000/api';

const blocks = [{ padding: '24px' }];

const prizes = [
  { background: '#4A4A4A', fonts: [{ text: '' }] },
  { background: '#d67142', fonts: [{ text: '' }] },
  { background: '#4A4A4A', fonts: [{ text: '' }] },
  { background: '#d67142', fonts: [{ text: '' }] },
  { background: '#4A4A4A', fonts: [{ text: '' }] },
  { background: '#d67142', fonts: [{ text: '' }] },
  { background: '#4A4A4A', fonts: [{ text: '' }] },
  { background: '#d67142', fonts: [{ text: '' }] },
];
const buttons = [{ radius: '15%', background: '#E0E0E0' }];

const Game = (): JSX.Element => {
  const params = new URLSearchParams(useLocation().search);
  const from = params.get('from');
  let userId = params.get('i');
  let hashKey = params.get('h');
  const friendUserId = params.get('f');
  const isSelf = params.get('s');
  const machineType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? 'Mobile'
    : 'Desktop';

  amplitude.getInstance().init('21b140df766a7752effb4df18dacad23', userId);

  // 页面显示内容
  const [mainPage, setMainPage] = useState<number>(1);
  // 是否显示FAQ弹窗
  const [popupPanel, setPopupPanel] = useState<boolean>(false);
  // 是否已经拷贝链接
  const [copylink, setCopyLink] = useState(false);

  const [displayMainFootPanel, setDisplayMainFootPanel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wheelInfo, setWheelInfo] = useState<any>(null);
  const [helpUsers, setHelpUsers] = useState<any[]>([]);
  const [isHelp, setIsHelp] = useState(false);
  const [isHelped, setIsHelped] = useState(false);
  const [isSelfHelp, setIsSelfHelp] = useState(false);
  const [showDownload, setShowDownload] = useState(false);

  const [footUsers, setFootUsers] = useState<any[]>([]);
  const [userFeeds, setUserFeeds] = useState<any[]>([]);
  const [isFullDownTime, setIsFullDownTime] = useState(false);
  const [fullDownIndexes, setFullDownIndexes] = useState<number[]>([]);
  const [isGiveCookie, setIsGiveCookie] = useState(false);
  const [giveCookieKey, setGiveCookieKey] = useState('');
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
  const [hasOrder, setHasOrder] = useState(false);
  const [feedIndex, setFeedIndex] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [showCashBack, setShowCashBack] = useState(false);
  const [deepLink, setDeepLink] = useState('');

  const scroll = useRef<HTMLDivElement>(null);

  const wheel = useRef<any>(null);

  useEffect(() => {
    getWheelFeeds(0);
    getTodayOtherWheels(0);
    (async () => {
      setLoading(true);
      await getWheelInfo();
      setLoading(false);
    })();

    amplitude.getInstance().logEvent('nosh_wheel_screen', {
      hashKey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const deadLine = moment
  //     .tz('US/Central')
  //     .set({ hour: 10, minute: 30, second: 0 });
  //   const timer = setInterval(async () => {
  //     const now = moment.tz('US/Central');
  //     if ((helpUsers.length !== 3 && !showDownload) || now.isBefore(deadLine)) {
  //       getWheelInfo();
  //     }
  //   }, 10000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [helpUsers, showDownload]);

  useEffect(() => {
    getDeepLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeepLink = async () => {
    const { data } = await axios.post(`${url}/user/generate-test-link`, {
      data: `&w=${hashKey}`,
    });
    setDeepLink(`https://${data.referal}`);
  };

  const getWheelInfo = async () => {
    const { data } = await axios.get(
      `${url}/wheel/info?u=${userId?.trim()}&h=${hashKey?.trim()}`
    );

    if (data) {
      setHasOrder(!!data.orderId);
      if (data.isLottery) {
        setTimeout(() => {
          setShowCashBack(true);
        }, 1500);
        setMainPage(3);
        setWheelInfo(data);
        return;
      }

      const helpUsers = JSON.parse(data.helpUsers);
      setHelpUsers(helpUsers);

      if (!from) {
        setShowDownload(true);
        amplitude.getInstance().logEvent('receivermain');
        return;
      }

      const hasMySelf = helpUsers.find((v) => v.id === userId);
      setIsSelfHelp(!!hasMySelf);

      if (
        friendUserId &&
        friendUserId !== userId &&
        hashKey &&
        from === 'app'
      ) {
        const hasHelped = helpUsers.find((v) => v.id === friendUserId);
        setIsHelped(!!hasHelped);
        handleUsedCopy('sharePressed');
        setIsHelp(true);
        amplitude.getInstance().logEvent('view_noshwheel', {
          source: 'offline_sharelinkhKey',
        });
        amplitude.getInstance().logEvent('receivermain');
      } else {
        setIsHelp(false);
        if (data.lastCookies < helpUsers.length) {
          await updateLastCookies(helpUsers.length);
          const fullDownArr: number[] = [];
          for (let i = data.lastCookies; i < helpUsers.length; i++) {
            fullDownArr.push(i);
          }
          setIsFullDownTime(true);
          setFullDownIndexes(fullDownArr);
          const arr = Array.from({ length: data.lastCookies }).map(
            (item, index) => index
          );
          setActiveIndexes(arr);
          setTimeout(() => {
            setIsFullDownTime(false);
          }, 2000);
        }
      }
      setWheelInfo(data);
    } else {
      setWheelInfo(null);
      setMainPage(1);
      setShowDownload(false);
      setHelpUsers([]);
      setIsHelp(false);
    }
  };

  const updateLastCookies = async (cookiesAmount) => {
    await axios.post(`${url}/wheel/update-last-cookies`, {
      cookies: cookiesAmount,
      userId,
    });
  };

  const getWheelFeeds = async (pageIndex: number) => {
    const { data } = await axios.post(`${url}/wheel/feeds`, {
      feedIndex: pageIndex,
    });

    data.forEach((v) => {
      const timeLeft = moment
        .tz('US/Central')
        .diff(moment.tz(v.createdAt, 'US/Central'), 'minute');
      if (timeLeft < 60) {
        v.time = `${timeLeft === 0 ? 1 : timeLeft}m`;
      } else if (timeLeft >= 60 && timeLeft < 1440) {
        v.time = `${(timeLeft / 60).toFixed(0)}h`;
      } else if (timeLeft >= 1440 && timeLeft < 43200) {
        v.time = `${(timeLeft / 60 / 24 / 7).toFixed(0)}w`;
      } else {
        const left = moment
          .tz('US/Central')
          .diff(moment.tz(v.createdAt, 'US/Central'), 'month');
        v.time = `${left}months`;
      }
    });

    setUserFeeds(pageIndex ? [...userFeeds.concat(data)] : data);
  };

  const getTodayOtherWheels = async (pageIndex: number) => {
    let { data } = await axios.post(`${url}/wheel/today-wheels`, {
      feedIndex: pageIndex,
    });
    if (data) {
      const u = isSelf ? userId : friendUserId;
      data = data.filter((v) => {
        return !v.isLottery && v.userId !== u;
      });
      setFootUsers(pageIndex ? [...footUsers.concat(data)] : data);
    }
  };

  const handleCreateWheel = async () => {
    setLoading(true);
    const { data } = await axios.post(`${url}/wheel/create`, {
      userId,
    });
    setLoading(false);
    setIsFullDownTime(true);
    setFullDownIndexes([0]);
    setActiveIndexes([]);
    setTimeout(async () => {
      if (data === 'OK') {
        getWheelFeeds(0);
        getTodayOtherWheels(0);
        await getWheelInfo();
      } else {
        alert('Something Wrong, Please try again');
      }
      setIsFullDownTime(false);
      setFullDownIndexes([]);
      setActiveIndexes([]);
    }, 2000);
  };

  const handleHelpFriend = async (isOpenWheel: boolean) => {
    setLoading(true);
    const { data } = await axios.post(`${url}/wheel/help-friend`, {
      userId: isSelf ? userId : friendUserId,
      hashKey: wheelInfo.hashKey,
      isOpenWheel,
    });
    setLoading(false);

    setIsFullDownTime(true);
    setFullDownIndexes([helpUsers.length]);
    const arr = Array.from({ length: helpUsers.length }).map(
      (item, index) => index
    );
    setActiveIndexes(arr);

    setTimeout(
      async () => {
        if (data.message === 'Success') {
          getWheelFeeds(0);
          getTodayOtherWheels(0);

          // 替好友助力完成后总是显示自己的转盘
          if (!isSelf) {
            userId = friendUserId;
            hashKey = '';
          }
          await getWheelInfo();
          setIsFullDownTime(false);
          setFullDownIndexes([]);
          setActiveIndexes([]);
          setIsHelp(false);
        } else {
          alert('Something Wrong, Please try again');
        }
      },
      isSelf ? 2000 : 0
    );
  };

  const handleHelpOtherFriend = async (hashKey: string) => {
    setLoading(true);
    const { data } = await axios.post(`${url}/wheel/help-friend`, {
      userId: isSelf ? userId : friendUserId,
      hashKey,
      isOpenWheel: false,
    });
    amplitude.getInstance().logEvent('noshwheelmain_feed_givecookie', {
      feed_givecookie_count: data.currentCookies,
      giftedbefore: data.giftedBefore,
    });
    const item = footUsers.find((v) => v.hashKey === hashKey);
    if (item) {
      const helpUsers = JSON.parse(item.helpUsers);
      helpUsers.push({
        id: isSelf ? userId : friendUserId,
      });
      item.helpUsers = JSON.stringify(helpUsers);
      setFootUsers([...footUsers]);
    }
    setLoading(false);
    setIsGiveCookie(true);
    setGiveCookieKey(hashKey);
    setTimeout(() => {
      setIsGiveCookie(false);
    }, 1000);
  };

  const handleLottery = async () => {
    setLoading(true);
    const { data } = await axios.post(`${url}/wheel/lottery`, {
      userId,
    });
    setLoading(false);
    if (data) {
      setWheelInfo(data);
      setHasOrder(!!data.orderId);
      amplitude.getInstance().logEvent('noshwheelspinsuccess', {
        cashback: data.cashBackPercent,
      });
      return true;
    } else {
      alert('Something Wrong, Please try again');
      return false;
    }
  };

  const handleUsedCopy = async (type: string) => {
    await axios.post(`${url}/wheel/share-link`, {
      userId: isSelf ? userId : friendUserId,
      hashKey,
      type,
    });
  };

  // 获取页面顶部左右按钮
  const getMainHeadButton = () => {
    return (
      <div
        className="main-title-btn-r"
        style={{ top: isIphoneX() ? 40 : 24 }}
        onClick={() => {
          if (popupPanel) return;
          setPopupPanel(true);
          amplitude.getInstance().logEvent('noshwheelmain_openFAQ');
        }}
      >
        FAQ
      </div>
    );
  };

  const getMainCenterButtonText = (value: boolean) => {
    if (!value) {
      return <span style={{ color: '#ffffff' }}>TAP AGAIN TO COPY LINK</span>;
    } else {
      return (
        <span style={{ color: '#000000' }} role="img" aria-label="">
          Link copied! Share to send 🍪!
        </span>
      );
    }
  };

  const isIphoneX = () => {
    if (typeof window !== 'undefined' && window) {
      return (
        /iphone/gi.test(window.navigator.userAgent) &&
        window.screen.height >= 812
      );
    }
    return false;
  };

  const getCelebrates = () => {
    return (
      <div>
        <img
          src={require('../../src/assets/images/game/woohoo.png')}
          className="game-page-head-text"
          alt=""
        ></img>
        <img
          src={require('../../src/assets/images/marketing/celebration.gif')}
          className="game-page-head-fire"
          alt=""
        ></img>
      </div>
    );
  };

  const mainCenterHegiht = () => {
    if (isHelp || showDownload) return 380;

    if (!isSelfHelp) return 260;

    if (helpUsers.length >= 3) return 260;

    return 410;
  };

  return machineType === 'Desktop' ? (
    <div
      className="main-container-wheel"
      style={{ maxWidth: '450px', position: 'relative' }}
    >
      <div style={{ padding: '0 20px' }}>
        Please re-open this link using the browser on your mobile device!
      </div>
      <div
        style={{
          height: 200,
          overflow: 'hidden',
          position: 'absolute',
          bottom: 0,
        }}
      >
        <img
          src={require('../assets/images/game/main-disc-table.png')}
          alt=""
          className="main-disc-table"
        />
      </div>
    </div>
  ) : (
    <div className="main-container" style={{ maxWidth: '450px' }}>
      <Toaster position="bottom-center" />
      {loading && (
        <div className={styles.mask}>
          <Loading />
        </div>
      )}

      {getMainHeadButton()}

      {mainPage === 1 && (
        <div
          style={{
            width: '100%',
            height: '100vh',
            overflow: 'auto',
            position: 'relative',
            paddingBottom: 126,
            paddingTop: isIphoneX() ? 80 : 60,
          }}
        >
          {/* 页面顶部按钮 */}
          <div className="main-title-1" style={{ marginTop: 10 }}>
            How to Earn $, Eat{' '}
          </div>
          <div className="main-title-2">Guaranteed Winning</div>
          <img
            src={require('../assets/images/game/flow-image.jpg')}
            alt=""
            className="main-game-flow-image"
          />

          {/* 提示下载APP弹窗 */}
          {/* {showDownload && (
            <div className="down-app-popup">
              <img
                src={require('../assets/images/game/gift-box.png')}
                alt=""
                className="down-app-popup-img"
              ></img>
              <div className="down-app-popup-text">
                <span style={{ fontWeight: 'bold' }}>
                  User {wheelInfo.User.mobilePhone}
                </span>{' '}
                just sent you a meal gift. Looks like you’re new. Download app
                to receive this gift and other rewards.
              </div>
              <div
                className="down-app-pupup-button"
                onClick={() => {
                  copy(`w=${hashKey}`);
                  window.location.href = 'https://nosh.page.link/6ih7';
                }}
              >
                DOWNLOAD APP
              </div>
              <div className="down-app-popup-foot">
                Question? Ask sender or text
              </div>
              <div className="down-app-popup-foot">832-583-2900</div>
            </div>
          )} */}

          {/* 显示main-center（助力部分） */}
          <div
            style={{
              height: mainCenterHegiht(),
            }}
          >
            <div
              className="main-center"
              style={{
                opacity: isHelp || showDownload ? 0 : 1,
                overflow: 'auto',
              }}
            >
              <div className="main-center-text">
                <CountDown />
                <span style={{ color: '#000000', margin: '0px 5px' }}>
                  Left for{' '}
                  {!!wheelInfo && (
                    <span style={{ color: '#f06a20' }}>
                      {moment
                        .tz(wheelInfo.deadDate || '', 'US/Central')
                        .format('MMM D')}
                    </span>
                  )}
                  ’s Spin
                </span>
                <img
                  src={require('../assets/images/game/icon/vector.png')}
                  alt=""
                  style={{ width: 15, height: 15 }}
                />
              </div>
              <div className="main-center-text">
                {!helpUsers.length && 'Collect all 3 🍪 to Unlock Spin'}
                {helpUsers.length > 0 &&
                  helpUsers.length < 3 &&
                  'That’s it! Easy!'}
                {helpUsers.length === 3 && 'You got it! Spin now.'}
              </div>
              <ProgressContainer
                helpUsers={helpUsers}
                isFullDownTime={isFullDownTime}
                fullDownIndexes={fullDownIndexes}
                activeIndexes={activeIndexes}
              />

              {!isSelfHelp && helpUsers.length < 3 && (
                <div
                  className="main-center-button"
                  onClick={async () => {
                    if (isFullDownTime) return;

                    if (!wheelInfo) {
                      await handleCreateWheel();
                    } else {
                      await handleHelpFriend(false);
                    }
                    amplitude
                      .getInstance()
                      .logEvent('noshwheelmain_tapbtn_giveurself', {
                        cookie_count: helpUsers.length,
                      });
                  }}
                >
                  <span role="img" aria-label="">
                    TAP HERE TO 🍪 YOURSELF
                  </span>
                </div>
              )}

              {helpUsers.length > 0 && helpUsers.length < 3 && isSelfHelp && (
                <div
                  className="main-center-button"
                  onClick={() => {
                    copy(`https://noshinc.com/cashback?h=${wheelInfo.hashKey}`);
                    setCopyLink(true);
                    handleUsedCopy('copied');
                    toast.success('Copied!');
                    amplitude
                      .getInstance()
                      .logEvent('noshwheelmain_tapbtn_copylink');
                  }}
                >
                  {getMainCenterButtonText(copylink)}
                </div>
              )}

              {/* 显示分享链接相关 */}
              {helpUsers.length > 0 && helpUsers.length < 3 && isSelfHelp && (
                <div>
                  <div
                    className="main-center-share-text"
                    style={{ color: '#000000' }}
                  >
                    <span role="img" aria-label="">
                      PASTE, SHARE LINK TO GIVE FRIEND A 🍪
                    </span>
                  </div>
                  <div className="main-center-share-text">
                    AND RECEIVE ONE IN RETURN TOO!
                  </div>
                  <img
                    src={require('../assets/images/game/share-step.png')}
                    alt=""
                    className="main-center-share-img"
                  />
                  <div>
                    <img
                      src={require('../assets/images/game/icon/share1.png')}
                      alt=""
                      className="main-center-share-icon"
                    />
                    <img
                      src={require('../assets/images/game/icon/share2.png')}
                      alt=""
                      className="main-center-share-icon"
                    />
                    <img
                      src={require('../assets/images/game/icon/share3.png')}
                      alt=""
                      className="main-center-share-icon"
                    />
                    <img
                      src={require('../assets/images/game/icon/share4.png')}
                      alt=""
                      className="main-center-share-icon"
                    />
                    <img
                      src={require('../assets/images/game/icon/share5.png')}
                      alt=""
                      className="main-center-share-icon"
                    />
                  </div>
                </div>
              )}

              <div
                className="main-center-button"
                onClick={() => {
                  setMainPage(2);
                  setTimeout(() => {
                    wheel?.current?.play();
                  }, 2500);
                  setTimeout(() => {
                    wheel?.current?.stop(2);
                  }, 4000);
                  amplitude.getInstance().logEvent('noshwheelmain_tapbtn_spin');
                }}
              >
                SPIN NOW TO WIN
              </div>
              {/* 助力完成，显示可以转动转盘按钮 */}
              {helpUsers.length >= 3 && isSelf && (
                <div
                  className="main-center-button"
                  onClick={() => {
                    setMainPage(2);
                    setTimeout(() => {
                      wheel?.current?.play();
                    }, 2500);
                    setTimeout(() => {
                      wheel?.current?.stop(2);
                    }, 4000);
                    amplitude
                      .getInstance()
                      .logEvent('noshwheelmain_tapbtn_spin');
                  }}
                >
                  SPIN NOW TO WIN
                </div>
              )}
            </div>
          </div>
          {/* 显示轮盘相关部分 */}
          <div style={{ height: 200, overflow: 'hidden', marginTop: 0 }}>
            <img
              src={require('../assets/images/game/main-disc-table.png')}
              alt=""
              className="main-disc-table"
            />
          </div>

          {/* 接受助力弹窗 */}
          {(isHelp || showDownload) && (
            <div
              className="accept-assist-popup"
              style={{ top: isIphoneX() ? 270 : 250 }}
            >
              <div className="accept-assist-popup-container">
                <img
                  src={require('../assets/images/game/accept-popup.gif')}
                  alt=""
                  className="accept-assist-popup-img"
                />
                {!isHelped && (
                  <img
                    src={require('../assets/images/game/accept-popup-right.png')}
                    alt=""
                    className="accept-assist-popup-img-right"
                  />
                )}
              </div>
              {isHelped ? (
                <div className="accept-assist-popup-text">
                  <span role="img" aria-label="">
                    Looks like you’ve already given this user a cookie today.
                    Return tomorrow or try on someone else.
                  </span>
                </div>
              ) : (
                !!wheelInfo && (
                  <div className="accept-assist-popup-text">
                    <span role="img" aria-label="">
                      It’s a great day! You just received a 🍪 cookie
                    </span>
                    <img
                      onClick={() => setPopupPanel(true)}
                      src={require('../assets/images/game/icon/mark.png')}
                      alt=""
                      style={{ width: 16, height: 16 }}
                    />
                    from User *{wheelInfo.User.mobilePhone.slice(-4)}. Accept it
                    and return the favor by tapping button below.
                  </div>
                )
              )}
              {isHelped ? (
                <div
                  className="accept-assist-pupup-button"
                  style={{ marginBottom: 30, animation: 'none' }}
                  onClick={() => {
                    userId = friendUserId;
                    hashKey = '';
                    getWheelInfo();
                    amplitude
                      .getInstance()
                      .logEvent('receivemain_doublegrantmistake');
                  }}
                >
                  <span role="img" aria-label="">
                    GOT IT
                  </span>
                </div>
              ) : (
                <>
                  <div
                    className="accept-assist-pupup-button"
                    onClick={() => {
                      if (showDownload) {
                        amplitude
                          .getInstance()
                          .logEvent('receivermain_acceptcookieNreturn', {
                            cookie_count: helpUsers.length,
                            source: 'web',
                          });
                        copy(`w=${hashKey}`);
                        window.location.href = deepLink;
                        return;
                      }

                      handleHelpFriend(true);
                      amplitude
                        .getInstance()
                        .logEvent('receivermain_acceptcookieNreturn', {
                          cookie_count: helpUsers.length,
                          source: 'app',
                        });
                    }}
                  >
                    <span role="img" aria-label="">
                      Accept 🍪, RETURN FAVOR
                    </span>
                  </div>
                  <div
                    className="accept-assist-popup-foot"
                    onClick={() => {
                      if (showDownload) {
                        amplitude
                          .getInstance()
                          .logEvent('receivermain_acceptcookieNnoreturn', {
                            cookie_count: helpUsers.length,
                            source: 'web',
                          });
                        copy(`w=${hashKey}`);
                        window.location.href = deepLink;
                        return;
                      }

                      handleHelpFriend(false);
                      amplitude
                        .getInstance()
                        .logEvent('receivermain_acceptcookieNnoreturn', {
                          cookie_count: helpUsers.length,
                          source: 'app',
                        });
                    }}
                  >
                    ACCEPT BUT DON’T RECIPROCATE
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}

      {(mainPage === 2 || mainPage === 3) && (
        <div
          style={{
            width: '100%',
            height: '100vh',
            paddingTop: 60,
          }}
        >
          {/* 页面顶部按钮 */}
          <div className="game-page-head">
            <img
              src={require('../assets/images/game/gift-box.png')}
              alt=""
              className="game-page-head-img"
            ></img>
            {mainPage === 3 && getCelebrates()}
          </div>

          {mainPage === 2 && (
            <div style={{ width: '100%', height: '470px', marginTop: '10px' }}>
              <div
                className="game-page-center"
                style={{ marginTop: isIphoneX() ? 20 : -20 }}
              >
                <div className="game-page-lucky-whee">
                  <img
                    src={require('../assets/images/game/pointer.png')}
                    alt=""
                    className="game-page-lucky-whee-pointer"
                  ></img>
                  <LuckyWheel
                    ref={wheel}
                    width={310}
                    height={310}
                    blocks={blocks}
                    prizes={prizes}
                    buttons={buttons}
                    onEnd={async (prize) => {
                      // console.log('prize', prize);
                      // const flag = await handleLottery();
                      // if (!flag) return;
                      // setMainPage(3);
                      // setTimeout(() => {
                      //   setShowCashBack(true);
                      // }, 1500);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {mainPage === 3 && (
            <div>
              <div className="win-page-percent">
                {wheelInfo && (
                  <AnimatedNumber
                    className="win-page-percent-text"
                    value={wheelInfo.cashBackPercent}
                    formatValue={(value) => `${Number(value).toFixed(0)}`}
                    duration={1500}
                  />
                )}
              </div>
              <div className="win-page-comtext">
                <div className="win-page-text1" style={{ marginTop: 10 }}>
                  <span style={{ color: '#000000' }}>INSTANT</span>
                  <span style={{ color: '#D25147' }}>CASH BACK</span>
                </div>
                {hasOrder ? (
                  <div>
                    <div className="win-page-text2">
                      $
                      {showCashBack ? (
                        <AnimatedNumber
                          value={wheelInfo.cashBack.toFixed(0)}
                          formatValue={(value) => `${Number(value).toFixed(2)}`}
                          duration={1500}
                        />
                      ) : (
                        0
                      )}
                      <span className="win-page-text">
                        (Can Be Used Instantly)
                      </span>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {!!wheelInfo && (
                  <div
                    style={{
                      fontSize: 14,
                      marginTop: 10,
                      color: '#4A4A4A',
                      width: 300,
                      margin: '10px auto 0 auto',
                    }}
                  >
                    Place an order now in your NoshPass app to get $
                    {wheelInfo.cashBackPercent}% instant cashback on your
                    purchase.
                  </div>
                )}
              </div>
              <img
                src={require('../../src/assets/images/game/win-page-foot.png')}
                alt=""
                style={{ width: '100%', height: 200 }}
              ></img>
            </div>
          )}
        </div>
      )}

      {/* 页面底部内容 */}
      {mainPage !== 2 && (
        <div
          className="main-foot"
          style={{ height: displayMainFootPanel ? '80vh' : 144 }}
          onClick={() => {
            setDisplayMainFootPanel(!displayMainFootPanel);
            amplitude
              .getInstance()
              .logEvent(
                displayMainFootPanel
                  ? 'noshwheelmain_feed_taptocollapse'
                  : 'noshwheelmain_feed_taptoexpand'
              );
          }}
        >
          {displayMainFootPanel ? (
            <div className="main-foot-controller">
              Tap to Collapse <CaretDownOutlined style={{ marginLeft: 4 }} />
            </div>
          ) : (
            <div className="main-foot-controller">
              Tap to Expand <CaretUpOutlined style={{ marginLeft: 4 }} />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: 20,
                alignItems: 'center',
              }}
            >
              <img
                src={require('../assets/images/game/foot-bar.png')}
                alt=""
                className="main-foot-head-image"
              />
            </div>

            {displayMainFootPanel && (
              <div className="main-foot-active" ref={scroll}>
                {new Array(Math.max(userFeeds.length + footUsers.length))
                  .fill('')
                  .map((v, i) => {
                    const helpUsers = !!footUsers[i]
                      ? JSON.parse(footUsers[i].helpUsers)
                      : [];
                    const u = isSelf ? userId : friendUserId;
                    const hasHelp = helpUsers.find((o) => o.id === u);
                    return (
                      <div key={i}>
                        {!!footUsers[i] && (
                          <div style={{ padding: '0 20px', marginBottom: 10 }}>
                            <div className="main-foot-list-container">
                              <div style={{ display: 'flex' }}>
                                <img
                                  src={footUsers[i].User.avatarUrl}
                                  alt=""
                                  className="main-foot-list-user"
                                />
                                <div className="main-foot-list-bg">
                                  <img
                                    src={require('../assets/images/game/stamp2.png')}
                                    alt=""
                                    style={{ width: 24, height: 24 }}
                                  />
                                </div>
                                <div className="main-foot-list-bg">
                                  {!!hasHelp && (
                                    <img
                                      src={require('../assets/images/game/stamp2.png')}
                                      alt=""
                                      className="main-foot-list-action"
                                      style={{
                                        animation:
                                          isGiveCookie &&
                                          giveCookieKey === footUsers[i].hashKey
                                            ? 'scales 1s linear'
                                            : '',
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="main-foot-list-text">
                                  {footUsers[i].User.mobilePhone}
                                </div>
                              </div>
                              {!showDownload && (
                                <div
                                  className={
                                    hasHelp
                                      ? 'main-foot-list-button2'
                                      : 'main-foot-list-button1'
                                  }
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (hasHelp) return;
                                    if (showDownload) {
                                      amplitude
                                        .getInstance()
                                        .logEvent(
                                          'nosh_wheel_go_to_app_store(give_cookie)'
                                        );
                                      copy(`w=${hashKey}`);
                                      window.location.href = deepLink;
                                      return;
                                    }

                                    handleHelpOtherFriend(footUsers[i].hashKey);
                                  }}
                                >
                                  <span role="img" aria-label="">
                                    GIVE 🍪
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {!!userFeeds[i] && (
                          <div className="main-foot-active-feeds">
                            <span
                              className="carousel-text"
                              style={{ fontSize: 13 }}
                            >
                              {userFeeds[i].feed}
                            </span>
                            <div
                              className="carousel-time"
                              style={{ fontSize: 13 }}
                            >
                              {userFeeds[i].time}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                <div
                  className="main-more"
                  onClick={async (event) => {
                    event.stopPropagation();
                    if (isLoadMore) return;
                    setIsLoadMore(true);
                    const pageIndex = feedIndex + 1;
                    await getWheelFeeds(pageIndex);
                    await getTodayOtherWheels(pageIndex);
                    setFeedIndex(pageIndex);
                    setIsLoadMore(false);
                  }}
                >
                  {isLoadMore && (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 14,
                            color: '#f06a20',
                            marginRight: 10,
                          }}
                          spin
                        />
                      }
                    />
                  )}
                  {isLoadMore ? 'loading more...' : 'more'}
                </div>
              </div>
            )}

            {!displayMainFootPanel && (
              <div
                style={{
                  width: '100%',
                  height: 88,
                  boxSizing: 'border-box',
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Carousel
                  autoplay
                  autoplaySpeed={3000}
                  dotPosition="right"
                  dots={false}
                >
                  {new Array(Math.max(userFeeds.length + footUsers.length))
                    .fill('')
                    .map((v, i) => {
                      const helpUsers = !!footUsers[i]
                        ? JSON.parse(footUsers[i].helpUsers)
                        : [];
                      const u = isSelf ? userId : friendUserId;
                      const hasHelp = helpUsers.find((o) => o.id === u);
                      return (
                        <div key={i}>
                          <div
                            style={{
                              height: 60,
                              width: '100%',
                            }}
                          >
                            {!!footUsers[i] && (
                              <div className="main-foot-list-container">
                                <div style={{ display: 'flex' }}>
                                  <img
                                    src={footUsers[i].User.avatarUrl}
                                    alt=""
                                    className="main-foot-list-user"
                                  />
                                  <div className="main-foot-list-bg">
                                    <img
                                      src={require('../assets/images/game/stamp2.png')}
                                      alt=""
                                      style={{ width: 24, height: 24 }}
                                    />
                                  </div>
                                  <div className="main-foot-list-bg">
                                    {!!hasHelp && (
                                      <img
                                        src={require('../assets/images/game/stamp2.png')}
                                        alt=""
                                        className="main-foot-list-action"
                                        style={{
                                          animation:
                                            isGiveCookie &&
                                            giveCookieKey ===
                                              footUsers[i].hashKey
                                              ? 'scales 1s linear'
                                              : '',
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="main-foot-list-text">
                                    {footUsers[i].User.mobilePhone}
                                  </div>
                                </div>
                                {!showDownload && (
                                  <div
                                    className={
                                      hasHelp
                                        ? 'main-foot-list-button2'
                                        : 'main-foot-list-button1'
                                    }
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      if (hasHelp) return;
                                      if (showDownload) {
                                        copy(`w=${hashKey}`);
                                        window.location.href = deepLink;
                                        return;
                                      }
                                      amplitude
                                        .getInstance()
                                        .logEvent(
                                          'nosh_wheel_friend_feed_give'
                                        );
                                      handleHelpOtherFriend(
                                        footUsers[i].hashKey
                                      );
                                    }}
                                  >
                                    <span role="img" aria-label="">
                                      GIVE 🍪
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                            {!!userFeeds[i] && (
                              <div style={{ height: 30 }}>
                                <span className="carousel-text">
                                  {userFeeds[i].feed}
                                </span>
                                <div className="carousel-time">
                                  {userFeeds[i].time}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </Carousel>
              </div>
            )}
          </div>
        </div>
      )}

      {/* 显示FAQ弹出窗口 */}
      <BottomSheet open={popupPanel} onDismiss={() => setPopupPanel(false)}>
        <div className="popup">
          <div className="main-title-1">How to Earn $, Eat </div>
          <div className="main-title-2">Guaranteed Winning</div>
          <img
            src={require('../assets/images/game/popup-text.png')}
            alt=""
            className="popup-text-img"
          ></img>
          <div
            className="pupup-button"
            onClick={() => {
              setPopupPanel(false);
              amplitude.getInstance().logEvent('noshwheelmain_closeFAQ');
            }}
          >
            CLOSE
          </div>
          <div className="pupup-foot">Questions? Text 832-583-2900</div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default Game;
