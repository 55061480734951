import React from 'react';
import { useParams } from 'react-router';

const PoolJump = (): JSX.Element => {
  const { hashKey } = useParams<any>();
  window.location.href = `https://nosh.page.link/${hashKey}`;

  return <></>;
};

export default PoolJump;
