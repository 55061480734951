import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styles from './driver.module.scss';
import Loading from '../Components/Loading';
import { BASE_URL } from '../utils/Constants';
import Restaurant from './components/restaurant';
import NoshStop from './components/noshStop';
import FoodSwap from './components/foodSwap';
import Stamp from './components/stamp';

const DriverManager = (): JSX.Element => {
  const { hashKey } = useParams<any>();

  const url = BASE_URL;
  const tabs = ['Restaurant', 'FoodSwap', 'NoshStop', 'Monitor'];
  const [loading, setLoading] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [orderType, setOrderType] = useState<any>();
  const [driverRoutes, setDriverRoutes] = useState<any>();
  const [active, setActive] = useState(1);
  const [invalidLink, setInvalidLink] = useState(false);

  useEffect(() => {
    getDriverRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDriverRoutes = async () => {
    setLoading(true);
    const uri = hashKey
      ? `${url}/driver/routes/${hashKey}`
      : `${url}/driver/routes`;
    const { data } = await Axios.get(uri);
    setLoading(false);
    if (data === null) {
      setInvalidLink(true);
      return;
    }
    setTotalOrders(data.totalOrders);

    // 控制按钮loading
    data.driverRoutes.forEach((d) => {
      d.u1ArrivedLoading = false;
      d.u1LeftLoading = false;
      d.orderInRestaurant.forEach((v) => {
        v.arrivedLoading = false;
        v.leftLoading = false;
        v.delayLoading = false;
      });
      d.orderInLocations.forEach((v) => {
        v.arrivedLoading = false;
        v.leftLoading = false;
        v.uploadLoading = false;
      });
    });

    setDriverRoutes(data.driverRoutes);
    setOrderType(data.orderType);
  };

  return invalidLink ? (
    <div style={{ margin: '50px 20px' }}>
      Link invalid, make sure you are using link for today.
    </div>
  ) : (
    <div className={styles.shiftBody}>
      {loading && <Loading />}
      {!loading && (
        <div className={styles.container}>
          <div style={{ display: active === 1 ? 'block' : 'none' }}>
            <div className="manager-view">
              <h3> Total Orders: {totalOrders}</h3>
            </div>
            <hr />
            {driverRoutes && (
              <Restaurant driverRoutes={driverRoutes} orderType={orderType} />
            )}
          </div>
          <div style={{ display: active === 2 ? 'block' : 'none' }}>
            {driverRoutes && (
              <FoodSwap driverRoutes={driverRoutes} orderType={orderType} />
            )}
          </div>
          <div style={{ display: active === 3 ? 'block' : 'none' }}>
            {driverRoutes && <NoshStop driverRoutes={driverRoutes} />}
          </div>
          <div style={{ display: active === 4 ? 'block' : 'none' }}>
            {driverRoutes && (
              <Stamp driverRoutes={driverRoutes} orderType={orderType} />
            )}
          </div>
        </div>
      )}
      <div className={styles.tabbar}>
        {tabs.map((v, i) => (
          <div
            key={i}
            className={`${styles.tabitem} ${
              active === i + 1 ? styles.active : ''
            }`}
            onClick={() => setActive(i + 1)}
          >
            {v}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DriverManager;
