import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import styles from '../driver.module.scss';

const errorColor = '#d9363e';
const correctColor = '#389e0d';

const Stamp = (props: { driverRoutes: any }): JSX.Element => {
  const [maxReso, setMaxReso] = useState(0);
  const [maxNoshStop, setMaxNoshStop] = useState(0);
  const routes = props.driverRoutes;

  useEffect(() => {
    routes.forEach((r) => {
      if (r.orderInRestaurant.length > maxReso) {
        setMaxReso(r.orderInRestaurant.length);
      }
      if (r.orderInLocations.length > maxNoshStop) {
        setMaxNoshStop(r.orderInLocations.length);
      }
    });
  });

  const computedReso = (date: string, reso: any) => {
    if (!reso) return <>-</>;

    const outOfLeft = moment
      .tz(reso.leftTime, 'US/Central')
      .isAfter(moment.tz(date, 'US/Central').set({ hour: 11, minute: 30 }));

    const outOfArrived = moment
      .tz(reso.arrivedTime, 'US/Central')
      .isAfter(moment.tz(date, 'US/Central').set({ hour: 11, minute: 10 }));

    if (reso.leftTime || reso.delayTime || reso.arrivedTime) {
      return (
        <>
          {reso.arrivedTime && (
            <>
              <span
                style={{
                  color: outOfArrived ? errorColor : correctColor,
                  marginRight: 10,
                }}
              >
                ARRIVE
                {delayTime(
                  outOfArrived,
                  moment.tz(reso.arrivedTime, 'US/Central'),
                  moment.tz(date, 'US/Central').set({ hour: 11, minute: 10 })
                )}
              </span>
              <br />
            </>
          )}
          {reso.delayTime && (
            <>
              <span style={{ color: errorColor, marginRight: 10 }}>
                DELAY ({moment.tz(reso.delayTime, 'US/Central').format('HH:mm')}
                )
              </span>
              <br />
            </>
          )}
          {reso.leftTime && (
            <span style={{ color: outOfLeft ? errorColor : correctColor }}>
              LEFT
              {delayTime(
                outOfLeft,
                moment.tz(reso.leftTime, 'US/Central'),
                moment.tz(date, 'US/Central').set({ hour: 11, minute: 30 })
              )}
            </span>
          )}
        </>
      );
    }

    return <>*</>;
  };

  const computedNoshStop = (date: string, ns: any) => {
    if (!ns) return <>-</>;

    const outOfLeft = moment
      .tz(ns.leftTime, 'US/Central')
      .isAfter(moment.tz(`${date} ${ns.pickupEnd}`, 'US/Central'));

    const outOfArrived = moment
      .tz(ns.arrivedTime, 'US/Central')
      .isAfter(moment.tz(`${date} ${ns.pickupStart}`, 'US/Central'));

    if (ns.arrivedTime || ns.leftTime) {
      return (
        <>
          {ns.arrivedTime && (
            <>
              <span
                style={{
                  color: outOfArrived ? errorColor : correctColor,
                  marginRight: 10,
                }}
              >
                ARRIVE
                {delayTime(
                  outOfArrived,
                  moment.tz(ns.arrivedTime, 'US/Central'),
                  moment.tz(`${date} ${ns.pickupStart}`, 'US/Central')
                )}
              </span>
              <br />
            </>
          )}
          {ns.leftTime && (
            <>
              <span style={{ color: outOfLeft ? errorColor : correctColor }}>
                LEFT
                {delayTime(
                  outOfLeft,
                  moment.tz(ns.leftTime, 'US/Central'),
                  moment.tz(`${date} ${ns.pickupEnd}`, 'US/Central')
                )}
              </span>
              <br />
            </>
          )}
        </>
      );
    }

    return <>*</>;
  };

  const computedUhual = (route: any, uType: number) => {
    const arrviedTime =
      uType === 1 ? route['u1ArrivedTime'] : route['u2ArrivedTime'];
    const leftTime = uType === 1 ? route['u1LeftTime'] : route['u2LeftTime'];
    if (!arrviedTime && !leftTime) return <>*</>;

    return (
      <>
        {arrviedTime && (
          <>
            <span
              style={{
                color: correctColor,
                marginRight: 10,
              }}
            >
              ARRIVE ({moment.tz(arrviedTime, 'US/Central').format('HH:mm')})
            </span>
            <br />
          </>
        )}
        {leftTime && (
          <span style={{ color: correctColor }}>
            LEFT ({moment.tz(leftTime, 'US/Central').format('HH:mm')})
          </span>
        )}
      </>
    );
  };

  const delayTime = (
    isTimeOut: boolean,
    currenTime: Moment,
    targetTime: Moment
  ) => {
    if (!isTimeOut) return '';

    const diff = currenTime.diff(targetTime, 'minute');
    return diff > 0 ? `(+${diff.toFixed(0)})` : '';
  };

  const table = () => {
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>DRIVER</th>
            <th>UH1</th>
            {new Array(maxReso).fill('').map((v, i) => (
              <th key={i}>RESO{i + 1}</th>
            ))}
            <th>UH2</th>
            {new Array(maxNoshStop).fill('').map((v, i) => (
              <>
                <th key={'stop' + i}>STOP{i + 1}</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {routes.map((route, index) => (
            <tr key={index}>
              <td>DR#{route.driverId}</td>
              <td>{computedUhual(route, 1)}</td>
              {new Array(maxReso).fill('').map((v, i) => (
                <td key={'r' + i}>
                  {computedReso(route.date, route.orderInRestaurant[i])}
                </td>
              ))}
              <td>{computedUhual(route, 2)}</td>
              {new Array(maxNoshStop).fill('').map((v, i) => (
                <td key={'s' + i}>
                  {computedNoshStop(route.date, route.orderInLocations[i])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className={styles.recordCard}>
        <div>{table()}</div>
      </div>
      <div className={styles.noshstopTitle}>
        <span style={{ marginRight: 10 }}>Driver Path:</span>
        <div
          className={styles.linkBtn}
          onClick={() => window.open(`https://noshpass.com/driver.html`)}
        >
          Tap to Open
        </div>
      </div>
    </>
  );
};

export default Stamp;
