import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Axios from 'axios';
import { BASE_URL } from '../utils/Constants';

const Verify = (): JSX.Element => {
  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token');
  const [title, setTile] = useState('');

  useEffect(() => {
    (async () => {
      const { data } = await Axios.post(`${BASE_URL}/nosh-simple/verify`, {
        token,
      });
      if (!data) {
        setTile('Bad or expired connection');
      } else {
        setTile('The verification is normal, jumping....');
        setTimeout(() => {
          window.location.href = 'https://noshsimple.com/final';
        }, 500);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        background: '#F5F5F5',
      }}
    >
      <div
        style={{
          marginTop: 40,
          display: 'flex',
          justifyContent: 'flex-start',
          height: 500,
          width: 600,
          background: '#ffffff',
          boxSizing: 'border-box',
          padding: 60,
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            color: '#E56064',
            fontSize: 28,
            fontWeight: 900,
          }}
        >
          NoshSimple - Verify your email
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: 60,
          }}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default Verify;
