import React, { useEffect, useState } from 'react';
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons';
import 'react-awesome-animated-number/dist/index.css';
import styles from './driver-route-update.module.css';
import Loading from '../Components/Loading';
import { BASE_URL } from '../utils/Constants';
import Axios from 'axios';

const DirverRouteUpdate = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [driverData, setDriverData] = useState<any[]>([]);
  const url = BASE_URL;

  useEffect(() => {
    queryManagerView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryManagerView = async () => {
    setLoading(true);
    try {
      const data = (
        await Axios.get(`${url}/tools/today-drivers-data`, {
          headers: {
            'x-access-token': 'd8c8ca45-46a3-453d-a6e0-22dad9484f9d',
          },
        })
      ).data;
      const drData: any[] = [];
      data.forEach((v) => {
        drData.push({
          hashKey: v.hashKey,
          driverId: v.driverId,
          value: JSON.parse(v.value),
        });
      });
      drData.sort((a, b) => {
        return a.driverId - b.driverId;
      });
      setDriverData(drData);
      console.log(drData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleMove = (type, routeIndex, resoIndex) => {
    if (routeIndex === 0 && type === 'left') return;
    if (routeIndex === driverData.length - 1 && type === 'right') return;

    const item = driverData[routeIndex].value.ordersInRestaurant.data.splice(
      resoIndex,
      1
    );
    const currentIndex = type === 'left' ? routeIndex - 1 : routeIndex + 1;
    driverData[currentIndex].value.ordersInRestaurant.data.push(item[0]);
    setDriverData([...driverData]);
  };

  const handleSort = (type, routeIndex, resoIndex) => {
    const resoData = driverData[routeIndex].value.ordersInRestaurant.data;

    if (type === 'up' && resoIndex === 0) return;
    if (type === 'down' && resoIndex === resoData.length - 1) return;

    const currentIndex = type === 'up' ? resoIndex - 1 : resoIndex + 1;
    resoData[resoIndex] = resoData.splice(
      currentIndex,
      1,
      resoData[resoIndex]
    )[0];
    setDriverData([...driverData]);
  };

  const handleSave = async () => {
    console.log(driverData);
    setLoading(true);
    try {
      await Axios.post(
        `${url}/tools/update-driver-route`,
        {
          driverData,
        },
        {
          headers: {
            'x-access-token': 'd8c8ca45-46a3-453d-a6e0-22dad9484f9d',
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className={styles.content}>
      <div className={styles.head}>
        <div className={styles.btn} onClick={handleSave}>
          save
        </div>
      </div>
      <div className={styles.container}>
        {loading && (
          <div className={styles.mask}>
            <Loading />
          </div>
        )}
        {driverData.map((val, index) => (
          <div className={styles.listContainer} key={index}>
            <div className={styles.title}>DR {val.driverId}</div>
            <div className={styles.list}>
              {val.value.ordersInRestaurant.data.map((v, i) => (
                <div className={styles.item} key={i}>
                  {v.restaurantName}
                  <div className={styles.iconList}>
                    <LeftOutlined
                      className={styles.icon}
                      onClick={() => handleMove('left', index, i)}
                    />
                    <RightOutlined
                      className={styles.icon}
                      onClick={() => handleMove('right', index, i)}
                    />
                    <UpOutlined
                      className={styles.icon}
                      onClick={() => handleSort('up', index, i)}
                    />
                    <DownOutlined
                      className={styles.icon}
                      onClick={() => handleSort('down', index, i)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DirverRouteUpdate;
