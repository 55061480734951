import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const AppButton = (props: {
  text: string;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
}): JSX.Element => {
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

  return (
    <>
      <div
        className={props.className}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
      >
        {!!props.loading && (
          <Spin
            indicator={antIcon}
            style={{ color: '#ffffff', marginRight: 10 }}
          />
        )}
        {props.text}
      </div>
    </>
  );
};

export default AppButton;
