import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import styles from '../noshsimple-driver.module.scss';
import { JsonToTable } from 'react-json-to-table';
import Axios from 'axios';
import { SIMPLE_URL } from '../../utils/Constants';
import { Button, Input } from 'antd';
import AppButton from '../../DriverRoute/components/appButton';
import toast from 'react-hot-toast';

const Foods = (props: { driverRoutes: any }): JSX.Element => {
  const [driverRoute, setDriverRoute] = useState<any>({});
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.driverRoutes) {
      const meals: any[] = [];
      const { ordersInLocations } = props.driverRoutes;
      ordersInLocations.forEach((o) => {
        o.orders.forEach((m) => {
          const meal = meals.find((l) => l['dish name'] === m.mealTitle);
          if (meal) {
            meal.total += m.mealAmount;
          } else {
            meals.push({
              'dish name': m.mealTitle,
              total: m.mealAmount,
            });
          }
        });
      });
      setDriverRoute({
        driverId: props.driverRoutes.driverId,
        hashKey: props.driverRoutes.hashKey,
        startTime: props.driverRoutes.startTime,
        endTime: props.driverRoutes.endTime,
        contactPhone: props.driverRoutes.contactPhone,
        meals,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.driverRoutes]);

  const saveDriverPhone = async (hashKey: string) => {
    // eslint-disable-next-line no-useless-escape
    const reg = /^(1?|(1\-)?)\d{10,12}$/;
    if (!reg.test(phone)) {
      alert('wrong phone');
      return;
    }
    await Axios.post(`${SIMPLE_URL}/driver/edit-contact-phone`, {
      hashKey,
      phone,
    });
    driverRoute.contactPhone = phone;
    setDriverRoute({ ...driverRoute });
  };

  const editRoute = async (hashKey: string, type: string) => {
    if (window.confirm(`Are you sure to confirm it?`)) {
      setLoading(true);
      const { data } = await Axios.post(
        `${SIMPLE_URL}/driver/start-or-finish`,
        {
          hashKey,
          type,
        }
      );
      setLoading(false);
      if (!data) {
        toast.error(`Something wrong, Please try again`);
        return;
      }
      // driverRoute[type] = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
      // setDriverRoute({ ...driverRoute });
      window.location.reload();
    }
  };

  return (
    <>
      <div className={styles.recordCard}>
        <h3 className={styles.h3}>
          Driver Shift Details [B#{driverRoute.driverId}] [
          {moment.tz(props.driverRoutes.date, 'US/Central').format('MMM DD')}][
          {moment.tz(props.driverRoutes.date, 'US/Central').format('dd')}]
        </h3>
        {!driverRoute.startTime && (
          <AppButton
            text="START SHIFT"
            className={styles.startShift}
            loading={loading}
            onClick={() => {
              if (loading) return;
              editRoute(driverRoute.hashKey, 'startTime');
            }}
          />
        )}
        {!!driverRoute.startTime && !driverRoute.endTime && (
          <AppButton
            text="END SHIFT"
            className={styles.endShift}
            loading={loading}
            onClick={() => {
              if (loading) return;
              editRoute(driverRoute.hashKey, 'endTime');
            }}
          />
        )}
        {!!driverRoute.startTime && (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Driver Phone</div>
            {!!driverRoute.contactPhone ? (
              <div style={{ textAlign: 'left' }}>
                {driverRoute.contactPhone}
              </div>
            ) : (
              <Input.Group compact>
                <Input
                  style={{ width: 'calc(100% - 80px)', textAlign: 'left' }}
                  defaultValue=""
                  value={phone}
                  maxLength={10}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <Button
                  onClick={() => saveDriverPhone(driverRoute.hashKey)}
                  type="primary"
                  style={{
                    width: 80,
                    backgroundColor: '#f06a20',
                    borderColor: '#f06a20',
                  }}
                >
                  Submit
                </Button>
              </Input.Group>
            )}
          </div>
        )}
        <div className={styles.section}>
          <div className={styles.sectionTitle}>Orders to from NoshSimple</div>
          <JsonToTable json={driverRoute.meals} />
        </div>
      </div>
    </>
  );
};

export default Foods;
