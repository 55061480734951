import React, { FC, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import axios from 'axios';
import { BASE_URL } from '../utils/Constants';
import { Select, Spin } from 'antd';

const ImagesUpload: FC = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyBfQogCed6AY7NDM2mvy2DDDM1_8mi8KqA',
    authDomain: 'noshpass-new.firebaseapp.com',
    projectId: 'noshpass-new',
    storageBucket: 'noshpass-new.appspot.com',
    messagingSenderId: '652195764522',
    appId: '1:652195764522:web:3a546ca07c568c885ce653',
    measurementId: 'G-HVXE3B1ZG6',
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [meals, setMeals] = useState<any[]>([]);
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [cps, setCPs] = useState<any[]>([]);
  const [selectedReso, setSelectedReso] = useState<any>({});
  const [selectedMeal, setSelectedMeal] = useState<any>({});
  const [selectedCP, setSelectedCP] = useState<any>({});

  useEffect(() => {
    refreshRestaurant();
    refreshPickupLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshRestaurant = async () => {
    const { data } = await axios.get(`${BASE_URL}/restaurants/list`);
    const res = data.data;
    res.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
    setRestaurants(res);
  };

  const refreshMenu = async (restaurantId) => {
    setLoading(true);
    const { data } = await axios.get(
      `${BASE_URL}/meal-by-restaurant?restaurantId=${restaurantId}`
    );
    setLoading(false);
    setMeals(data);
  };

  const refreshPickupLocations = async () => {
    const { data } = await axios.get(`${BASE_URL}/pickupLocations`);
    setCPs(data.data);
  };

  const changeResoLogo = async (e) => {
    setLoading(true);
    const imgId = v4().slice(-8);
    const storageRef = ref(storage, `Restaurants/${imgId}.png`);
    try {
      const result = await uploadBytes(storageRef, e.target.files[0]);
      const imageLink = `https://firebasestorage.googleapis.com/v0/b/noshpass-new.appspot.com/o/Restaurants%2F${result.metadata.name}?alt=media&token=faad9c36-65fc-4695-a3c8-a274c0375b5b`;
      await axios.post(`${BASE_URL}/meals/update-reso-logo`, {
        id: selectedReso.id,
        imageLink,
      });
      setLoading(false);
      alert('upload success!');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert(e);
    }
  };

  const changeMealImage = async (e) => {
    setLoading(true);
    const imgId = v4().slice(-8);
    const storageRef = ref(storage, `Meals/${imgId}.png`);
    try {
      const result = await uploadBytes(storageRef, e.target.files[0]);
      const imageLink = `https://firebasestorage.googleapis.com/v0/b/noshpass-new.appspot.com/o/Meals%2F${result.metadata.name}?alt=media&token=faad9c36-65fc-4695-a3c8-a274c0375b5b`;
      await axios.post(`${BASE_URL}/meals/update-meal-image`, {
        id: selectedMeal.id,
        imageLink,
      });
      setLoading(false);
      alert('upload success!');
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert(e);
    }
  };

  const changeLocations = async (e) => {
    setLoading(true);
    const imgId = v4().slice(-8);
    const storageRef = ref(storage, `Locations/${imgId}.png`);
    try {
      const result = await uploadBytes(storageRef, e.target.files[0]);
      const imageLink = `https://firebasestorage.googleapis.com/v0/b/noshpass-new.appspot.com/o/Locations%2F${result.metadata.name}?alt=media&token=faad9c36-65fc-4695-a3c8-a274c0375b5b`;
      await axios.post(`${BASE_URL}/meals/update-noshstop-image`, {
        id: selectedCP.id,
        imageLink,
      });
      setLoading(false);
      alert('upload success!');
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert(e);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {loading && (
        <Spin
          size="large"
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            left: 0,
          }}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            Restaurant:{' '}
            <Select
              defaultValue=""
              style={{ width: 300 }}
              onChange={(e) => {
                const reso = restaurants.find((v) => v.id === e);
                setSelectedReso(reso);
                setSelectedMeal({});
                refreshMenu(e);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  !!option!.children &&
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                );
              }}
            >
              {restaurants.map((v, i) => (
                <Option value={v.id} key={i}>
                  {v.title}
                </Option>
              ))}
            </Select>
          </div>
          {!!selectedReso.id && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ width: 200, marginTop: 20, textAlign: 'left' }}>
                Logo:
              </div>
              <img
                style={{ width: 200, height: 200, borderRadius: '100%' }}
                src={selectedReso.firebaseLogoImage}
                alt=""
              />
              <input
                type="file"
                style={{ marginTop: 20, width: 200 }}
                onChange={(e) => changeResoLogo(e)}
              />
            </div>
          )}
        </div>
        <div
          style={{
            marginLeft: 100,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <div>
            Meal:
            <Select
              defaultValue=""
              style={{ width: 600 }}
              onChange={(e) => {
                const meal = meals.find((v) => v.id === e);
                setSelectedMeal(meal);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  !!option!.children &&
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                );
              }}
            >
              {meals.map((v, i) => (
                <Option value={v.id} key={i}>
                  {v.title}
                </Option>
              ))}
            </Select>
          </div>
          {!!selectedMeal.id && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ width: 200, marginTop: 20, textAlign: 'left' }}>
                Image:
              </div>
              <img
                style={{ width: 333, height: 200 }}
                src={selectedMeal.firebaseImgFilename}
                alt=""
              />
              <input
                type="file"
                style={{ marginTop: 20, width: 200 }}
                onChange={(e) => changeMealImage(e)}
              />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: 700,
          marginTop: 100,
        }}
      >
        <div>
          NoshStop:{' '}
          <Select
            defaultValue=""
            style={{ width: 400 }}
            onChange={(e) => {
              const location = cps.find((v) => v.id === e);
              setSelectedCP(location);
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                !!option!.children &&
                ((option!.children as unknown) as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              );
            }}
          >
            {cps.map((v, i) => (
              <Option value={v.id} key={i}>
                {v.name}
              </Option>
            ))}
          </Select>
        </div>
        {!!selectedCP.id && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img
              style={{ width: 200, height: 200, marginTop: 20 }}
              src={selectedCP.firebaseImageIds[0]}
              alt=""
            />
            <input
              type="file"
              style={{ marginTop: 20, width: 200 }}
              onChange={(e) => changeLocations(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagesUpload;
