import React, { useEffect, useState } from 'react';
import './styles.scss';
import { JsonToTable } from 'react-json-to-table';
import Axios from 'axios';
import { useParams } from 'react-router';
import Loading from '../Components/Loading';
import { Spin } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import { Collapse } from 'antd';

const OrderSummary = (): JSX.Element => {
  const { Panel } = Collapse;
  const [summaryInfo, setSummaryInfo] = useState<any>();
  const [leOrders, setLEOrders] = useState<any>();
  const [loading, setLoading] = useState(false);
  // 新样式
  const [orders, setOrders] = useState<any[]>();

  const { restaurantId, hashKey, orderType } = useParams<any>();

  const url = 'https://backend.noshpass.com/api';
  // const url = 'http://localhost:8000/api';
  const REFRESH_SECS = 30;

  useEffect(() => {
    querySummary();
    const repeat = setInterval(() => querySummary(), REFRESH_SECS * 1000);

    return () => {
      clearInterval(repeat);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const querySummary = async () => {
    setLoading(true);
    const orderSummary = (
      await Axios.get(
        `${url}/orders/restaurant/daily-summary/${restaurantId}/${orderType}/${hashKey}`
      )
    ).data;
    if (orderSummary.restaurantName) {
      setSummaryInfo({
        Restaurant: orderSummary.restaurantName,
        Date: orderSummary.date,
        'Total Orders': orderSummary.totalOrder,
        isConfirm: orderSummary.isConfirm,
        CollectionTime: orderSummary?.summary[0]?.restaurantPickupTime,
      });
    }
    const le = orderSummary?.summary?.find(
      (s) => s.ordersType === 'GENERAL_LE'
    );
    if (le) {
      setLEOrders({
        'Collection Time': le?.restaurantPickupTime,
        Orders:
          le.orders.map((o) => ({
            'Meal Name': o.mealName,
            'Order #': o.count,
          })) || [],
      });

      // 新样式
      const d: any[] = [];
      le.orders.forEach((v, i) => {
        d.push([]);
        new Array(v.count).fill('').map(() =>
          d[i].push({
            'Meal Name': v.mealName,
            Order: 1,
          })
        );
      });
      setOrders(d);
      console.log(d);
    }
    setLoading(false);
  };

  const handleConfrim = async () => {
    if (window.confirm(`Please make sure you are ready !`)) {
      const { data } = await Axios.post(
        `${url}/orders/restaurant/daily-summary-confirm/${restaurantId}/${hashKey}`
      );
      if (data === 'OK') {
        window.location.reload();
      } else {
        toast.error('error! please try again');
      }
    }
  };

  return (
    <div className="summary">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Toaster position="top-center" />

          <h3>NoshPass Daily Orders</h3>

          {summaryInfo ? (
            <div>
              <div
                style={{
                  color: '#F06A20',
                  fontSize: 13,
                  marginBottom: 10,
                  position: 'relative',
                }}
              >
                Live orders, page auto refresh every {REFRESH_SECS} secs.
                <Spin style={{ position: 'absolute', left: '1%', top: '0' }} />
                <Spin style={{ position: 'absolute', right: '1%', top: '0' }} />
              </div>
              {!summaryInfo.isConfirm && (
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    backgroundColor: '#F06A20',
                    borderRadius: 4,
                    marginBottom: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#ffffff',
                  }}
                  onClick={handleConfrim}
                >
                  Confirm Order - No Need to Call
                </div>
              )}
              <JsonToTable json={summaryInfo} />
              <br />
            </div>
          ) : (
            <div>
              <br />
              <br />
              Only order summary for today can be obtained. Please check if you
              have entered the correct link for today.
            </div>
          )}
          {summaryInfo && <br />}
          {/* {leOrders && <JsonToTable json={leOrders} />} */}
          {!!orders?.length && (
            <>
              {orders.map((v, i) => (
                <Collapse key={i} bordered={true} expandIconPosition="right">
                  <Panel
                    header={
                      <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
                        Total Orders: {v.length}
                      </div>
                    }
                    key={i}
                  >
                    <JsonToTable json={v} />
                  </Panel>
                </Collapse>
              ))}
            </>
          )}
          {leOrders && <br />}
          {!!summaryInfo && (
            <div style={{ color: 'blue', textAlign: 'left' }}>
              To ensure the best customer experience, each of the above order
              (each row) must be individually packed, labeled and include
              utensils and condiments.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
