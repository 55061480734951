import React, { FC, useEffect } from 'react';
import { Provider } from 'use-react-modal';
import { Footer } from '../Components/Footer/Footer';
import { Navbar } from '../Components/NavBar/Navbar';
import { HowWorks } from './HomeComponents';
import { LandingSection } from './LandingSection';
import RestaurantsMap from './Map';
import { Questions } from './Questions';

export const Home: FC = () => {
  useEffect(() => {
    window.location.href = 'https://noshsimple.com';
  }, []);

  return (
    <div></div>
    // <div className="main-container">
    //   <Provider background="rgba(0, 0, 0, 0.5)">
    //     <Navbar menu />
    //     <LandingSection />
    //     <HowWorks />
    //     <RestaurantsMap />
    //     {/* <LunchPlans /> */}
    //     {/* <LunchPlansMobile /> */}
    //     <Questions />
    //     <Footer />
    //   </Provider>
    // </div>
  );
};
