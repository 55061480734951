import { Button, Input } from 'antd';
import Axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { JsonToTable } from 'react-json-to-table';
import { BASE_URL } from '../../utils/Constants';
import styles from '../driver.module.scss';
import AppButton from './appButton';

const FoodSwap = (props: {
  driverRoutes: any;
  isRedundant?: boolean;
  orderType?: string;
}): JSX.Element => {
  const url = BASE_URL;
  const [driverRoutes, setDriverRoutes] = useState(props.driverRoutes);
  const [shiftLoading, setShiftLoading] = useState(false);
  const [phone, setPhone] = useState('');

  const convertKeys = (data) => {
    return _.mapKeys(data, (v, key) => _.startCase(_.lowerCase(key)));
  };

  const editShift = async (hashKey: string, type: string) => {
    if (window.confirm(`Are you sure to confirm it?`)) {
      setShiftLoading(true);
      const { data } = await Axios.post(`${url}/driver/routes/shift/edit`, {
        hashKey,
        type,
      });
      setShiftLoading(false);
      if (!data) {
        toast.error(`Something wrong, Please try again`);
        return;
      }
      const item = driverRoutes.find((v) => v.hashKey === hashKey);
      item[type] = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
      setDriverRoutes([...driverRoutes]);
    }
  };

  const editUhual = async (d: any, type: string) => {
    if (!d.driverPhone) {
      alert('Please Enter Driver Phone!');
      return;
    }

    if (!!d.endTime || !!d[`${type}`]) return;

    if (window.confirm(`Are you sure to confirm it?`)) {
      const loading =
        type === 'u2ArrivedTime' ? 'u2ArrivedLoading' : 'u2LeftLoading';
      d[loading] = true;
      setDriverRoutes([...driverRoutes]);
      const { data } = await Axios.post(`${url}/driver/routes/shift/edit`, {
        hashKey: d.hashKey,
        type,
      });
      if (!data) {
        d[loading] = false;
        setDriverRoutes([...driverRoutes]);
        toast.error(`Something wrong, Please try again`);
        return;
      }

      d[`${type}`] = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
      d[loading] = false;
      setDriverRoutes([...driverRoutes]);
    }
  };

  const uhualText = (d: any, type: string) => {
    // `u2${type}Time`: u2ArrivedTime or u2LeftTime
    if (!!d.endTime)
      return d[`u2${type}Time`] ? `${type.toUpperCase()}` : type.toUpperCase();

    return d[`u2${type}Time`] ? `${type.toUpperCase()}` : type.toUpperCase();
  };

  const uhualClass = (d: any, type: string) => {
    if (!d.endTime && !!d[`u2${type}Time`]) {
      let outTime = moment.tz(d.date, 'US/Central').set({
        hour: 11,
        minute: type === 'Arrived' ? 30 : 35,
      });
      if (props.orderType === 'DINNER') {
        outTime = moment.tz(d.date, 'US/Central').set({
          hour: 18,
          minute: type === 'Arrived' ? 30 : 35,
        });
      }
      const isTimeOut = moment
        .tz(d[`u2${type}Time`], 'US/Central')
        .isAfter(outTime);

      return `${styles.uhualBtn} ${isTimeOut ? styles.timeOut : styles.onTime}`;
    }

    if (!!d.endTime) {
      return styles.inactiveBtn;
    }

    const style = {
      Arrived: styles.uhualBtn,
      Left: styles.uhualLeftBtn,
    }[type];

    return style;
  };

  const saveDriverPhone = async (hashKey: string) => {
    // eslint-disable-next-line no-useless-escape
    const reg = /^(1?|(1\-)?)\d{10,12}$/;
    if (!reg.test(phone)) {
      alert('wrong phone');
      return;
    }
    await Axios.post(`${url}/driver/routes/shift/edit-phone`, {
      hashKey,
      phone,
    });
    const item = driverRoutes.find((v) => v.hashKey === hashKey);
    item.driverPhone = phone;
    setDriverRoutes([...driverRoutes]);
  };

  return (
    <>
      <Toaster position="top-center" />
      {props.driverRoutes.map((d, i) => {
        d.storageCenter.meals.forEach((v) => {
          v.dishName =
            v.dishName.length > 30
              ? `${v.dishName.substring(0, 35)}...`
              : v.dishName;
        });

        return (
          <div key={i}>
            {props.isRedundant ? (
              <h3 className={styles.h3}>
                Redundant Driver Shift Details [
                {moment.tz(d.date, 'US/Central').format('MMM DD')}]
              </h3>
            ) : (
              <h3 className={styles.h3}>
                Driver Shift Details [DR#{d.driverId}] [
                {moment.tz(d.date, 'US/Central').format('MMM DD')}]
              </h3>
            )}

            {/* start/end shift */}
            {!d.startTime && !props.isRedundant && (
              <AppButton
                text="START SHIFT"
                className={styles.startShift}
                loading={shiftLoading}
                onClick={() => editShift(d.hashKey, 'startTime')}
              />
            )}
            {!!d.startTime && !d.endTime && (
              <AppButton
                text="END SHIFT"
                className={styles.endShift}
                loading={shiftLoading}
                onClick={() => {
                  if (d.orderInLocations.length) {
                    const cp =
                      d.orderInLocations[d.orderInLocations.length - 1];
                    const today = moment.tz('US/Central').format('YYYY-MM-DD');
                    const deadLine = moment.tz(
                      `${today} ${cp.pickupEnd}`,
                      'US/Central'
                    );
                    if (moment.tz('US/Central').isAfter(deadLine)) {
                      editShift(d.hashKey, 'endTime');
                    } else {
                      alert('something wrong, please try again');
                    }
                  } else {
                    editShift(d.hashKey, 'endTime');
                  }
                }}
              />
            )}

            <div className={styles.section}>
              <div className={styles.sectionTitle}>Driver Phone</div>
              {!!d.startTime &&
                (!!d.driverPhone ? (
                  <div style={{ textAlign: 'left' }}>{d.driverPhone}</div>
                ) : (
                  <Input.Group compact>
                    <Input
                      style={{ width: 'calc(100% - 80px)' }}
                      defaultValue=""
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <Button
                      onClick={() => saveDriverPhone(d.hashKey)}
                      type="primary"
                      style={{
                        width: 80,
                        backgroundColor: '#f06a20',
                        borderColor: '#f06a20',
                      }}
                    >
                      Submit
                    </Button>
                  </Input.Group>
                ))}
            </div>

            {/* uhual */}
            <div className={styles.section}>
              <div className={styles.sectionTitle}>UHUAL</div>
              {!!d.startTime && (
                <div className={styles.nsBtnList}>
                  <AppButton
                    text={uhualText(d, 'Arrived')}
                    className={uhualClass(d, 'Arrived')}
                    loading={d.u2ArrivedLoading}
                    onClick={() => editUhual(d, 'u2ArrivedTime')}
                  />
                  <AppButton
                    text={uhualText(d, 'Left')}
                    className={uhualClass(d, 'Left')}
                    loading={d.u2LeftLoading}
                    onClick={() => editUhual(d, 'u2LeftTime')}
                  />
                </div>
              )}
            </div>

            {!!d.storageCenter && (
              <div className={styles.section}>
                <div className={styles.sectionTitle}>
                  Orders to Gather from Storage Center
                </div>
                <JsonToTable json={convertKeys(d.storageCenter)} />
              </div>
            )}
            <hr />
          </div>
        );
      })}
    </>
  );
};

export default FoodSwap;
