import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styles from './noshsimple-driver.module.scss';
import Loading from '../Components/Loading';
import { SIMPLE_URL } from '../utils/Constants';
import Foods from './components/foods';
import Address from './components/address';
import Restaurant from './components/restaurant';
import Stamp from './components/stamp';

const NoshSimpleDriverManager = (): JSX.Element => {
  const { hashKey } = useParams<any>();

  const tabs = ['Restaurant', 'Foods', 'NoshStop', 'Monitor'];
  const [loading, setLoading] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [driverRoutes, setDriverRoutes] = useState<any>([]);
  const [active, setActive] = useState(1);
  const [invalidLink, setInvalidLink] = useState(false);

  useEffect(() => {
    getDriverRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDriverRoutes = async () => {
    setLoading(true);
    const { data } = await Axios.get(
      `${SIMPLE_URL}/driver/route-by-key?hashKey=${hashKey}`
    );
    setLoading(false);
    if (!data) {
      setInvalidLink(true);
      return;
    }

    let total = 0;
    data.driverRoutes.forEach((v) => {
      total += v.totalOrders;
    });

    setTotalOrders(total);
    setDriverRoutes(data.driverRoutes);
  };

  return invalidLink ? (
    <div style={{ margin: '50px 20px' }}>
      Link invalid, make sure you are using link for today.
    </div>
  ) : (
    <div className={styles.shiftBody}>
      {loading && <Loading />}
      {!loading && (
        <div className={styles.container}>
          <div style={{ display: active === 1 ? 'block' : 'none' }}>
            <div className="manager-view">
              <h3> Total Orders: {totalOrders}</h3>
            </div>
            <hr />
            {driverRoutes.map((v, i) => {
              return <Restaurant key={i} driverRoutes={v} />;
            })}
          </div>
          <div style={{ display: active === 2 ? 'block' : 'none' }}>
            {driverRoutes.map((v, i) => {
              return <Foods key={i} driverRoutes={v} />;
            })}
          </div>
          <div style={{ display: active === 3 ? 'block' : 'none' }}>
            {driverRoutes.map((v, i) => {
              return <Address key={i} driverRoutes={v} />;
            })}
          </div>
          <div style={{ display: active === 4 ? 'block' : 'none' }}>
            <Stamp driverRoutes={driverRoutes} />;
          </div>
        </div>
      )}
      <div className={styles.tabbar}>
        {tabs.map((v, i) => (
          <div
            key={i}
            className={`${styles.tabitem} ${
              active === i + 1 ? styles.active : ''
            }`}
            onClick={() => setActive(i + 1)}
          >
            {v}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoshSimpleDriverManager;
