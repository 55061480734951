import React, { useEffect, useState } from 'react';
import { JsonToTable } from 'react-json-to-table';
import Axios from 'axios';
import { useParams } from 'react-router';
import Loading from '../Components/Loading';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment-timezone';

const OrderSummaryReminder = (): JSX.Element => {
  const [menu, setMenu] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isInvalidKey, setIsInvalidKey] = useState(false);
  const [step, setStep] = useState('1');
  const [isConfirm, setIsConfirm] = useState(false);

  const { hashKey } = useParams<any>();

  const url = 'https://backend.noshpass.com/api';
  // const url = 'http://localhost:8000/api';

  useEffect(() => {
    querySummary();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const querySummary = async () => {
    setLoading(true);
    const t = hashKey.charAt(hashKey.length - 1);
    const actualKey = hashKey.substr(0, hashKey.length - 1);
    if (!['1', '2'].includes(t)) {
      setIsInvalidKey(true);
      setLoading(false);
      return;
    }
    setStep(t);
    const res = (
      await Axios.get(
        `${url}/orders/restaurant/summary-reminder-menu/${actualKey}`
      )
    ).data;
    console.log(res);
    if (res === 'Invalid params') {
      setIsInvalidKey(true);
      setLoading(false);
      return;
    }
    const dayOfWeek = moment.tz('US/Central').isoWeekday();
    const resMenu = res.menu.map((v) => {
      const dayDiff =
        dayOfWeek >= 5 ? 7 - dayOfWeek + v.dayOfWeek : v.dayOfWeek - dayOfWeek;
      return {
        meal: v.Meal.title,
        date: moment.tz('US/Central').add(dayDiff, 'day').format('YYYY-MM-DD'),
        day: moment.tz('US/Central').add(dayDiff, 'day').format('dddd'),
      };
    });
    setMenu(resMenu);
    setIsConfirm(res.status);
    setLoading(false);
  };

  const handleConfrim = async () => {
    if (window.confirm(`Please make sure you are ready !`)) {
      const actualKey = hashKey.substr(0, hashKey.length - 1);
      const { data } = await Axios.post(
        `${url}/orders/restaurant/summary-reminder-confirm/${actualKey}`
      );
      if (data === 'Updated') {
        window.location.reload();
      } else {
        toast.error('error! please try again');
      }
    }
  };

  return (
    <div className="summary">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Toaster position="top-center" />

          <h3 style={{ marginTop: 30 }}>NoshPass</h3>

          {isInvalidKey ? (
            <div>
              <br />
              <br />
              Link invalid, make sure your link is correct.
            </div>
          ) : (
            <div style={{ textAlign: 'left' }}>
              {step === '1' && (
                <div>
                  <br />
                  <br />
                  Friendly reminder that the following dishes will be featured
                  on this coming week's NoshPass express lunch menu
                  <br />
                  <br />
                  <JsonToTable json={menu} />
                  <br />
                  To ensure the best experience for our (and your) customers,
                  please confirm you'll be able to fulfill the above dishes. If
                  we don't hear from you within the next 48 hours, we will
                  remove the item from the menu items for next week. Note that
                  any orders not timely fulfilled by 10:55am could incur
                  customer refunds and penalty borne by you. As such, be sure to
                  notify all staffs on duty on your feature day. <br />
                  Sincerely, NoshPass Team
                  <br />
                  <br />
                  {!isConfirm ? (
                    <div
                      style={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#F06A20',
                        borderRadius: 4,
                        marginBottom: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#ffffff',
                      }}
                      onClick={handleConfrim}
                    >
                      I Hereby Confirm
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#CCCCCC',
                        borderRadius: 4,
                        marginBottom: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#ffffff',
                      }}
                    >
                      Confirmed! Thanks.
                    </div>
                  )}
                </div>
              )}
              {step === '2' && (
                <div>
                  <br />
                  <br />
                  Haven't heard from you. The following dishes will be featured
                  on tomorrow's NoshPass express lunch menu
                  <br />
                  <br />
                  <JsonToTable json={menu} />
                  <br />
                  <br />
                  FINAL REMINDER: Please confirm immediately you'll be able to
                  fulfil these dish orders. If we don't hear from you within the
                  next 3 hours, we will remove the item from our menu for
                  tomorrow. Note that any orders not timely fulfilled by 10:55am
                  could incur customer refunds and penalty borne by you. As
                  such, be sure to notify all staffs on duty on your feature
                  day. Sincerely, NoshPass Team
                  <br />
                  <br />
                  {!isConfirm ? (
                    <div
                      style={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#F06A20',
                        borderRadius: 4,
                        marginBottom: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#ffffff',
                      }}
                      onClick={handleConfrim}
                    >
                      I Hereby Confirm
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#CCCCCC',
                        borderRadius: 4,
                        marginBottom: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#ffffff',
                      }}
                    >
                      Confirmed! Thanks.
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderSummaryReminder;
