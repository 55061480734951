import React from 'react';
import 'react-awesome-animated-number/dist/index.css';
import { getMobileOrPC } from '../utils/Constants';

const Policy = (): JSX.Element => {
  const isPC = getMobileOrPC();

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: isPC ? 800 : '100%',
          backgroundColor: '#ffffff',
          paddingTop: 20,
          marginBottom: 20,
        }}
      >
        <img
          style={{ width: 100, height: 100 }}
          alt="logo"
          src="https://mcusercontent.com/da469e35d31f7c8b4eafe3e71/images/8e41b67e-c3a2-97cb-6fe4-efee8483b806.png"
        />
        <div
          style={{
            boxSizing: 'border-box',
            paddingLeft: 20,
            paddingRight: 20,
            fontFamily: 'Helvetica',
            color: '#808080',
            lineHeight: 1.5,
            textAlign: 'justify',
          }}
        >
          <p>Terms of Use </p>
          <p>
            Welcome to NoshPass! These Terms of Use (“Terms”) are a contract
            between you and NoshPass, Inc. (“NoshPass” or “we”), the provider of
            NoshPass services. These Terms govern your access to and use of the
            NoshPass website located at https://NoshPass.com, and NoshPass’s
            mobile phone application (such as for iPhone or Android) (the “App”)
            and any content, and products and/or services made available to you
            through NoshPass and/or by NoshPass via e-mail or physical
            notification to you (collectively, the “E-Platform”).
          </p>
          <p>
            These Terms and any applicable terms and conditions related to any
            promotional offers provided to you for use with your NoshPass
            Service (as defined in clause 4.3 below), constitute the entire
            agreement between you and us related to your NoshPass Service (the
            “Service Agreement”).
          </p>
          <p>
            A NoshPass Service (as defined in clause 4.3 below) is a Service
            plan which allows you to reserve and pick-up lunch meals from any of
            our partnering restaurants at varying prices depending on your
            NoshPass Service (see clause 4.3).
          </p>
          <p>
            Please read these Terms carefully before accessing and using the
            E-Platform. Our relationship is subject to these Terms and they
            impose certain responsibilities upon you and they exclude and limit
            our liability to you in the event of loss or damage (see clauses 16
            and 18).
          </p>
          <p>INFORMATION ABOUT US AND HOW TO CONTACT US</p>
          <p>
            Who we are. We are NoshPass, Inc.. Our address is 808 Travis St, Ste
            400, Houston, TX. How to contact us. E-mail: hi@NoshPass.com. How we
            may contact you. If we have to contact you, we will do so by email.
          </p>
          <p>TERMS OF USE</p>
          <p>
            Acceptance of Terms. By accessing and/or using the E-Platform, you
            accept and agree to be bound by these Terms, as if you had agreed to
            these Terms in writing. If you do not agree to any of these Terms,
            do not use the E-Platform.
          </p>
          <p>
            Amendment of Terms. NoshPass reserves the right, at our sole
            discretion, to amend these Terms at any time without incurring any
            liability or obligation. Unless we provide a delayed effective date,
            all amendments will be effective upon posting of such updated Terms.
            Your continued access to or use of the E-Platform after such period
            constitutes your consent to be bound by the Terms, as amended.
          </p>
          <p>
            Additional Terms. In addition to these Terms, when using particular
            plans, offers, products, services or features, you will also be
            subject to any additional posted guidelines, or rules applicable to
            such plan, offer, product, service or feature, which may be posted
            and modified from time to time. All such additional terms are hereby
            incorporated by reference into these Terms, provided that in the
            event of any conflict between such additional terms and these Terms,
            these Terms shall prevail.
          </p>
          <p>YOUR CANCELLATION RIGHTS AS A CONSUMER</p>
          <p>
            You have a legal right to cancel your Service to NoshPass at any
            time with or without cause. You can cancel your Service via the
            “Settings” menu in the App. If you have already purchased a NoshPass
            Service (as defined in Clause 4.3 below) and you exercise your right
            to cancel pursuant to clause 3.1, such cancellation will take effect
            at the end of your current billing cycle which will be 30 days from
            your last billing date or the day when you have used up your meal
            quota, whichever comes first. For the avoidance of doubt, to the
            extent that you have unused meals in your NoshPass Service after
            your election to cancel your NoshPass Service, you are still
            entitled to use any unused meals before the end of your current
            billing cycle.
          </p>
          <p>E-PLATFORM</p>
          <p>
            E-Platform. The E-Platform is a platform that enables consumers to
            reserve and pick up meals (details set out at clause 4.3) from the
            restaurants and/or food service providers that sign up with NoshPass
            (“Merchants”) according to their chosen plan (the “Services”).
            Through the E-Platform you can access third party products and
            services. NoshPass is not a restaurant, chef or food service
            provider and does not own, operate or control any of the
            restaurants, chefs, or food services facilities accessible through
            the E-Platform.
          </p>
          <p>
            Eligible Meals. You are entitled to reserve no more than one (1)
            meal per weekday (excluding Public Holidays, as defined below at
            clause 5.8) from one (1) participating Merchant (“Eligible Meal”),
            and to pick up such meal at the location of the Merchant from 12
            noon to 3:00pm. NoshPass reserves the right in accordance with
            clause 2.2 to change from time to time the number of Eligible Meals
            a member can reserve per cycle or based on the Service plan,
            geographic location, Merchant or otherwise.
          </p>
          <p>
            Merchant and Meal Availability and Allocation. NoshPass makes no
            guarantee on the availability of particular Merchants, meals, menu
            choices or other inventory, and availability may change over time,
            including during the course of any given Service cycle.
          </p>
          <p>
            Meal Modifications. NoshPass Merchants are unable to make any
            modifications to the meals that they are offering on the platform.
            All meals will be prepared as described. Modifications,
            substitutions and any other changes or requests for changes are
            strictly forbidden and may result in a termination of your NoshPass
            Service.
          </p>
          <p>
            Use of NoshPass. Your NoshPass Service is personal to you and you
            agree not to create more than one account. You cannot transfer or
            gift meals to third parties, including other NoshPass users. To use
            your NoshPass Service you must have access to the internet. We
            continually update and test various aspects of the E-Platform. We
            reserve the right to, and by using our service you agree that we
            may, include you in or exclude you from these tests without notice.
            In order to pick up your meal, you must have the Android App or iOS
            App installed on your mobile device, and you must have a working
            camera. To pick up your meal, you will be required to open the App,
            log in to your account, and scan the QR code found at the
            restaurant.
          </p>
          <p>
            Following the scan, you must then show the confirmation success
            screen to the Merchant. NoshPass reserves the right to modify this
            pickup process as required by the restaurant and/or NoshPass.
          </p>
          <p>
            In order to use certain features on the NoshPass E-Platform, you are
            required to have a mobile device. NoshPass does not warrant that the
            E-Platform will be compatible or interoperable with your mobile
            device. The E-Platform requires and uses data access and text
            messaging capabilities. Carrier rates for data and text messaging
            may apply, and you are responsible for all of these charges.
          </p>
          <p>BILLING</p>
          <p>
            Price Changes. We reserve the right to adjust pricing at any time in
            accordance with clause 2.2. Unless we expressly communicate
            otherwise, any price changes to your NoshPass Service will take
            effect on your next billing cycle upon notice communicated through a
            posting on the E-Platform or such other means as we may deem
            appropriate from time to time. Payment Methods. You may edit your
            Payment Method information by using the E-Platform and/or the App.
            If a payment is not successfully settled, due to expiration of
            credit card, insufficient funds or otherwise, and you do not contact
            us with your Payment Method information or cancel your account (see,
            “Termination” below at clause 7), you nonetheless will remain
            responsible for any uncollected amounts and authorise us to continue
            billing the Payment Method, as it may be updated, including in the
            event you attempt to create a new account. This may result in a
            change to your payment billing dates. If we cannot charge your
            account, we reserve the right to terminate your access to our
            E-Platform or any portion thereof. Reservation and Cancellation of
            Meals. You must reserve and cancel meals from your NoshPass Service
            only through the E-Platform. It is a breach of the Service Agreement
            if you cancel, change, or request a meal directly with a Merchant.
            Meals can be reserved starting at 5:00pm local time on the evening
            before the meal will be received. All meals must be reserved by
            1:30pm local time on the day when the meal will be collected. If a
            meal is not reserved by 1:30pm local time on the day the meal would
            have been collected, the customer will not be eligible to receive a
            meal on that day. There are no refunds for meals not collected or
            not ordered in time. If you cancel a meal before 11am local time on
            the day the meal would have been collected, you will have the
            opportunity to reserve another meal before 1:30pm on the same day.
            If you cancel a meal after 11am local time on the day the meal would
            have been collected, that meal will count toward your total meals
            for your current billing cycle.
          </p>
          <p>
            Gifts and Promotions. From time to time we may make available gift
            cards for users of the E-Platform, other types of promotions or
            promotional plans (including through the use of promotional codes or
            those provided as part of a third party promotion). Gift cards,
            promotions and promotional plans may only be redeemed as described,
            and may be subject to additional or different terms. Unless
            otherwise expressly communicated to you in connection with your
            redemption, gift cards, promotions and promotional plans are (i)
            only available to new users that have never had an account on the
            E-Platform before, and (ii) may only be redeemed once, upon initial
            registration of an account on the E-Platform. NoshPass has the right
            to charge your Payment Method the full retail value of any gift
            card, promotion, or promotional plan that is redeemed by you more
            than once, or otherwise than as described in the specifics of the
            gift card, promotion, or promotional plan. Gift cards, promotions,
            and promotional plans are not transferable, cannot be redeemed for
            cash, and may not be combined with other offers. In order to receive
            a promotion, you may be required to provide and verify your mobile
            phone number. The mobile phone number you verify must be a unique
            mobile phone number that is not associated with another account in
            order to receive the promotion. We may also review any other aspects
            of your account to determine if it is a new user account or not, and
            the determination is at NoshPass’s discretion.
          </p>
          <p>
            Public Holidays. We observe all standard national holidays in the
            United States. On a Holiday, you will not be able to reserve meals
            on the NoshPass E-Platform. Occasionally, multiple holidays will be
            included in a given 30-day billing cycle. During these cycles it may
            be possible that the maximum number of meals you will be able to
            reserve on your plan will be less than the number you are entitled
            to. The total price for the plan during these cycles does not
            change. You will not receive a refund for any of your meals. You
            will not be able to pick up multiple meals on any day.
          </p>
          <p>OTHER FEES</p>
          <p>
            Fees We Charge. Your NoshPass Service fee covers your access to
            eligible meals as explained above. We reserve the right to change
            the policy in accordance with clause 2.2 regarding when we charge
            fees, to introduce additional fees (including but not limited to, a
            sign-up fee, late cancellation fee, or missed meal pick up fee) and
            to change the amount of any such fees at any time. Additionally,
            from time to time we may allow you to purchase additional meals,
            products or services through the E-Platform. If you choose to
            purchase any of these offerings, you will be responsible to pay the
            applicable fees in addition to your Service fee. Third Party Fees
            for Using NoshPass. You are also responsible for all third party
            charges and fees associated with connecting and using the
            E-Platform, including but not limited to fees such as internet
            service provider fees, telephone and computer equipment charges, and
            any other fees necessary to access the E-Platform.
          </p>
          <p>TERMINATION</p>
          <p>
            We may end your Service Agreement if you break it or if we have a
            legal right to do so. We may end your NoshPass Service at any time
            with or without cause by giving 7 days’ notice. If we end your
            Service without cause, we will refund you the unused prepaid part of
            your current billing cycle prorated to the number of days left in
            that billing cycle after we cancel your Service Agreement.
          </p>
          <p>
            Upon any termination, we may immediately deactivate your account and
            all related information and/or bar any further access to your
            account information and the E-Platform. You must compensate us if
            you break the Service Agreement. We reserve the right to retain any
            monies you have paid to us and/or seek to recover from you
            reasonable compensation for the net costs and/or loss of profits we
            have incurred as a direct or indirect result of you breaking the
            Service Agreement.
          </p>
          <p>
            Your right to end the Service Agreement. You may terminate your
            NoshPass Service at any time via the “Settings” menus in the App.
            Following any cancellation you will continue to have access to your
            NoshPass Service through the end of your current prepaid billing
            period. You can also terminate the Service Agreement for the
            following reasons: we have told you about an upcoming change to the
            Services or these Terms which you do not agree to (as per clause
            2.2);
          </p>
          <p>
            you have a legal right to end the Service Agreement because of
            something we have done wrong, in which case the Service Agreement
            will end immediately and we will refund you the unused prepaid part
            of your current billing cycle prorated to the number of days left in
            that billing cycle after such cancellation.
          </p>
          <p>
            Infringing or Fraudulent Activity. NoshPass does not permit
            copyright infringing activities and reserves the right to terminate
            access to the E-Platform and remove all content submitted by any
            persons who are found to be infringers. Any suspected fraudulent,
            abusive, or illegal activity that may be grounds for termination of
            your use of the E-Platform may be referred to appropriate law
            enforcement authorities. These remedies are in addition to any other
            remedies NoshPass may have at law or in equity.
          </p>
          <p>
            ELIGIBILITY; REGISTRATION INFORMATION AND PASSWORD; E-PLATFORM
            ACCESS
          </p>
          <p>
            Eligibility Criteria. The availability of all or part of our
            E-Platform may be limited based on demographic, geographic, or other
            criteria as we may establish from time to time. You understand and
            agree we may disallow you from subscribing to the E-Platform or may
            terminate your NoshPass Service at any time based on these criteria.
          </p>
          <p>
            These Terms are applicable only to users of the E-Platform located
            in the United States and separate terms apply to users in other
            countries and/or regions. The E-Platform is not available to any
            users suspended or removed from the E-Platform. By using the
            E-Platform, you represent that you are 18 years of age or older and
            a permanent resident of the United States who has not been
            previously suspended or removed from the E-Platform or any other
            NoshPass site. Those who choose to access the E-Platform do so at
            their own initiative and are responsible for compliance with all
            applicable laws and regulations.
          </p>
          <p>
            Account registration requires you to submit to NoshPass certain
            personal information, such as your name, email address, and mobile
            phone number. You are permitted to create only one account. You
            agree that the information you provide to NoshPass at registration
            and at all other times will be true, accurate, current, and
            complete. You also agree that you will ensure that this information
            is kept accurate and up-to-date at all times. When you register, you
            will be asked to create a password. You are solely responsible for
            maintaining the confidentiality of your account and password and for
            restricting access to your computer and/or mobile device, and you
            agree to accept responsibility for all activities that occur under
            your account.
          </p>
          <p>PRIVACY STATEMENT – PERSONAL INFORMATION COLLECTION STATEMENT</p>
          <p>
            We collect personal information and/or data from you when you
            register for and use the E-Platform. Although you are not obliged to
            provide such personal information and/or data as requested on the
            E-Platform, we may not be able to provide you with certain services
            on the E-Platform should you fail to do so.
          </p>
          <p>
            Purpose. We collect your personal information and/or data for the
            following purposes: (a) to register you as a new user of the
            E-Platform; (b) to process and deliver your order including the
            management of payments, fees and charges and to collect and recovery
            money owed to us; (c) to manage our relationship with you which
            includes notifying you about any changes to these Terms and asking
            you to leave a review or take a survey; (d) to administer and
            protect the business of NoshPass and the E-Platform; (e) to deliver
            relevant content and advertisements to you and measure or understand
            the effectiveness of the advertising we serve you; (f) to use data
            analytics to improve the E-Platform, our services, marketing,
            customer relationships and experiences; and (g) to make suggestions
            and recommendations to you about goods or services that may be of
            interest to you.
          </p>
          <p>
            Disclosure or Transfer of Personal Data. All personal information
            and/or data held by NoshPass remains confidential but we may
            disclose and/or transfer such personal information and/or data to
            third parties under the following circumstances: (a) where the
            information and/or data is disclosed and/or transferred to any
            agents or associates of NoshPass who have been duly authorized by
            NoshPass to use such information and/or data; (b) where NoshPass
            considers necessary to do so in order to comply with any applicable
            laws and regulations; (c) where the information and/or data is
            disclosed and/or transferred to any external service providers who
            have been duly authorized by NoshPass to use such information and/or
            data and who will facilitate the services on the E-Platform,
            including without limitation the Merchants; and (d) where NoshPass
            deems necessary in order to maintain and improve the services on the
            E-Platform. If NoshPass enters into a joint venture with, purchases
            or is sold to or merged with another business entity, your personal
            data may be disclosed or transferred to the target company, our new
            business partners or owners or their advisors.
          </p>
          <p>
            NoshPass to delete your personal information and data kept with
            NoshPass by contacting at . In the event that you wish to access,
            amend or delete your personal information and data, we may request
            you to provide personal details in order to verify and confirm your
            identity. HKID card number or passport number or business
            registration certificate number cannot be amended unless such data
            is proved to be inaccurate. We are required to respond to such
            requests within 40 days of receiving it and we reserve the right to
            charge a nominal fee for the processing of this request in
            accordance with local laws and regulations. If you would like
            NoshPass to delete all personal information together with the
            deletion of your account, please follow the steps mentioned-above.
            Please note that the deletion of your personal information from our
            database will result in us not being able to provide you with any
            services. If applicable, any legal requirement on us to maintain
            certain records of your personal information shall prevail over any
            of your requests.
          </p>
          <p>
            Storage and Security. NoshPass will use all reasonable endeavours to
            maintain the security of your personal information and to protect
            your personal information from misuse, interference and loss and
            against unauthorised access, modification or disclosure. NoshPass
            will destroy any personal information it holds about you which it no
            longer requires under any applicables laws and regulations. Where
            you have chosen a password which allows to you access certain parts
            of the E-Platform, you are responsible for keeping such password
            confidential. Due to the nature of the internet, NoshPass does not
            provide any guarantee or warranty regarding the security of your
            personal information during transmission to us or storage by us and
            you acknowledge that you disclose your personal information to us at
            your own risk. Please contact us immediately if you become aware or
            have reason to believe there has been any unauthorised use of your
            personal information in connection with the E-Platform. Direct
            Marketing. Your personal data may be used in the marketing and
            promotion of goods and/or services of NoshPass and/or any external
            service providers who have been duly authorized by NoshPass
            (including, without limitation, the Merchants) by way of post,
            telephone, e-mails and/or short message services (SMS)
            notifications. Such use of your personal data in such direct
            marketing to you requires your consent (which includes an indication
            of no objection), as such please note that:
          </p>
          <p>
            Your name, log-in ID, contact details, age, sex and date of birth,
            held by NoshPass from time to time may be used by NoshPass and/or
            its authorised personnel or staff in direct marketing and The
            following classes of services and products may be marketed: (i) food
            and beverage related products and services; (ii) reward, loyalty or
            privileges programs and related products and services; (iii) special
            offers including coupons, discounts and promotional campaigns; and
            (vi) products and services offered by NoshPass and/or any Merchants.
          </p>
          <p>
            Opt-Out from Direct Marketing. Suitable measures are implemented to
            make available to you the options to “opt-out” of receiving such
            materials described in clause 9.5 above. If you do not (i) want us
            to share your personal information with other parties or (ii)
            receive any such direct marketing materials, you may unsubscribe by
            following the relevant steps which are presented in every
            communication you receive from us, or by contacting the Company,
            whose contact details are set out under clause 9.3 above]. Please
            note that the withdrawal of any authorisations for processing of
            your personal information by authorized third parties may result in
            us not being able to provide you with any services.
          </p>
          <p>
            Cookie Policy. We do not collect any personally identifiable
            information from you whilst you visit and browse the E-Platform,
            save and except where such information of you is expressly
            requested. When you access the E-Platform, we record your visits
            only and do not collect any personal information or data. The
            E-Platform’s server software will also record the domain name server
            address and track the pages you visit and store such information in
            “cookies”, and gather and store information like internet protocol
            (IP) addresses, browser type, referring/exit pages, operating
            system, date/time stamp, and clickstream data in log files. All
            these are done without you being aware that they are occurring.
          </p>
          <p>PROHIBITED CONDUCT</p>
          <p>You will not:</p>
          <p>
            Cancel any meals from your NoshPass Service directly with a
            Merchant; Harass, threaten, or defraud users, members or staff of
            NoshPass or Merchants; Make unsolicited offers, advertisements,
            proposals, or send junk mail or “spam” to users; Impersonate another
            person or access another user’s account without that person’s
            permission; Share your passwords of your E-Platform account with any
            third party or encourage any other user to do so;
          </p>
          <p>
            Permit third parties to use any meals reserved under your own
            membership, including other members;
          </p>
          <p>
            Misrepresent the source, identity, or content of information
            transmitted via the E-Platform, including deleting the copyright or
            other proprietary rights; Upload material (e.g. virus) that is
            damaging to computer systems or data of NoshPass or users of the
            E-Platform;
          </p>
          <p>
            Upload copyrighted material that is not your own or that you do not
            have the legal right to distribute, display, and otherwise make
            available to others; or Upload or send to users of the E-Platform
            pornographic, threatening, embarrassing, hateful, racially or
            ethnically insulting, libellous, or otherwise inappropriate content.
          </p>
          <p>PROHIBITED USES</p>
          <p>
            As a condition of your use of the E-Platform, you will not use the
            E-Platform for any purpose that is unlawful or prohibited by these
            Terms. You may not use the E-Platform in any manner that, in our
            sole discretion, could damage, disable, overburden, or impair it or
            interfere with any other party’s use and enjoyment of the
            E-Platform. You may not attempt to gain unauthorised access to the
            E-Platform, or any part of the E-Platform, other accounts, computer
            systems or networks connected to the E-Platform, or any part of
            them, through hacking, password mining, or any other means or
            interfere or attempt to interfere with the proper working of the
            E-Platform or any activities conducted on the E-Platform. You may
            not remove, circumvent, disable, damage or otherwise interfere with
            security-related features of the E-Platform, any features that
            prevent or restrict use or copying of any content accessible through
            the E-Platform, or any features that enforce limitations on the use
            of the E-Platform or the content therein. You may not obtain or
            attempt to obtain any materials or information through any means not
            intentionally made available through the E-Platform. You agree
            neither to modify the E-Platform in any manner or form, nor to use
            modified versions of the E-Platform, including (without limitation)
            for the purpose of obtaining unauthorised access to the E-Platform.
            The E-Platform may contain robot exclusion headers. You agree that
            you will not use any robot, spider, scraper, or other automated
            means to access the E-Platform for any purpose without our express
            written permission or bypass our robot exclusion headers or other
            measures we may use to prevent or restrict access to the E-Platform.
          </p>
          <p>
            NoshPass reserves the right to refuse service, terminate accounts,
            remove or edit content, or cancel orders in its sole discretion.
          </p>
          <p>USER SUBMISSIONS OF REVIEWS</p>
          <p>
            General. The E-Platform provides certain features which enable you
            and other users to submit, post, and share reviews. These
            submissions may include without limitation text, graphic and
            pictorial works, or any other content submitted by you and other
            users through the E-Platform (“User Submissions”). User Submissions
            are displayed for informational purposes only and are not controlled
            by NoshPass. NoshPass cannot guarantee any anonymity or
            confidentiality with respect to any User Submissions, and strongly
            recommends that you think carefully about what you upload to the
            E-Platform. You understand that all User Submissions are the sole
            responsibility of the person from whom such User Submission
            originated. This means that you, and not NoshPass, are entirely
            responsible for all User Submissions that you upload, post, email,
            transmit, or otherwise make available through the E-Platform.
          </p>
          <p>
            Right to Remove or Edit User Submissions. NoshPass makes no
            representations that it will publish or make available on the
            E-Platform any User Submissions, and reserves the right, in its sole
            discretion, to refuse to allow any User Submissions on the
            E-Platform, or to edit or remove any User Submission at any time
            with or without notice.
          </p>
          <p>
            Licence Grant by You to NoshPass. You retain all your ownership
            rights in original aspects of your User Submissions. By submitting
            User Submissions to NoshPass, you hereby grant NoshPass and its
            affiliates, sublicensees, partners, designees, and assignees of the
            E-Platform (collectively, the “NoshPass Licensees”) a worldwide,
            nonexclusive, fully paid-up, royalty-free, perpetual, irrevocable,
            sublicensable, and transferable license to use, reproduce (including
            by making mechanical reproductions), distribute, modify, adapt,
            translate, prepare derivative works of, publicly display, publish,
            publicly perform, and otherwise exploit your User Submissions and
            derivatives thereof in connection with the E-Platform and NoshPass’s
            (and its successors’) business, including, without limitation, for
            marketing, promoting, and redistributing part or all of the Site
            (and derivative works thereof), in any media formats and through any
            media channels now known or hereafter discovered or developed.
          </p>
          <p>
            User Submissions Representations and Warranties. You are solely
            responsible for your own User Submissions and the consequences of
            posting or publishing them. In connection with User Submissions, you
            affirm, represent, and warrant that: (i) you own, or have the
            necessary licenses, rights, consents, and permissions to use and
            authorise NoshPass to use all patent, trademark, copyright, or other
            proprietary rights in and to your User Submissions to enable
            inclusion and use of your User Submissions in the manner
            contemplated by NoshPass and these Terms, and to grant the rights
            and license set forth above, and (ii) your User Submissions,
            NoshPass’s or any NoshPass Licensee’s use of such User Submissions
            pursuant to these Terms, and NoshPass’s or any of NoshPass
            Licensee’s exercise of the license rights set forth above, do not
            and will not: (a) infringe, violate, or misappropriate any
            third-party right, including any copyright, trademark, patent, trade
            secret, moral right, privacy right, right of publicity, or any other
            intellectual property or proprietary right; (b) cause injury to any
            other person; (c) violate these Terms or any applicable law or
            regulation; or (d) require obtaining a license from or paying fees
            or royalties to any third party for the exercise of any rights
            granted in these Terms, including, by way of example and not
            limitation, the payment of any royalties to any copyright owners,
            including any royalties to any agency, collection society, or other
            entity that administers such rights on behalf of others. NoshPass
            may, but is not obligated to, monitor and edit or remove any
            activity or content, including but not limited to content that
            NoshPass determines in its sole discretion to be in violation of the
            standards of the E-Platform. NoshPass takes no responsibility and
            assumes no liability for any User Submissions.
          </p>
          <p>
            Inaccurate or Offensive User Submissions. You understand that when
            using the E-Platform, you may be exposed to User Submissions from a
            variety of sources and that NoshPass does not endorse and is not
            responsible for the accuracy, usefulness, safety, or intellectual
            property rights of or relating to such User Submissions. You further
            understand and acknowledge that you may be exposed to User
            Submissions that are inaccurate, offensive, indecent, or
            objectionable. You agree to waive, and hereby do waive, any legal or
            equitable rights or remedies you have or may have against NoshPass
            in relation to the user submissions.
          </p>
          <p>
            Feedback. If you provide NoshPass with any comments, bug reports,
            feedback, or modifications proposed or suggested by you to the
            E-Platform (“Feedback”), NoshPass shall have the right, but is not
            obligated, to use such Feedback at its discretion, including, but
            not limited to the incorporation of such suggested changes into the
            E-Platform. You hereby grant NoshPass a perpetual, irrevocable,
            nonexclusive license under all rights necessary to incorporate and
            use your Feedback for any purpose.
          </p>
          <p>OWNERSHIP; PROPRIETARY RIGHTS</p>
          <p>
            The E-Platform is owned and operated by NoshPass. The visual
            interfaces, graphics, design, compilation, information, computer
            code, products, software (including any downloadable software),
            services, and all other elements of the E-Platform provided by
            NoshPass (“Materials”) may be protected by copyright, trade dress,
            and trademark laws, international conventions, and all other
            relevant intellectual property and proprietary rights, and
            applicable laws. Except for any content uploaded by you, all
            Materials contained on the E-Platform are the copyrighted property
            of NoshPass or its subsidiaries or affiliated companies and/or
            third-party licensors. All trademarks, service marks, and trade
            names are proprietary to NoshPass or its affiliates and/or
            third-party licensors. Except as expressly authorised by NoshPass,
            you agree not to sell, license, distribute, copy, modify, publicly
            perform or display, transmit, publish, edit, adapt, create
            derivative works from, or otherwise make unauthorised use of the
            Materials.
          </p>
          <p>THIRD-PARTY SITES, PRODUCTS AND SERVICES; LINKS</p>
          <p>
            The E-Platform may include links or access to other web sites or
            services (“Linked Sites”) solely as a convenience to users. NoshPass
            does not endorse any such Linked Sites or the information, material,
            products, or services contained on other linked sites or accessible
            through other Linked Sites. Furthermore, NoshPass makes no express
            or implied warranties with regard to the information, material,
            products, or services that are contained on or accessible through
            linked sites. Access and use of linked sites, including the
            information, material, products, and services on linked sites or
            available through linked sites, is solely at your own risk.
          </p>
          <p>
            Your correspondence or business dealings with, or participation in
            promotions of, advertisers found on or through the E-Platform are
            solely between you and such advertiser. You agree that NoshPass will
            not be responsible or liable for any loss or damage of any sort
            incurred as the result of any such dealings or as the result of the
            presence of such advertisers on the E-Platform.
          </p>
          <p>WARRANTIES AND DISCLAIMERS</p>
          <p>
            Meals, and other non-NoshPass products and services offered via the
            E-Platform are offered and provided by Merchants and other third
            parties, not NoshPass. Your collection and use of these meals and
            your use of these non-NoshPass products and services is solely at
            your own risk.
          </p>
          <p>
            NoshPass makes no guarantee of the ingredients contained in any
            meal. As stated in clause 4.10 above, NoshPass and/or Merchants are
            unable to make any modifications to the meals offered and it is
            therefore your responsibility to make sure that you can safely eat
            (or come into contact with) all of the ingredients contained in any
            meal. You have a legal right to receive goods which comply with
            their description and are of satisfactory quality. NoshPass shall
            neither be liable for actions or omissions of the any Merchant nor
            you in regard to the provision of any goods and/or services.
            Merchants are responsible for the preparation, condition and quality
            of any goods/or services. NoshPass makes no guarantee for the
            quantity, quality, condition or other representations of the goods
            and/or services provided by Merchants or the accuracy or
            completeness of the information (including menu information, photos
            and images of any goods) displayed on the E-Platform. For the
            avoidance of doubt, in no event shall NoshPass be liable for any
            act, error or omission by any third party, including, without
            limitation, any which arises out of or is any way connected with
            your visit to a Merchant, your use of or consumption of a meal,
            service, product or appointment made through the E-Platform, or the
            performance or non-performance of any third party provider in
            connection with any goods and/or services.
          </p>
          <p>NoshPass is not an agent of any third party provider.</p>
          <p>
            The E-Platform and any downloadable software, content, services, or
            applications made available in conjunction with or through the
            E-Platform are provided “as is” and “as available” without
            warranties of any kind either express or implied. to the fullest
            extent permissible pursuant to applicable law, NoshPass, on behalf
            of itself and its suppliers and partners, disclaims and excludes all
            warranties, whether statutory, express or implied, including, but
            not limited to, implied warranties of merchantability, fitness for a
            particular purpose, and non-infringement of proprietary rights.
          </p>
          <p>
            Without limiting the foregoing, NoshPass does not warrant or make
            any representations (i) that the E-Platform and any downloadable
            software, content, services, or applications made available in
            conjunction with or through the E-Platform will be uninterrupted or
            error-free, that defects will be corrected, or that the E-Platform
            and any downloadable software, content, services, or applications
            made available in conjunction with or through the E-Platform or the
            server that makes them available are free of viruses or other
            harmful components, or (ii) regarding the use of the E-Platform and
            any downloadable software, content, services, or applications made
            available in conjunction with or through the E-Platform in terms of
            correctness, accuracy, reliability, or otherwise. Any material or
            data that you download or otherwise obtain through the E-Platform is
            at your own risk. You are solely responsible for any damages to your
            computer system or loss of data resulting from the download of such
            material or data.
          </p>
          <p>WAIVER AND RELEASE</p>
          <p>
            You understand that NoshPass is not a restaurant or food provider or
            other service provider and the meals you consume are sourced and
            prepared by the applicable Merchant and not by NoshPass. Although
            NoshPass endeavours to offer services that is of high quality,
            NoshPass is not responsible for the quality of any meals or service.
          </p>
          <p>
            Therefore, to the fullest extent permitted by law, and subject to
            clause 18.3, you release and hold harmless NoshPass, its parent,
            subsidiaries or affiliated entities, and each of their respective
            officers, directors, members, employees, consultants, contract
            employees, representatives and agents, and each of their respective
            successors and assigns, from any and all responsibility, claims,
            actions, suits, procedures, costs, expenses, damages and liabilities
            arising out of or in any way related to your participation in or use
            of your E-Platform Account or your NoshPass Service, including with
            respect to bodily injury, physical harm, illness, death or property
            damage.
          </p>
          <p>INDEMNITY</p>
          <p>
            You agree to indemnify and hold NoshPass, its affiliated companies,
            and its suppliers and partners harmless from any claims, losses,
            damages, liabilities, including legal fees, arising out of your
            misuse of the E-Platform, violation of these Terms, violation of the
            rights of any other person or entity, or any breach of the prior
            representations, warranties, and covenants.
          </p>
          <p>
            OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU - YOUR
            ATTENTION IS DRAWN PARTICULARLY TO THIS CLAUSE
          </p>
          <p>
            To the extent permitted by law, NoshPass (which shall include its
            employees, directors, agents, representatives, affiliates and parent
            company) exclude all liability (whether arising in contract, in
            negligence or otherwise) for loss or damage which you or any third
            party may incur in connection with our E-Platform, our services, and
            any website linked to our E-Platform and any content or material
            posted on it. NoshPass, its agents, representatives and service
            providers shall not be liable for any indirect, special, incidental,
            consequential, or exemplary damages arising from your use of the
            E-Platform or for any other claim related in any way to your use of
            the E-Platform. These exclusions for indirect, special,
            consequential, and exemplary damages include, without limitation,
            damages for lost profits, lost data, loss of goodwill, work
            stoppage, work stoppage, computer failure, or malfunction, or any
            other commercial damages or losses, even if NoshPass, its agents,
            representatives, and service providers have been advised of the
            possibility thereof and regardless of the legal or equitable theory
            upon which the claim is based. NoshPass’s liability to you is
            limited to the amounts, if any, paid by you to NoshPass under this
            Service Agreement in the three months immediately prior to the event
            first giving rise to the claim. The limitations in clauses 18.1 and
            18.2 will apply to the maximum extent permitted by applicable law,
            regardless of whether NoshPass has been advised of the possibility
            of such damages and regardless of whether any remedy fails of its
            essential purpose.
          </p>
          <p>
            Responsibility we do not exclude: We do not in any way exclude or
            limit our liability for: death or personal injury caused by our
            failure to use reasonable care and skill or that of our employees;
          </p>
          <p>fraud or fraudulent misrepresentation;</p>
          <p>
            any other liability for which it would be unlawful for us to attempt
            to limit or exclude our liability; or breach of your legal rights in
            relation to any meals you receive, as summarized in clause 15.2
            above.
          </p>
          <p>LICENSE</p>
          <p>
            Subject to your compliance with these Terms, NoshPass grants you a
            limited, non-exclusive, revocable, non-transferable license to
            download and install a copy of the App on a single mobile device
            that you own or control and to run such copy of the App solely for
            your own personal use. You shall not:
          </p>
          <p>
            license, sublicense, sell, resell, transfer, assign, distribute or
            otherwise commercially exploit or make available to any third party
            the Application in any way;
          </p>
          <p>modify or make derivative works based upon the App;</p>
          <p>
            create Internet "links" to the Service or "frame" or "mirror" any
            App on any other server or wireless or Internet-based device;
          </p>
          <p>
            reverse engineer or access the App in order to: (i) design or build
            a competitive product or service;
          </p>
          <p>
            design or build a product using similar ideas, features, functions
            or graphics of the App; copy any ideas, features, functions or
            graphics of the App;
          </p>
          <p>
            launch an automated program or script, including, but not limited
            to, web spiders, web crawlers, web robots, web ants, web indexers,
            bots, viruses or worms, or any program which may make multiple
            server requests per second, or unduly burdens or hinders the
            operation and/or performance of the App;
          </p>
          <p>misuse the App, including by hacking or "scraping";</p>
          <p>
            send spam or otherwise duplicative or unsolicited messages of any
            kind in violation of applicable laws;
          </p>
          <p>
            send or store infringing, obscene, threatening, libelous, or
            otherwise unlawful or tortious material, including material harmful
            to children or anything which would violate third party privacy
            rights;
          </p>
          <p>
            {' '}
            send or store material containing software viruses, worms, Trojan
            horses or other harmful computer code, files, scripts, agents or
            programs;
          </p>
          <p>
            interfere with or disrupt the integrity or performance of the
            Application; or attempt to gain unauthorized access to the
            Application or its related systems or networks.
          </p>
          <p>MISCELLANEOUS</p>
          <p>
            Choice of Law; Forum. These Terms shall be governed in all respects
            by the laws of the United States, without regard to conflict of law
            provisions. You agree that any claim or dispute you may have against
            NoshPass must be resolved by a court located in the United States.
            You agree to submit to the exclusive jurisdiction of the courts of
            the United States for the purpose of litigating all such claims or
            disputes.
          </p>
          <p>
            Assignment. We may assign our rights and obligations under these
            Terms. The Terms will inure to the benefit of our successors,
            assigns and licensees.
          </p>
          <p>
            Severability. If any provision of these Terms shall be unlawful,
            void, or for any reason unenforceable, then that provision will be
            deemed severable from these Terms and will not affect the validity
            and enforceability of any remaining provisions.
          </p>
          <p>
            {' '}
            Headings. The heading references are for convenience purposes only.
            They do not constitute a part of these Terms, and will not be deemed
            to limit or affect any of the provisions.
          </p>
          <p>
            Entire Agreement. Each party agrees that it shall have no remedies
            in respect of any statement, representation, assurance or warranty
            (whether made innocently or negligently) that is not set out or
            referred to in this Service Agreement. Each party agrees that it
            shall have no claim for innocent or negligent misrepresentation
            based on any statement in this Service Agreement. Waiver. No waiver
            of any of these Terms by NoshPass is binding unless authorised in
            writing by an executive officer of NoshPass. In the event that
            NoshPass waives a breach of any provision of these Terms, such
            waiver will not be construed as a continuing waiver of other
            breaches of the same nature or other provisions of these Terms and
            will in no manner affect the right of NoshPass to enforce the same
            at a later time.
          </p>
          <p>
            {' '}
            Third Party Rights. A person who is not a party to this Service
            Agreement shall not have any rights to enforce any of its terms
            under the Contracts (Rights of Third Parties) Ordinance (Cap. 623).
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policy;
