import React, { useEffect, useState } from 'react';
import styles from './entrance.module.css';
import 'react-awesome-animated-number/dist/index.css';
import moment from 'moment-timezone';
import { useLocation } from 'react-router';

const Entrance = (): JSX.Element => {
  const params = new URLSearchParams(useLocation().search);
  const usable = params.get('usable');

  const [countDown, setCountDown] = useState('--:--:--');

  useEffect(() => {
    const timer = setInterval(() => {
      const timeleft = moment()
        .add(moment().hours() >= 10 ? 1 : 0, 'day')
        .set({ hours: 10, minutes: 0, second: 0 })
        .subtract(10, 'second')
        .diff(moment(), 'second');

      const t = moment.duration(timeleft, 'second');

      const countdown =
        timeleft <= 0
          ? '--:--:--'
          : moment({ h: t.hours(), m: t.minutes(), s: t.seconds() }).format(
              'HH:mm:ss'
            );

      setCountDown(countdown);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.timeContainer}>
        <div className={styles.countDown}>{countDown}</div>
        <div className={styles.text}>
          {usable === 'disable' ? (
            <>You Already Won</>
          ) : (
            <>
              time left to win today’s{' '}
              <span style={{ textDecoration: 'underline' }}>free</span> lunch
            </>
          )}
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img
          alt=""
          className={styles.image}
          src={require('../../src/assets/images/marketing/free_lunch.png')}
        />
      </div>
      <div
        className={usable === 'disable' ? styles.buttonDisable : styles.button}
      >
        GO
      </div>
    </div>
  );
};

export default Entrance;
