import React, { useEffect, useState } from 'react';
import styles from './driver.module.scss';
import axios from 'axios';
import { BASE_URL } from '../utils/Constants';
import { Spin } from 'antd';

const DriverAmount = (): JSX.Element => {
  const url = BASE_URL;
  const [driverAmount, setDriverAmount] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDriverAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDriverAmount = async () => {
    const { data } = await axios.get(`${url}/driver/amount`);
    if (data) {
      setDriverAmount(JSON.parse(data.value));
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    const value = JSON.stringify(driverAmount);
    const { data } = await axios.post(`${url}/driver/amount-update`, {
      driverAmount: value,
    });
    setLoading(false);
    if (data === 'OK') {
      alert('Success');
    }
  };

  return (
    <div className={styles.shiftBody} style={{ overflow: 'auto' }}>
      {loading && <Spin size="large" className={styles.loading} />}
      <h3 style={{ marginTop: 30, marginBottom: 30 }}>Today's driver</h3>
      {driverAmount.map((v, i) => {
        return (
          <div key={i} style={{ marginBottom: 20 }}>
            <div className={styles.driverTitle}>Driver {i + 1}</div>
            <div className={styles.driverTxtRow}>
              <span style={{ marginRight: 10, width: 120 }}>Name: </span>
              <input
                className={styles.driverAmountTxt}
                value={v.driverName}
                onChange={(e) => {
                  v.driverName = e.target.value;
                  setDriverAmount([...driverAmount]);
                }}
              />
            </div>
            <div className={styles.driverTxtRow}>
              <span style={{ marginRight: 10, width: 120 }}>Contact: </span>
              <input
                maxLength={10}
                className={styles.driverAmountTxt}
                value={v.mobilePhone}
                onChange={(e) => {
                  v.mobilePhone = e.target.value;
                  setDriverAmount([...driverAmount]);
                }}
              />
            </div>
          </div>
        );
      })}

      <div
        style={{
          padding: '0 20px',
          width: '100%',
          marginTop: 40,
        }}
      >
        <div
          className={styles.driverAmountBtn}
          style={{
            background: '#ff4d4f',
            marginBottom: 10,
          }}
          onClick={() => {
            driverAmount.push({
              mobilePhone: '',
              driverName: '',
            });
            setDriverAmount([...driverAmount]);
          }}
        >
          Add
        </div>
        <div
          className={styles.driverAmountBtn}
          style={{
            background: '#67C23A',
          }}
          onClick={() => {
            driverAmount.pop();
            setDriverAmount([...driverAmount]);
          }}
        >
          Subtract
        </div>
        <div
          className={styles.driverAmountBtn}
          style={{
            background: '#1677ff',
            marginTop: 40,
          }}
          onClick={() => handleConfirm()}
        >
          Confirm
        </div>
      </div>
    </div>
  );
};

export default DriverAmount;
