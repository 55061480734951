import React, { useEffect, useRef, useState } from 'react';
import 'react-awesome-animated-number/dist/index.css';
import styles from './menu-tooling-v2.module.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Popover } from 'antd';
import axios from 'axios';
import { v4 } from 'uuid';
import RestaurantCard from './components/RestaurantCard';
import toast, { Toaster } from 'react-hot-toast';
import { Button, Select } from 'antd';
import Loading from '../Components/Loading';
import { ASIAN_TAGS, BASE_URL, WESTERN_TAGS } from '../utils/Constants';
import { useHistory } from 'react-router';
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

const { Option } = Select;

export type RestaurantType = {
  id: string;
  title: string;
  mealTypeId: string;
  dropId?: string;
};

const resoGroupType = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
];

const ScheduleMenu = (): JSX.Element => {
  const [menuLoading, setMenuLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [weeksMenu, setWeeksMenu] = useState<any[]>([{}, {}, {}, {}, {}]);
  const [addRotate, setAddRotate] = useState('');
  const [currentRotate, setCurrenRotate] = useState('');
  const [currentRotate2, setCurrenRotate2] = useState('');
  const [rotates, setRotates] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentMealType, setCurrentMealType] = useState('LUNCH');
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [baseRestaurants, setBaseRestaurants] = useState<any[]>([]);
  const [restaurantSchedules, setRestaurantSchedules] = useState<any[]>([]);
  const _restaurantSchedules = useRef<any>(null);
  const _restaurants = useRef<any>(null);
  const _meals = useRef<any>(null);
  const _schedules = useRef<any>(null);

  const url = BASE_URL;
  // const url = 'http://localhost:8000/api';

  const history = useHistory();

  useEffect(() => {
    (async () => {
      allRotates();
      setMenuLoading(true);
      await getMeals();
      await allSchedules();
      const rotate = await getCurrentRotate();
      await getRestaurants();
      await getRestaurantSchedules();
      await getMenuByRotates(rotate, 'LUNCH');
      setMenuLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRestaurants = async () => {
    const { data } = await axios.get(`${url}/restaurants/list-info`);
    const reso = data;
    reso.sort((a, b) => {
      return `${a.title}`.localeCompare(b.title);
    });
    _restaurants.current = reso;
    setRestaurants(reso);
    setBaseRestaurants(reso);
  };

  const getMeals = async () => {
    const { data } = await axios.get(`${url}/meals/list`);
    _meals.current = data;
  };

  const allSchedules = async () => {
    const { data } = await axios.get(
      `${url}/schedules-d8c8ca45-46a3-453d-a6e0-22dad9484f9d/dayoffs/list`
    );
    _schedules.current = data;
  };

  const getCurrentRotate = async () => {
    const { data } = await axios.get(`${url}/tools/rotate-week`);
    setCurrenRotate(data.rotateWeek);
    setCurrenRotate2(data.rotateWeek);
    return data.rotateWeek;
  };

  const allRotates = async () => {
    // const { data } = await axios.get(`${url}/tools/all-rotate`);
    setRotates([0, 1, 2, 3, 4, 5]);
  };

  const getRestaurantSchedules = async () => {
    const { data } = await axios.get(`${url}/schedules-restaurant/list`);
    setRestaurantSchedules(data || []);
    _restaurantSchedules.current = data || [];
  };

  const getMenuByRotates = async (rotate: string, mealType: string) => {
    const { data } = await axios.get(
      `${url}/schedules-menu/get?rotateWeek=${rotate}&scheduleType=${mealType}`
    );
    if (data) {
      const menu = JSON.parse(data.restaurantIds);
      menu.forEach((v, i) => {
        v.restaurants.forEach((l) => {
          const reso = _restaurantSchedules.current.find(
            (v) => v.restaurantId === l.id
          );
          if (reso) {
            const schedules = JSON.parse(reso.schedules);
            l.schedules = schedules;
          }

          const resoInfo = _restaurants.current.find((v) => v.id === l.id);
          if (resoInfo) {
            l.tags = resoInfo.tags;
            l.driving = resoInfo.driving;
            l.lunchRotate = resoInfo.lunchRotate;
            l.dinnerRotate = resoInfo.dinnerRotate;
            l.category = resoInfo.category;
          }
        });
      });
      sortMealType(menu);
      setWeeksMenu(menu);
    } else {
      setWeeksMenu([
        { rotate, dayOfWeek: 1, restaurants: [] },
        { rotate, dayOfWeek: 2, restaurants: [] },
        { rotate, dayOfWeek: 3, restaurants: [] },
        { rotate, dayOfWeek: 4, restaurants: [] },
        { rotate, dayOfWeek: 5, restaurants: [] },
      ]);
    }
  };

  const saveWeeksMenu = async () => {
    const weekMenu = JSON.parse(JSON.stringify(weeksMenu));
    weekMenu.forEach((v) => {
      v.restaurants.forEach((l) => {
        delete l.schedules;
        delete l.tags;
        delete l.mealIds;
      });
    });
    setSaveLoading(true);
    const { data } = await axios.post(`${url}/schedules-menu/save`, {
      rotateWeek: Number(currentRotate),
      scheduleType: currentMealType,
      weekMenu,
    });
    setSaveLoading(false);
    if (data.status === 'success') {
      toast.success(`Weeks menu saved!`);
    } else {
      toast.error(data.message);
    }
  };

  const SearchMealContainer = () => {
    return (
      <div className={styles.leftContainer}>
        <div className={styles.leftHead}>
          <div className={styles.resoName}>Menu</div>
          <input
            className={styles.input}
            onChange={(e) => {
              const reso = baseRestaurants.filter(
                (v) =>
                  !!v.title && v.title.toLowerCase().includes(e.target.value)
              );
              setRestaurants(reso);
            }}
          />
        </div>
        <div className={styles.leftMealList}>
          <SearchedMealList />
        </div>
      </div>
    );
  };

  const SearchedMealList = () => {
    return (
      <Droppable droppableId="searchMeals" isDropDisabled={true}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {restaurants.map((reso: RestaurantType, index) => (
              <Draggable key={reso.id} draggableId={`${reso.id}`} index={index}>
                {(provided, snapshot) => (
                  <React.Fragment>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                    >
                      <RestaurantCard
                        restaurant={reso}
                        showClose={false}
                        showSelect={false}
                      />
                    </div>
                    {snapshot.isDragging && (
                      <div className="react-beatiful-dnd-copy">
                        <RestaurantCard
                          restaurant={reso}
                          showClose={false}
                          showSelect={false}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    );
  };

  const MenuList = () => {
    return (
      <div className={styles.droppable}>
        {weeksMenu.map((menu, index) => {
          return (
            <React.Fragment key={index}>
              <div className={styles.dropList}>
                <div className={styles.listDate}>
                  <div className={styles.listDateRow}>
                    <div className={styles.promoWeek}>
                      <span style={{ fontWeight: 'normal' }}>promo week:</span>
                      {menu.rotate}
                      {!!menu.warns && (
                        <div>
                          <Popover
                            content={
                              <div style={{ width: 300 }}>
                                <p>
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    {menu.warns}
                                  </span>
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleOutlined
                              style={{
                                color: 'red',
                                marginLeft: 10,
                                marginBottom: 8,
                              }}
                            />
                          </Popover>
                        </div>
                      )}
                    </div>
                    <div>
                      <span style={{ fontWeight: 'normal' }}>day: </span>
                      {menu.dayOfWeek}
                    </div>
                  </div>
                </div>
                <Droppable droppableId={`drop-${index}`} key={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={styles.mealList}
                    >
                      <div>
                        {!!menu.restaurants &&
                          menu.restaurants.map((reso, inx) => (
                            <Draggable
                              key={reso.dropId}
                              draggableId={`${reso.dropId}`}
                              index={inx}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <RestaurantCard
                                    restaurant={reso}
                                    showClose
                                    showSelect
                                    onClose={() => removeMeal(index, inx)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const removeMeal = (index, rIndex) => {
    weeksMenu[index].restaurants.splice(rIndex, 1);
    sortMealType(weeksMenu);
    setWeeksMenu([...weeksMenu]);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    const [, dMenuInd] = destination.droppableId.split('-');
    const dTargetMenu = weeksMenu[dMenuInd].restaurants;
    const [, sMenuInd] =
      source.droppableId === 'searchMeals'
        ? [0, 0]
        : source.droppableId.split('-');
    const sTargetMenu = weeksMenu[sMenuInd].restaurants;

    // 同一列互换位置
    if (sInd === dInd) {
      const swapedList = swap(dTargetMenu, source.index, destination.index);
      dTargetMenu.restaurants = swapedList;
      return;
    }

    // 待放置的位置是否有相同的菜
    const mealId =
      source.droppableId === 'searchMeals'
        ? restaurants[source.index].id
        : sTargetMenu[source.index].id;
    const hasMeal = dTargetMenu.find((v) => v.id === mealId);
    if (hasMeal) {
      return;
    }

    // 从查询列表复制一份到目标列
    if (source.droppableId === 'searchMeals') {
      const reso = restaurants[source.index];
      const hasSchedules = restaurantSchedules.find(
        (v) => v.restaurantId === reso.id
      );
      if (!hasSchedules) {
        if (window.confirm(`No sorting type has been added`)) {
          // window.open('https://noshpass.com/menu-tooling-v2/restaurants');
          history.push('./restaurants');
        }
        return;
      }
      const schedules = JSON.parse(hasSchedules.schedules);
      dTargetMenu.splice(destination.index, 0, {
        ...reso,
        schedules,
        scheduleType: 'A',
        mealIds: schedules.find((v) => v.type === 'A').meals,
        dropId: v4(),
      });
      return;
    }

    // 不同列直接调换
    const [removed] = sTargetMenu.splice(source.index, 1);
    dTargetMenu.splice(destination.index, 0, removed);
  };

  const swap = (list: RestaurantType[], startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  const sortMealType = (menu = weeksMenu) => {
    menu.forEach((m, i) => {
      let warns = '';
      const protein: any[] = [];
      const tags: any[] = [];
      let farReso = false;
      let goodRangePrice = false;
      const firstRatings = m.restaurants.length
        ? m.restaurants[0].ratingPercent
        : 0;
      let ratingFlag = false;

      m.restaurants.forEach((reso, index) => {
        reso.mealTypeId = resoGroupType[index];

        const resos = reso.schedules.find((o) => o.type === reso.scheduleType);
        const tmeals: any[] = [];
        resos.meals.forEach((l) => {
          const item = _meals.current.find((o) => o.id === l);
          tmeals.push(item);
        });

        // 获得当天所有餐厅的蛋白质类型
        const p = tmeals.map((o) => o.protein).flat();
        protein.push(p);

        // 获得当天所有餐厅的标签
        const t = tmeals.map((o) => o.tags).flat();
        tags.push(t);

        // 餐厅离 uhual 的距离
        farReso = (!!reso.driving && reso.driving > 20) || farReso;

        // 每个餐厅的价格区间
        const [min, max] = reso.priceRange.split('-');
        goodRangePrice = max - min < 5 || goodRangePrice;

        if (reso.ratingPercent > firstRatings) {
          ratingFlag = true;
        }
      });

      // 尽量不要在连续几周的同一天展示相同的餐厅
      const sameDayResoIds = _schedules.current
        .filter(
          (o) =>
            o.rotateWeek !== m.rotate &&
            o.dayOfWeek === m.dayOfWeek &&
            o.scheduleType === currentMealType
        )
        .map((o) => o.RestaurantId);
      const hasSameDayReso = _.intersection(
        sameDayResoIds,
        m.restaurants.map((o) => o.id)
      );
      warns += `${
        hasSameDayReso.length > 0 ? '❌' : '✅'
      } Try not to feature the same restaurants on the same day on consecutive weeks\n`;

      warns += `${
        farReso ? '❌' : '✅'
      } Restaurant should not be too far away\n`;

      warns += `${
        goodRangePrice ? '❌' : '✅'
      } price range has a good variety\n`;

      // 评分高的要放在评分低的前面
      warns += `${
        ratingFlag ? '❌' : '✅'
      } feature dishes with higher reviews at the top of the menu\n`;

      // 每天必须至少摄入一种蛋白质：1 份白肉（鸡肉、火鸡）、1 份海鲜、1 份红肉（猪肉、牛肉）
      const flatProtein = protein.flat().map((o) => o.toLowerCase());
      warns += `${flatProtein.includes('pork') ? '✅' : '❌'} have 1 pork\n`;
      warns += `${
        flatProtein.includes('chicken') || flatProtein.includes('turkey')
          ? '✅'
          : '❌'
      } have 1 chicken\n`;
      warns += `${
        flatProtein.includes('seafood') ? '✅' : '❌'
      } have 1 seafood\n`;

      // 每天必须至少有 2-4 种素食选择，（1 种纯素食，如果有的话）+ 1 种清真或无麸质选择
      const flatTags = tags.flat().map((o) => o.toLowerCase());
      warns += `${
        flatTags.includes('veg') || flatTags.includes('vegan') ? '✅' : '❌'
      } have 1 vegan\n`;
      warns += `${
        flatTags.includes('halal') || flatTags.includes('gluten-free')
          ? '✅'
          : '❌'
      } have 1 halal or gluten free\n`;
      warns += `${flatTags.includes('salad') ? '✅' : '❌'} have 1 salad\n`;
      warns += `${
        flatTags.includes('sandwich') ? '✅' : '❌'
      } have 1 sandwich\n`;
      warns += `${flatTags.includes('burger') ? '✅' : '❌'} have 1 burger\n`;

      // 每天必须有大约。 5 种西方美食和 3 种亚洲美食
      const hasWesternTags = _.intersection(WESTERN_TAGS, flatTags);
      warns += `${
        hasWesternTags.length >= 5 ? '✅' : '❌'
      } have 5 westerns cuisine\n`;
      const hasAsianTags = _.intersection(ASIAN_TAGS, flatTags);
      warns += `${
        hasAsianTags.length >= 3 ? '✅' : '❌'
      } have 3 asian cuisine\n`;

      // 同一家餐厅不应连续几天出现
      if (i !== menu.length - 1) {
        const tResoIds = menu[i].restaurants.map((o) => o.id);
        const nResoIds = menu[i + 1].restaurants.map((o) => o.id);
        const resArr = tResoIds.filter((x) => {
          return nResoIds.includes(x);
        });
        warns += `${
          resArr.length ? '❌' : '✅'
        } The same restaurant shouldn't be featured on consecutive days\n`;
      }

      m.warns = warns;
    });
  };

  // const addWeek = (rotate) => {
  // const weekMenu: WeeksMenu = {
  //   rotateWeek: rotate,
  //   menu: [],
  // };
  // for (let i = 1; i <= SERVICE_DAY_IN_WEEK; i++) {
  //   weekMenu.menu.push({
  //     dayOfWeek: i,
  //     meals: [],
  //   });
  // }
  // setWeeksMenu([weekMenu]);
  // };

  const HeadMenu = () => {
    return (
      <div className={styles.rightHead}>
        <div className={styles.rightHeadTxt}>
          Current Promo Week
          <input
            className={styles.input}
            style={{ width: 50, marginLeft: 10, marginTop: 10 }}
            disabled
            value={currentRotate2}
            onChange={(e) => setCurrenRotate(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', paddingRight: 10 }}>
          <div style={{ fontSize: 13 }}>
            Meal Type:
            <Select
              defaultValue={currentMealType}
              style={{ width: 120, marginRight: 10, marginLeft: 10 }}
              onChange={(e) => {
                setCurrentMealType(e);
                getMenuByRotates(currentRotate, e);
              }}
            >
              <Option value="LUNCH">LUNCH</Option>
              <Option value="DINNER">DINNER</Option>
            </Select>
          </div>
          <div style={{ fontSize: 13 }}>
            Promo Weeks:
            {currentRotate !== '' && (
              <Select
                defaultValue={currentRotate}
                style={{ width: 80, marginRight: 10, marginLeft: 10 }}
                onChange={(e) => {
                  setCurrenRotate(e);
                  getMenuByRotates(e, currentMealType);
                }}
              >
                {rotates.map((v) => (
                  <Option value={v} key={v}>
                    {v}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          {/* <div className={styles.btn} onClick={() => setShowModal(true)}>
            Add
          </div> */}
          <Button
            type="primary"
            danger
            loading={saveLoading}
            style={{ borderRadius: 4 }}
            onClick={() => {
              saveWeeksMenu();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <DragDropContext
      onDragEnd={(e) => {
        onDragEnd(e);
        sortMealType();
        setWeeksMenu([...weeksMenu]);
      }}
    >
      <Toaster position="top-center" />
      {/* add rotates */}
      <Modal
        title="Add Rotate Week"
        visible={showModal}
        onOk={() => {
          if (!addRotate) {
            toast.error(`Rotate can't be empty`);
            return;
          }
          const hasRotate = rotates.find((v) => v === Number(addRotate));
          if (hasRotate) {
            toast.error(`Rotate: ${addRotate} already have`);
            return;
          }
          // addWeek(Number(addRotate));
          setAddRotate('');
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
          setAddRotate('');
        }}
        okText="确认"
        cancelText="取消"
      >
        Promo week:
        <input
          className={styles.input}
          style={{ marginLeft: 10 }}
          value={addRotate}
          onChange={(e) => setAddRotate(e.target.value.trim())}
        />
      </Modal>
      <div className={styles.container}>
        {SearchMealContainer()}
        <div className={styles.rightContainer}>
          {HeadMenu()}
          <MenuList />
        </div>
      </div>
      {menuLoading && (
        <div className={styles.mask}>
          <Loading />
        </div>
      )}
    </DragDropContext>
  );
};

export default ScheduleMenu;
