import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import axios from 'axios';
import { PublicHolidays } from '../utils/Constants';
import './styles.css';
import {
  getNextOrderWindow,
  isHolidayOrWeekend,
  getCutoffTime,
} from '../utils/time';
import { Spin } from 'antd';
import HolidayNoService from './HolidayNoService';
import { useLocation, useHistory } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { appStore, getMobileOrPC, googlePlay } from '../utils/Constants';
import styles from './share-menu.module.css';
import _ from 'lodash';

const MenuShare = (): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Moment>();
  const [weekDays, setWeekDays] = useState<{ orderDate: Moment }[]>();
  const [loading, setLoading] = useState(false);
  const [isHoliday, setIsHoliday] = useState(false);
  const [isAvalible, setIsAvalible] = useState(true);
  const [meals, setMeals] = useState<any>();

  const isPC = getMobileOrPC();
  moment.updateLocale('zh', {
    weekdays: [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六',
    ],
  });
  const href = window.location.href;
  const url = 'https://backend.noshpass.com/api';

  const amplitudeKey =
    href.indexOf('stage.ricepass') < 0
      ? '21b140df766a7752effb4df18dacad23'
      : 'ca7407f6985c43ce3ff6fa4c4ee1a71d';
  amplitude.getInstance().init(amplitudeKey);

  const holidays = PublicHolidays;

  const params = new URLSearchParams(useLocation().search);

  const history = useHistory();

  useEffect(() => {
    amplitude.getInstance().logEvent('microsite_i_home_screen');
    refreshDates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshDates = async () => {
    const nextDate = getNextOrderWindow(moment(), holidays, true);
    setSelectedDate(nextDate);
    const monday = nextDate.clone().startOf('isoWeek');

    const { data } = await axios.get(`${url}/serviceDays`, {});

    const wDays = data.map((index) => {
      return {
        orderDate: monday.clone().add(index - 1, 'day'),
      };
    });
    setWeekDays(wDays);
    refreshMenu(nextDate);
  };

  const refreshMenu = async (pickUpDate: Moment) => {
    setLoading(true);
    try {
      // TODO: CHANGE TO ANOTHER ENDPOINT
      const { data } = await axios.get(`${url}/mealsMenu`, {
        params: {
          pickUpDate: pickUpDate?.format('YYYY-MM-DD'),
          lat: params.get('lat') || 29.72861563,
          lng: params.get('lng') || -95.4575555,
          scheduleType: 'LUNCH',
        },
      });
      if (!data.meals.length) {
        setIsHoliday(true);
      } else {
        setIsHoliday(false);
        const mealByReso = _.toArray(
          _.groupBy(data.meals, (o) => o.Restaurant.id)
        );
        setMeals(mealByReso);
      }
    } catch (e) {
      setIsHoliday(true);
    } finally {
      setLoading(false);
      const now = moment();
      setIsAvalible(true);
      if (now.isSame(pickUpDate, 'date')) {
        const LEReservationEnd = getCutoffTime(now.clone(), true);
        setIsAvalible(now.isBefore(LEReservationEnd));
      } else if (now.isAfter(pickUpDate, 'date')) {
        setIsAvalible(false);
      }
    }
  };

  const gotoAppStore = (position: 'header' | 'footer') => {
    return (
      <div className="app-store">
        <img
          className="app-store-img"
          onClick={() => {
            amplitude
              .getInstance()
              .logEvent(
                position === 'header'
                  ? 'home_screen_header_google'
                  : 'home_screen_footer_google'
              );
            window.open(googlePlay);
          }}
          alt="google-play"
          src={require('../assets/images/menu-share/google-play.png')}
        />
        <img
          className="app-store-img"
          onClick={() => {
            amplitude
              .getInstance()
              .logEvent(
                position === 'header'
                  ? 'home_screen_header_apple'
                  : 'home_screen_footer_apple'
              );
            window.open(appStore);
          }}
          alt="app-store"
          src={require('../assets/images/menu-share/app-store.png')}
        />
      </div>
    );
  };

  const otherTag = (title: string) => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          padding: '2px 10px',
          backgroundColor: '#F06A20',
          borderTopLeftRadius: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {title === 'NEW' && (
          <img
            src={require('../assets/icons/newHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'HOT' && (
          <img
            src={require('../assets/icons/popularHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'CHEF CHOICE' && (
          <img
            src={require('../assets/icons/chef_choiceHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        {title === 'REHEATABLE' && (
          <img
            src={require('../assets/icons/reheatableHL.png')}
            style={{ marginRight: 6, height: 16 }}
            alt=""
          />
        )}
        <span
          style={{
            fontSize: 10,
            fontWeight: 'bold',
            color: '#ffffff',
          }}
        >
          {title}
        </span>
      </div>
    );
  };

  return (
    <div
      className="container-share"
      style={{
        maxWidth: isPC ? '450px' : '50000px',
        height: document.body.clientHeight,
      }}
    >
      <div style={{ height: '30px', width: '100%' }}>
        <div className="head-logo">
          <img
            className="share-logo-img"
            alt=""
            src={require('../assets/images/menu-share/plain-logo.png')}
          />
          <span>Delivery without the Fees.</span>
        </div>
      </div>
      <div className="content">
        <div className="tabs">
          {weekDays &&
            weekDays.map((data) => {
              const { orderDate: tagDate } = data;
              const now = moment();
              let tabClass = '';
              if (selectedDate?.isSame(tagDate, 'date')) {
                tabClass = 'tab-active';
              } else if (isHolidayOrWeekend(tagDate, holidays)) {
                tabClass = 'tab-inactive';
              } else if (now.isSame(tagDate, 'date')) {
                const LEReservationEnd = getCutoffTime(now.clone(), true);
                tabClass = now.isBefore(LEReservationEnd) ? '' : 'tab-inactive';
              } else if (now.isAfter(tagDate, 'date')) {
                tabClass = 'tab-inactive';
              }

              return (
                <div
                  className={`tab ${tabClass}`}
                  key={data.orderDate.toISOString()}
                  onClick={async () => {
                    amplitude.getInstance().logEvent('home_screen_tag');
                    setSelectedDate(tagDate);
                    await refreshMenu(tagDate);
                  }}
                >
                  {tagDate.format('DD')} {tagDate.format('ddd')}
                </div>
              );
            })}
        </div>
        {isHoliday ? (
          <HolidayNoService />
        ) : (
          <div className="list-container">
            <div className="list">
              {!!meals &&
                meals.length &&
                meals.map((v) => (
                  <div key={v[0].Restaurant.id}>
                    <div
                      style={{
                        width: '100%',
                        height: 60,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <img
                          alt=""
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: '100%',
                            marginRight: 10,
                          }}
                          src={v[0].Restaurant.firebaseLogoImage}
                        />
                        <span style={{ fontWeight: 800 }}>
                          {v[0].Restaurant.title}
                        </span>
                      </div>
                      <img
                        alt=""
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: '100%',
                          marginRight: 10,
                        }}
                        src={require('../assets/icons/go_restaurant_icon.png')}
                        onClick={() => {
                          localStorage.setItem('resoMeals', JSON.stringify(v));
                          history.push(`restaurant-meals`);
                        }}
                      />
                    </div>
                    <div className={styles.resoList}>
                      {!!v.length &&
                        v.map((val) => (
                          <div
                            className={styles.listCard}
                            key={val.id}
                            onClick={() => {
                              history.push(`meal-detail?id=${val.id}`);
                            }}
                          >
                            {val.color && (
                              <div
                                className="card-color"
                                style={{ backgroundColor: val.color }}
                              >
                                <span>{'Meal ' + val.mealSetChar}</span>
                                {val.tags.length ? (
                                  <span className="card-cusine">
                                    {val.tags[0]}
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            )}
                            <div className="card-img-container">
                              {!val.isOriginalPrice && (
                                <div className="card-sales">
                                  <div className="tag-origin-price">
                                    <span className="card-price">
                                      ${val.estimatedMealPrice.toFixed(2)}
                                    </span>
                                  </div>
                                  <img
                                    alt=""
                                    className="card-sales-img"
                                    src="https://rp-public-assets-us.s3.us-west-2.amazonaws.com/gallery/low8.99.png"
                                  />
                                </div>
                              )}
                              {!!val.isOriginalPrice && (
                                <div className="card-sales2">
                                  <div className="tag-origin-price">
                                    <span className="card-price2">
                                      ${val.estimatedMealPrice.toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {!!val.isOriginalPrice && (
                                <img
                                  src="https://rp-public-assets-us.s3.us-west-2.amazonaws.com/gallery/ZeroAnyFee.png"
                                  style={{
                                    width: 180,
                                    height: 28,
                                    position: 'absolute',
                                    top: 10,
                                  }}
                                  alt=""
                                />
                              )}
                              {!!val.isNew && otherTag('NEW')}
                              {!!val.isPopular && otherTag('HOT')}
                              {!!val.isChefChoice && otherTag('CHEF CHOICE')}
                              {!!val.isReheatable && otherTag('REHEATABLE')}
                              {!isAvalible && (
                                <div className="card-img-avalible">
                                  <img
                                    alt=""
                                    className="no-avalible-img"
                                    src={require('../assets/images/menu-share/time.png')}
                                  />
                                  <div className="no-avalible">
                                    This meal has passed the reservation window.
                                  </div>
                                </div>
                              )}
                              <img
                                className="card-img"
                                alt=" "
                                src={val.firebaseImgFilename}
                              />
                            </div>
                            <div className="card-resturant">{val.title}</div>
                            <div className="card-meal">
                              <span>{val.Restaurant.title}</span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                flexDirection: 'row',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginTop: 4,
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {val.tags.includes('Halal') && (
                                  <img
                                    className="card-portion-meal"
                                    alt=""
                                    src={require(`../assets/icons/halal.png`)}
                                  />
                                )}
                                {val.tags.includes('Gluten') && (
                                  <img
                                    className="card-portion-meal"
                                    alt=""
                                    src={require(`../assets/icons/gluten-free.png`)}
                                  />
                                )}
                                {val.tags.includes('Veg') && (
                                  <img
                                    className="card-portion-meal"
                                    alt=""
                                    src={require(`../assets/icons/veg.png`)}
                                  />
                                )}
                                {val.tags.includes('Nut-Free') && (
                                  <img
                                    className="card-portion-meal"
                                    alt=""
                                    src={require(`../assets/icons/nut-free.png`)}
                                  />
                                )}
                                {val.tags.includes('Dairy-Free') && (
                                  <img
                                    className="card-portion-meal"
                                    alt=""
                                    src={require(`../assets/icons/dairy-free.png`)}
                                  />
                                )}
                                {val.tags.includes('Vegan') && (
                                  <img
                                    className="card-portion-meal"
                                    alt=""
                                    src={require(`../assets/icons/vegan.png`)}
                                  />
                                )}
                              </div>

                              <div
                                className="protion"
                                style={{ marginLeft: 6, marginBottom: 4 }}
                              >
                                Portion:
                                <img
                                  className="card-portion"
                                  alt=""
                                  src={require(`../assets/images/menu-share/portion-${val.reviewPortion}.png`)}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <div className="bottom">
          <div className="bottom-txt1">Want to stop paying any fees?</div>
          <div className="bottom-txt2">Download NoshPass Now!</div>
          {gotoAppStore('footer')}
        </div>
      </div>
      {loading && (
        <div className="loading">
          <Spin
            spinning={true}
            delay={100}
            size="large"
            className="spin"
          ></Spin>
        </div>
      )}
    </div>
  );
};

export default MenuShare;
