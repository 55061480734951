import React, { useEffect, useState } from 'react';
import styles from './place-order.module.css';
import moment, { Moment } from 'moment-timezone';
import { Modal, Button, Select } from 'antd';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { MealCard, OrderCard, PickupLocation, UserInfo } from './type';
import UserCP from './components/userCP';
import { Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getMobileOrPC } from '../utils/Constants';
import _ from 'lodash';

const { confirm } = Modal;

const isPC = getMobileOrPC();

const { Option } = Select;

const url = 'https://backend.noshpass.com/api';
// const url = 'http://localhost:8000/api';

const PlaceOrder = (): JSX.Element => {
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [token, setToken] = useState('');
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [CPs, setCPs] = useState<PickupLocation[]>([]);
  const [weekDays, setWeekDays] = useState<{ orderDate: Moment }[]>([]);
  const [meals, setMeals] = useState<MealCard[]>([]);
  const [orders, setOrders] = useState<OrderCard[][]>([]);
  const [selecedtOrderDate, setSelectedOrderDate] = useState<Moment | null>(
    null
  );
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [restaurantId, setRestaurantId] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(false);
      const t = localStorage.getItem('token');
      if (t) {
        setIsLogin(true);
        setToken(t);
        await onLoad();
      } else {
        setToken('');
        setIsLogin(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let startDate = moment().startOf('isoWeek');
    if (
      moment().isoWeekday() >= 6 &&
      moment().isAfter(moment().set({ hours: 11, minutes: 0, seconds: 0 }))
    ) {
      startDate = moment().add(1, 'week').startOf('isoWeek');
    }
    const wDays = [6].map((index) => {
      return { orderDate: startDate.clone().add(index - 1, 'day') };
    });
    setWeekDays(wDays);
    setSelectedOrderDate(wDays[0].orderDate);
  }, []);

  const onLoad = async () => {
    setLoading(true);
    await getPickupLocations();
    await refreshUserInfo();
    await refreshRestaurant();
    await refreshOrderList();
    setLoading(false);
  };

  const onLogin = async () => {
    if (!phone || !code) {
      toast.error('Phone or code is invalid!');
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.post(`${url}/user/phone_login`, {
        mobilePhone: `+1${phone}`,
        otp: code,
      });
      setToken(data.data.token);
      setIsLogin(true);
      localStorage.setItem('token', data.data.token);
      await onLoad();
    } catch (e) {
      toast.error('Login faild');
    } finally {
      setLoading(false);
    }
  };

  const refreshUserInfo = async () => {
    try {
      const t = localStorage.getItem('token');
      const { data } = await axios.get(`${url}/user/settings/info`, {
        headers: {
          'x-access-token': t,
        },
      });
      setUserInfo(data.user);
    } catch (e) {
      localStorage.clear();
      setToken('');
      setIsLogin(false);
    } finally {
      setLoading(false);
    }
  };

  const getPickupLocations = async () => {
    const { data } = await axios.get(`${url}/pickupLocations`);
    const cps = data.data;
    cps.sort((a, b) => {
      return a.nameZh.localeCompare(b.nameZh);
    });
    setCPs(cps);
  };

  const saveUserPickupLocations = async (selectedCP: String) => {
    if (!selectedCP || selectedCP === userInfo?.cpId) return;
    setLoading(true);
    const locations = CPs.find((v) => v.id === selectedCP);
    await axios.post(
      `${url}/user/settings/editProfile`,
      {
        deliveryAddress: locations?.fullAddress,
        deliveryAddressLat: locations?.lat,
        deliveryAddressLng: locations?.lng,
      },
      {
        headers: {
          'x-access-token': token,
        },
      }
    );
    await refreshUserInfo();
    setLoading(false);
  };

  const LoginContainer = () => {
    return (
      <div className={styles.loginContainer}>
        <input
          type="text"
          className={styles.input}
          placeholder="mobile phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            className={styles.input}
            placeholder="code"
            maxLength={6}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>

        <Button
          type="primary"
          className={styles.btn}
          onClick={onLogin}
          loading={loading}
          color="red"
        >
          Login
        </Button>
      </div>
    );
  };

  const refreshRestaurant = async () => {
    const { data } = await axios.get(`${url}/restaurants/list`);
    const res = data.data;
    res.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
    setRestaurants(res);
  };

  const refreshMenu = async (restaurantId) => {
    setLoading(true);
    const { data } = await axios.get(
      `${url}/meal-by-restaurant?restaurantId=${restaurantId}`
    );
    const res: MealCard[] = data.map((v) => {
      return {
        RestaurantTitle: v.Restaurant.title,
        id: v.id,
        title: v.title,
        imgFilename: v.firebaseImgFilename,
        mealSetChar: v.mealSetChar,
        number: 0,
      };
    });
    setLoading(false);
    setMeals(res);
  };

  const handleConfirmOrder = async () => {
    const orders = meals.filter((v) => v.number);
    if (!orders.length) {
      toast.error('No dishes to order!');
      return;
    }

    if (!selecedtOrderDate) {
      toast.error('No selected order date');
      return;
    }

    const locations = CPs.find((v) => v.id === userInfo?.cpId);
    if (!locations) {
      toast.error('User CP is invalid!');
      return;
    }

    let orderNumber = 0;
    orders.forEach((v) => {
      orderNumber += v.number;
    });
    if (orderNumber > (userInfo?.creditsAmount || 0)) {
      toast.error('User credit not enough!');
      return;
    }

    const pickupStart = moment(
      `${selecedtOrderDate.format('YYYY-MM-DD')} ${locations.pickupStart}`
    ).toISOString();
    const pickupEnd = moment(
      `${selecedtOrderDate.format('YYYY-MM-DD')} ${locations.pickupEnd}`
    ).toISOString();

    setConfirmLoading(true);
    await axios.post(
      `${url}/meals/reserve-company-meal`,
      {
        pickupStart,
        pickupEnd,
        locationId: locations.id,
        orders,
      },
      {
        headers: {
          'x-access-token': token,
        },
      }
    );

    setConfirmLoading(false);
    setVisible(false);
    toast.success('Order finish!');

    setLoading(true);
    await refreshUserInfo();
    await refreshMenu(restaurantId);
    await refreshOrderList();
    setLoading(false);
  };

  const refreshOrderList = async () => {
    setLoading(true);
    const t = localStorage.getItem('token');
    const { data } = await axios.get(`${url}/user/orders/list`, {
      headers: {
        'x-access-token': t,
      },
    });
    const res: OrderCard[] = data.orders.allOrders
      .filter((v) => !v.isPickedUp && !v.isExpired)
      .map((v) => {
        return {
          id: v.id,
          mealTitle: v.Meal?.title || '',
          imgFilename: v.Meal?.firebaseImgFilename || '',
          mealSetChar: v.Meal?.mealSetChar || '',
          restaurantTitle: v.Restaurant?.title || '',
          expressPickupTimeStart: v.expressPickupTimeStart,
          isExpired: v.isExpired,
          isPickedUp: v.isPickedUp,
        };
      });
    res.sort((a, b) => {
      return moment(a.expressPickupTimeStart).isAfter(b.expressPickupTimeStart)
        ? 1
        : -1;
    });

    const mealGroup = _.toArray(
      _.groupBy(res, (o) =>
        moment(o.expressPickupTimeStart).format('YYYY-MM-DD')
      )
    );

    setOrders(mealGroup);
    setLoading(false);
  };

  const handleCancelOrder = async (orderId) => {
    setLoading(true);
    try {
      await axios.get(`${url}/user/orders/cancel/${orderId}`, {
        headers: {
          'x-access-token': token,
        },
      });
      await refreshOrderList();
      toast.success('Cancel success!');
    } catch (e) {
      toast.error('Faild to cancel');
    } finally {
      setLoading(false);
    }
  };

  const menuComponent = () => {
    return (
      <div style={{ width: '100%' }}>
        {meals.map((v, i) => {
          return (
            <div className={styles.card} key={i}>
              <div className={styles.displayrow}>
                <img
                  alt=""
                  className={styles.mealImg}
                  src={v.firebaseImgFilename}
                />
                <div className={styles.mealInfo}>
                  <span style={{ color: '#f06a20' }}>{v.title}</span>
                </div>
              </div>
              <input
                type="text"
                className={styles.orderInpu}
                placeholder=""
                value={v.number}
                onChange={(e) => {
                  meals[i].number = isNaN(Number(e.target.value))
                    ? 0
                    : Number(e.target.value);
                  setMeals([...meals]);
                }}
              />
            </div>
          );
        })}
        {!!meals.length && (
          <>
            <Modal
              title=""
              visible={visible}
              onOk={handleConfirmOrder}
              confirmLoading={confirmLoading}
              onCancel={() => setVisible(false)}
              maskClosable={false}
              keyboard={false}
            >
              <p>Do you confirm to order?</p>
            </Modal>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={() => {
                setVisible(true);
              }}
            >
              Order
            </Button>
          </>
        )}
      </div>
    );
  };

  const ordersComponent = () => {
    return (
      <>
        <div style={{ width: '100%' }}>
          <div
            style={{
              textAlign: 'left',
              marginTop: 10,
              marginBottom: 10,
              fontWeight: 'bold',
            }}
          >
            order List:
          </div>
          {orders.map((order, index) => {
            const restaurantGroup = _.toArray(
              _.groupBy(order, (o) => o.restaurantTitle)
            );
            return (
              <div key={index}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>
                    date:{' '}
                    {moment(order[0].expressPickupTimeStart).format(
                      'YYYY-MM-DD'
                    )}
                  </span>
                  <span>total: {order.length}</span>
                </div>
                {restaurantGroup.map((restaurant, inx) => {
                  return (
                    <div key={inx}>
                      <div
                        style={{
                          fontSize: 12,
                          color: '#f06a20',
                          textAlign: 'left',
                        }}
                      >
                        <span>
                          {restaurant[0].restaurantTitle}: {restaurant.length}
                        </span>
                      </div>
                      {restaurant.map((v, i) => {
                        return (
                          <div className={styles.card} key={i}>
                            <div className={styles.displayrow}>
                              <img
                                alt=""
                                className={styles.mealImg}
                                src={v.firebaseImgFilename}
                              />
                              <div className={styles.mealInfo}>
                                <div>{v.mealTitle}</div>
                              </div>
                            </div>
                            <div>
                              <Button
                                type="primary"
                                danger
                                style={{
                                  fontSize: 12,
                                  width: 68,
                                  marginRight: 10,
                                }}
                                onClick={() => {
                                  confirm({
                                    title: 'Do you Want to cancel this order?',
                                    icon: <ExclamationCircleOutlined />,
                                    content: '',
                                    onOk() {
                                      handleCancelOrder(v.id);
                                    },
                                  });
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div
      className={styles.container}
      style={{ maxWidth: isPC ? '450px' : '50000px' }}
    >
      <Toaster position="top-center" />
      {!isLogin && LoginContainer()}
      {isLogin && (
        <>
          {loading && <Spin size="large" className={styles.loading} />}
          <div
            className={styles.logout}
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Logout
          </div>
          <div className={styles.content}>
            <div>lastName: {userInfo?.lastName}</div>
            <div>mobilePhone: {userInfo?.mobilePhone}</div>
            <div>credit: {userInfo?.creditsAmount}</div>
            <UserCP
              CPs={CPs}
              userInfo={userInfo}
              onSave={(cp) => saveUserPickupLocations(cp)}
            />
            <div
              className={styles.displayrow}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <span>order Date:</span>
              {weekDays.map((v, i) => (
                <div style={{ fontWeight: 'bold' }} key={i}>
                  {v.orderDate.format('YYYY-MM-DD')}
                </div>
              ))}
            </div>
            <Select
              defaultValue=""
              style={{ width: '100%', marginBottom: 10 }}
              onChange={(e) => {
                setRestaurantId(e);
                refreshMenu(e);
              }}
            >
              {restaurants.map((v, i) => (
                <Option value={v.id} key={i}>
                  {v.title}
                </Option>
              ))}
            </Select>
            {menuComponent()}
            {ordersComponent()}
          </div>
        </>
      )}
    </div>
  );
};

export default PlaceOrder;
