import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import styles from '../noshsimple-driver.module.scss';
import { JsonToTable } from 'react-json-to-table';
import { Collapse } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { openLocationInMap } from '../../utils/geo';
import { FaDirections } from 'react-icons/fa';
import AppButton from '../../DriverRoute/components/appButton';
import Axios from 'axios';
import { SIMPLE_URL } from '../../utils/Constants';
import _ from 'lodash';
import toast from 'react-hot-toast';

const Restaurant = (props: { driverRoutes: any }): JSX.Element => {
  const { Panel } = Collapse;
  const [driverRoutes, setDriverRoutes] = useState<any>({
    ordersInRestaurant: [],
  });
  const [loading, setLoading] = useState(false);

  const convertKeys = (data) => {
    return _.mapKeys(data, (v, key) => _.startCase(_.lowerCase(key)));
  };

  useEffect(() => {
    setDriverRoutes({ ...props.driverRoutes });
  }, [props.driverRoutes]);

  const editRoute = async (hashKey: string, type: string) => {
    if (window.confirm(`Are you sure to confirm it?`)) {
      setLoading(true);
      const { data } = await Axios.post(
        `${SIMPLE_URL}/driver/start-or-finish`,
        {
          hashKey,
          type,
        }
      );
      setLoading(false);
      if (!data) {
        toast.error(`Something wrong, Please try again`);
        return;
      }
      // driverRoute[type] = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
      // setDriverRoute({ ...driverRoute });
      window.location.reload();
    }
  };

  const editClass = (address: any, type: string) => {
    if (!props.driverRoutes.endTime && !!address[`${type}Time`]) {
      const outTime = moment.tz(props.driverRoutes.date, 'US/Central').set({
        hour: 16,
        minute: type === 'arrived' ? 0 : 20,
      });

      const isTimeOut = moment
        .tz(`${address[`${type}Time`]}`, 'US/Central')
        .isAfter(outTime);
      return `${styles.nsArriveBtn} ${
        isTimeOut ? styles.timeOut : styles.onTime
      }`;
    }
    if (!!props.driverRoutes.endTime) {
      return styles.inactiveBtn;
    }
    const style = {
      arrived: styles.nsArriveBtn,
      left: styles.leftBtn,
    }[type];
    return style;
  };

  const editAddress = async (address: any, type: string) => {
    if (!!driverRoutes.endTime || !!address[`${type}Time`]) return;

    if (window.confirm(`Are you sure to confirm it?`)) {
      address[`${type}Loading`] = true;
      setDriverRoutes({ ...driverRoutes });
      const { data } = await Axios.post(
        `${SIMPLE_URL}/driver/update-restaurant-time`,
        {
          hashKey: driverRoutes.hashKey,
          timeType: `${type}Time`,
          lat: address.lat,
          lng: address.lng,
        }
      );
      if (!data) {
        toast.error(`Someting wrong, Please try again`);
        address[`${type}Loading`] = false;
        setDriverRoutes({ ...driverRoutes });
        return;
      }
      address[`${type}Time`] = moment
        .tz('US/Central')
        .format('YYYY-MM-DD HH:mm:ss');
      address[`${type}Loading`] = false;
      setDriverRoutes({ ...driverRoutes });
    }
  };

  return (
    <>
      <div style={{ marginBottom: 80 }}>
        <h3 className={styles.h3}>
          Driver Shift Details [B#{driverRoutes.driverId}] [
          {moment.tz(driverRoutes.date, 'US/Central').format('MMM DD')}]
        </h3>
        {!driverRoutes.startTime && (
          <AppButton
            text="START SHIFT"
            className={styles.startShift}
            loading={loading}
            onClick={() => {
              if (loading) return;
              editRoute(driverRoutes.hashKey, 'startTime');
            }}
          />
        )}
        {!!driverRoutes.startTime && !driverRoutes.endTime && (
          <AppButton
            text="END SHIFT"
            className={styles.endShift}
            loading={loading}
            onClick={() => {
              if (loading) return;
              editRoute(driverRoutes.hashKey, 'endTime');
            }}
          />
        )}
        {!driverRoutes.ordersInRestaurant.length && (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Orders to Distribute at Restaurant
            </div>
            No Order Yet
          </div>
        )}
        {!!driverRoutes.ordersInRestaurant.length &&
          driverRoutes.ordersInRestaurant.map((o, inx) => {
            const route = JSON.parse(JSON.stringify(o));
            return (
              <div
                className={styles.section}
                style={{ padding: 0, marginBottom: 20 }}
                key={inx}
              >
                <Collapse
                  bordered={false}
                  ghost
                  expandIconPosition="right"
                  style={{ color: 'red' }}
                >
                  <Panel
                    header={
                      <div
                        style={{
                          color: '#0bc180',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Restaurant {inx + 1}
                        <InfoCircleOutlined
                          style={{ marginLeft: 10 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            alert(o.address);
                          }}
                        />
                      </div>
                    }
                    key={inx}
                    className={styles.sectionTitle}
                  >
                    <div className={styles.noshstopTitle}>
                      <div>Location {inx + 1}</div>
                      <div
                        className={`${styles.simpleButton} ${styles.navBtn}`}
                        onClick={() =>
                          openLocationInMap(`${route.lat},${route.lng}`)
                        }
                      >
                        Map <FaDirections size={'1.2rem'} />
                      </div>
                    </div>
                    {!!driverRoutes.startTime && (
                      <div className={styles.nsBtnList}>
                        <AppButton
                          text="ARRIVED"
                          className={editClass(o, 'arrived')}
                          onClick={() => {
                            editAddress(o, 'arrived');
                          }}
                          loading={o.arrivedLoading}
                        />
                        <AppButton
                          text="LEFT"
                          className={editClass(o, 'left')}
                          onClick={() => {
                            editAddress(o, 'left');
                          }}
                          loading={o.leftLoading}
                        />
                      </div>
                    )}
                    <div style={{ overflow: 'auto' }}>
                      <JsonToTable json={convertKeys(route)} />
                    </div>
                  </Panel>
                </Collapse>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Restaurant;
