import React, { FC, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import axios from 'axios';
import { SIMPLE_URL } from '../utils/Constants';
import { Select, Spin } from 'antd';

const NoshSimpleImagesUpload: FC = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyDVQan1L_WkMAGHNJ3u5VTAHQwEe8QJfQo',
    authDomain: 'nosh-simple-ee5c6.firebaseapp.com',
    projectId: 'nosh-simple-ee5c6',
    storageBucket: 'nosh-simple-ee5c6.appspot.com',
    messagingSenderId: '645568565996',
    appId: '1:645568565996:web:1acb68f5c6303ba9b0cbc9',
    measurementId: 'G-8D675W23V8',
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [meals, setMeals] = useState<any[]>([]);
  const [selectedMeal, setSelectedMeal] = useState<any>({});

  useEffect(() => {
    refreshMenu();
  }, []);

  const refreshMenu = async () => {
    setLoading(true);
    const { data } = await axios.get(`${SIMPLE_URL}/meals`);
    setLoading(false);
    setMeals(data.meals);
  };

  const changeMealImage = async (e) => {
    setLoading(true);
    const imgId = v4().slice(-8);
    const storageRef = ref(storage, `Meals/${imgId}.png`);
    try {
      const result = await uploadBytes(storageRef, e.target.files[0]);
      const imageLink = `https://firebasestorage.googleapis.com/v0/b/nosh-simple-ee5c6.appspot.com/o/Meals%2F${result.metadata.name}?alt=media`;
      await axios.post(`${SIMPLE_URL}/meals/update-images`, {
        id: selectedMeal.id,
        imageLink,
      });
      setLoading(false);
      alert('upload success!');
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert(e);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {loading && (
        <Spin
          size="large"
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            left: 0,
          }}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <div
          style={{
            marginLeft: 100,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: 500,
          }}
        >
          <div>
            Meal:
            <Select
              defaultValue=""
              style={{ width: 400 }}
              onChange={(e) => {
                const meal = meals.find((v) => v.id === e);
                setSelectedMeal(meal);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  !!option!.children &&
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                );
              }}
            >
              {meals.map((v, i) => (
                <Option value={v.id} key={i}>
                  {v.title}
                </Option>
              ))}
            </Select>
          </div>
          {!!selectedMeal.id && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ width: 200, marginTop: 20, textAlign: 'left' }}>
                Image:
              </div>
              <img
                style={{ width: 400, height: 300 }}
                src={selectedMeal.imageLink}
                alt=""
              />
              <input
                type="file"
                style={{ marginTop: 20, width: 200 }}
                onChange={(e) => changeMealImage(e)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoshSimpleImagesUpload;
