import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const Promo = (params: any): JSX.Element => {
  const history = useHistory();
  useEffect(() => {
    const userAgent = params?.match?.params?.platform || navigator.userAgent || navigator.vendor

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod|ios/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/us/app/ricepass/id1538873562'
    }

    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.ricepass.mobile'
    }

    setTimeout(() => {
      history.push('/')
    }, 500);
  })

  return (
    <div className="main-container">
    
    </div>
  );
};

export default Promo;
