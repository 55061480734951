import React from 'react';
import 'react-awesome-animated-number/dist/index.css';
import { getMobileOrPC } from '../utils/Constants';

const Notices = (): JSX.Element => {
  const isPC = getMobileOrPC();

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: isPC ? 800 : '100%',
          height: 400,
          backgroundColor: '#ffffff',
          paddingTop: 20,
          marginBottom: 20,
        }}
      >
        <img
          style={{ width: 100, height: 100 }}
          alt="logo"
          src="https://ik.imagekit.io/z33v7m5eicg/down_BiG2phbIx_.png?updatedAt=1689081101621"
        />
        <div
          style={{
            boxSizing: 'border-box',
            paddingLeft: 20,
            paddingRight: 20,
            fontFamily: 'Helvetica',
            color: '#000000',
            lineHeight: 1.5,
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          Summer Announcement
        </div>
        <div
          style={{
            boxSizing: 'border-box',
            paddingLeft: 20,
            paddingRight: 20,
            fontFamily: 'Helvetica',
            color: '#808080',
            lineHeight: 1.5,
            textAlign: 'justify',
          }}
        >
          This summer, we'll pause to upgrade our NoshPass restaurant meal
          delivery service, and will return again in the fall. <p></p>From Fri
          (7/14), all NoshPassers will have priority access to our new meal
          service Nosh Simple, to enjoy quality delivered meals for as low as
          $7/meal. <p></p>Visit NoshSimple.com for instant access. Question?
          Text 833-583-2161.
        </div>
      </div>
    </div>
  );
};

export default Notices;
