import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import styles from '../noshsimple-driver.module.scss';

const errorColor = '#d9363e';
const correctColor = '#389e0d';

const Stamp = (props: { driverRoutes: any }): JSX.Element => {
  const [maxNoshStop, setMaxNoshStop] = useState(0);
  const routes = props.driverRoutes;

  useEffect(() => {
    routes.forEach((r) => {
      if (r.ordersInLocations.length > maxNoshStop) {
        setMaxNoshStop(r.ordersInLocations.length);
      }
    });
  });

  const computedNoshStop = (date: string, ns: any) => {
    if (!ns) return <>-</>;

    const outOfLeft = moment
      .tz(ns.leftTime, 'US/Central')
      .isAfter(moment.tz(`${date} ${ns.pickupEnd}`, 'US/Central'));

    const outOfArrived = moment
      .tz(ns.arrivedTime, 'US/Central')
      .isAfter(moment.tz(`${date} ${ns.pickupStart}`, 'US/Central'));

    if (ns.arrivedTime || ns.leftTime) {
      return (
        <>
          {ns.arrivedTime && (
            <>
              <span
                style={{
                  color: outOfArrived ? errorColor : correctColor,
                  marginRight: 10,
                }}
              >
                ARRIVE (
                {delayTime(
                  outOfArrived,
                  moment.tz(ns.arrivedTime, 'US/Central'),
                  moment.tz(`${date} ${ns.pickupStart}`, 'US/Central')
                )}
                {moment.tz(ns.arrivedTime, 'US/Central').format('HH:mm')})
              </span>
              <br />
            </>
          )}
          {ns.leftTime && (
            <>
              <span style={{ color: outOfLeft ? errorColor : correctColor }}>
                LEFT (
                {delayTime(
                  outOfLeft,
                  moment.tz(ns.leftTime, 'US/Central'),
                  moment.tz(`${date} ${ns.pickupEnd}`, 'US/Central')
                )}
                {moment.tz(ns.leftTime, 'US/Central').format('HH:mm')})
              </span>
              <br />
            </>
          )}
        </>
      );
    }

    return <>*</>;
  };

  const delayTime = (
    isTimeOut: boolean,
    currenTime: Moment,
    targetTime: Moment
  ) => {
    if (!isTimeOut) return '';

    const diff = currenTime.diff(targetTime, 'minute');
    return diff > 0 ? `+${diff.toFixed(0)}, ` : '';
  };

  const table = () => {
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>DRIVER</th>
            {new Array(maxNoshStop).fill('').map((v, i) => (
              <>
                <th key={'stop' + i}>STOP{i + 1}</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {routes.map((route, index) => (
            <tr key={index}>
              <td>
                B#{route.driverId}
                {!!route.driverPhone && <span> ({route.driverPhone})</span>}
              </td>
              {new Array(maxNoshStop).fill('').map((v, i) => (
                <td key={'s' + i}>
                  {computedNoshStop(route.date, route.ordersInLocations[i])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className={styles.recordCard}>
        <div>{table()}</div>
      </div>
      <div className={styles.noshstopTitle}>
        {/* <span style={{ marginRight: 10 }}>Driver Path:</span> */}
        {/* <div
          className={styles.linkBtn}
          onClick={() => window.open(`https://noshpass.com/driver.html`)}
        >
          Tap to Open
        </div> */}
      </div>
    </>
  );
};

export default Stamp;
