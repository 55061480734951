import React, { useState } from 'react';
import { PickupLocation, UserInfo } from '../type';
import { Button, Select } from 'antd';
import styles from '../place-order.module.css';

const { Option } = Select;

interface UserCPProps {
  userInfo: UserInfo | null;
  CPs: PickupLocation[];
  onSave?: (selectedCP: string) => void;
}

const UserCP = (props: UserCPProps): JSX.Element => {
  const [editCP, setEditCP] = useState(false);
  const [selectedCP, setSelectedCP] = useState('');

  const computedLocation = () => {
    const cp = props.CPs.find((v) => v.id === props.userInfo?.cpId);
    return `${cp?.nameZh}(${cp?.name})`;
  };

  return (
    <>
      nosh stop:
      {props.userInfo?.cpId && (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Select
            defaultValue={computedLocation()}
            className={styles.select}
            disabled={!editCP}
            onChange={(e) => setSelectedCP(e)}
          >
            {props.CPs.map((v) => (
              <Option value={v.id} key={v.id}>
                {v.nameZh}({v.name})
              </Option>
            ))}
          </Select>
          {!editCP ? (
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => setEditCP(true)}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                style={{ marginLeft: 4 }}
                onClick={() => {
                  if (props.onSave) {
                    props.onSave(selectedCP);
                    setEditCP(false);
                  }
                }}
              >
                Save
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 4 }}
                onClick={() => setEditCP(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default UserCP;
