import i18n from 'i18next';
import Locize from 'i18next-locize-backend';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

export enum Languages {
  EN = 'en',
}

const allowedLanguages = [Languages.EN];

const storageLanguage = localStorage.getItem('language');

let lng = Languages.EN;

if (
  storageLanguage &&
  allowedLanguages.indexOf(storageLanguage as Languages) > -1
) {
  lng = storageLanguage as Languages;
}

const pathLanguage = window.location.pathname.split('/')[1];
if (allowedLanguages.includes(pathLanguage as Languages)) {
  lng = pathLanguage as Languages;
}

i18n
  .use(XHR)
  .use(Locize)
  .use(initReactI18next)
  .init({
    backend: {
      projectId:
        process.env.REACT_APP_LOCIZE_PROJECT_ID ||
        'd6b02118-bbec-43da-867a-d92256f2836f',
      apiKey:
        process.env.REACT_APP_LOCIZE_API_KEY ||
        '32763e47-1014-48c6-98ee-42e01bc2bda8',
      referenceLng: Languages.EN,
    },
    defaultNS: 'common',
    lng,
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: Languages.EN,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
