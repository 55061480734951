import React, { FC } from 'react';
import 'react-awesome-animated-number/dist/index.css';
import styles from './meal-card.module.css';
import { DeleteOutlined } from '@ant-design/icons';
import { Select } from 'antd';

export interface RestaurantCardProps {
  restaurant: any;
  showClose: boolean;
  showSelect: boolean;
  onClose?: () => void;
}

const { Option } = Select;

export const RestaurantCard: FC<RestaurantCardProps> = (
  props: RestaurantCardProps
) => {
  return (
    <div className={styles.mealItem}>
      <div className={styles.mealContent}>
        {props.showClose && (
          <div className={styles.close} onClick={props.onClose}>
            <DeleteOutlined />
          </div>
        )}
        {props.showClose && (
          <div className={styles.mealType}>{props.restaurant.mealTypeId}</div>
        )}
        <div className={styles.mealTitle}>
          <div style={{ color: '#F06A20', fontWeight: 'bold', marginTop: 4 }}>
            {props.restaurant.title}
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 4 }}>
            <span style={{ fontWeight: 400 }}>category:</span>{' '}
            {props.restaurant.category}
          </div>
          {props.restaurant.lunchRotate && (
            <div style={{ fontWeight: 'bold', marginTop: 4 }}>
              <span style={{ fontWeight: 400 }}>lunch promo week:</span>{' '}
              {props.restaurant.lunchRotate.join(', ')}
            </div>
          )}
          {!!props.restaurant.dinnerRotate && (
            <div style={{ fontWeight: 'bold', marginTop: 4 }}>
              <span style={{ fontWeight: 400 }}>dinner promo week:</span>{' '}
              {props.restaurant.dinnerRotate.join(', ')}
            </div>
          )}
          <div style={{ fontWeight: 'bold', marginTop: 4 }}>
            <span style={{ fontWeight: 400 }}>total order:</span>{' '}
            {props.restaurant.historyOrderAmount}
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 4 }}>
            <span style={{ fontWeight: 400 }}>total order:</span>{' '}
            {props.restaurant.historyOrderAmount}
          </div>
          {!!props.restaurant.tags && (
            <div style={{ fontWeight: 'bold', marginTop: 4 }}>
              <span style={{ fontWeight: 400 }}>total sold:</span>{' '}
              {props.restaurant.tags.join(', ')}
            </div>
          )}
          <div style={{ fontWeight: 'bold', marginTop: 4 }}>
            <span style={{ fontWeight: 400 }}>total options:</span>{' '}
            {props.restaurant.mealAmount}
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 4 }}>
            <span style={{ fontWeight: 400 }}>price range:</span> $
            {props.restaurant.priceRange}
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 4 }}>
            <span style={{ fontWeight: 400 }}>distance per mile: </span>
            {props.restaurant.driving || 'unknown'} min
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 4 }}>
            <span style={{ fontWeight: 400 }}>ratings:</span>{' '}
            {Number(props.restaurant.badRatings) === 0
              ? '100%'
              : (
                  (Number(props.restaurant.goodRatings) /
                    (Number(props.restaurant.badRatings) +
                      Number(props.restaurant.goodRatings))) *
                  100
                ).toFixed(0) + '%'}
          </div>
          {props.showSelect && (
            <div>
              <span>type:</span>
              <Select
                defaultValue={props.restaurant.scheduleType}
                style={{
                  width: 120,
                  marginRight: 10,
                  marginLeft: 10,
                  marginTop: 4,
                }}
                onChange={(e) => {
                  props.restaurant.scheduleType = e;
                  props.restaurant.mealIds = props.restaurant.schedules.find(
                    (v) => v.type === e
                  ).meals;
                }}
              >
                {props.restaurant.schedules.map((v, i) => {
                  return (
                    <Option value={v.type} key={i}>
                      {v.type}
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantCard;
