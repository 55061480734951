import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styles from './driver.module.scss';
import Loading from '../Components/Loading';
import { BASE_URL } from '../utils/Constants';
import NoshStop from './components/noshStop';
import FoodSwap from './components/foodSwap';

const DriverRoleNS = (): JSX.Element => {
  const { hashKey } = useParams<any>();

  const url = BASE_URL;
  const tabs = ['FoodSwap', 'NoshStop'];
  const [loading, setLoading] = useState(false);
  const [driverRoutes, setDriverRoutes] = useState<any>();
  const [active, setActive] = useState(1);
  const [invalidLink, setInvalidLink] = useState(false);
  const [enableGeo, setEnableGeo] = useState(false);
  const [geoErrMsg, setGeoErrMsg] = useState('');

  useEffect(() => {
    getDriverRoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      setEnableGeo(true);
    } else {
      setEnableGeo(false);
      alert('Location services are not supported');
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        !driverRoutes ||
        !driverRoutes[0] ||
        !driverRoutes[0].startTime ||
        driverRoutes[0].endTime
      )
        return;
      navigator.geolocation.getCurrentPosition(
        (v) => {
          // console.log(v.coords.latitude, v.coords.longitude);
          setGeoErrMsg('');
          Axios.post(`${url}/driver/position/save`, {
            hashKey: driverRoutes[0].hashKey,
            lat: v.coords.latitude,
            lng: v.coords.longitude,
            driverId: driverRoutes[0].driverId,
          });
        },
        (err) => {
          console.log(err);
          setGeoErrMsg(
            'Failed to obtain location, please enable location permission'
          );
        }
      );
    }, 10000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableGeo, driverRoutes]);

  const getDriverRoutes = async () => {
    setLoading(true);
    const uri = `${url}/driver/routes/${hashKey}`;
    const { data } = await Axios.get(uri);
    setLoading(false);
    if (data === null || !!data.totalOrders) {
      setInvalidLink(true);
      return;
    }

    console.log(data);
    // 控制按钮loading
    data.u1ArrivedLoading = false;
    data.u1LeftLoading = false;
    data.orderInRestaurant.forEach((v) => {
      v.arrivedLoading = false;
      v.leftLoading = false;
      v.delayLoading = false;
    });
    data.orderInLocations.forEach((v) => {
      v.arrivedLoading = false;
      v.leftLoading = false;
      v.uploadLoading = false;
    });

    setDriverRoutes([data]);
  };

  return invalidLink ? (
    <div style={{ margin: '50px 20px' }}>
      Link invalid, make sure you are using link for today.
    </div>
  ) : (
    <div className={styles.shiftBody}>
      {loading && <Loading />}
      {!loading && (
        <div className={styles.container}>
          <div className={styles.warnTxt}>{geoErrMsg}</div>
          <div style={{ display: active === 1 ? 'block' : 'none' }}>
            {driverRoutes && <FoodSwap driverRoutes={driverRoutes} />}
          </div>
          <div style={{ display: active === 2 ? 'block' : 'none' }}>
            {driverRoutes && <NoshStop driverRoutes={driverRoutes} />}
          </div>
        </div>
      )}
      <div className={styles.tabbar}>
        {tabs.map((v, i) => (
          <div
            key={i}
            className={`${styles.tabitem} ${
              active === i + 1 ? styles.active : ''
            }`}
            onClick={() => setActive(i + 1)}
          >
            {v}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DriverRoleNS;
