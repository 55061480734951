declare const navigator: any;

/**
 * @param latlng format in lat,lng, e.g. 29.71589583,-95.5261003
 */
export const openLocationInMap = (latlng: string): void => {
  const platform =
    navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

  if (
    /* if we're on iOS, open in Apple Maps */
    platform.indexOf('iPhone') !== -1 ||
    platform.indexOf('iPod') !== -1 ||
    platform.indexOf('iPad') !== -1
  ) {
    window.open(`maps://maps.google.com/maps?q=${latlng}`);
  } else {
    window.open(`https://maps.google.com/maps?q=${latlng}`);
  }
};
/**
 * @param latlng format in lat,lng, e.g. 29.71589583,-95.5261003
 */
export const directToInMap = (latlng: string): void => {
  const platform =
    navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

  if (
    /* if we're on iOS, open in Apple Maps */
    platform.indexOf('iPhone') !== -1 ||
    platform.indexOf('iPod') !== -1 ||
    platform.indexOf('iPad') !== -1
  ) {
    window.open(`maps://maps.google.com/maps?daddr=${latlng}`);
  } else {
    window.open(`https://maps.google.com/maps?daddr=${latlng}`);
  }
};
