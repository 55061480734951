import axios from 'axios';
import React, { useEffect } from 'react';
import { getFingerprint } from 'react-fingerprint';
import { BASE_URL } from '../utils/Constants';

const Jump = (): JSX.Element => {
  useEffect(() => {
    (async () => {
      const fingerprint = await getFingerprint();
      await axios.post(`${BASE_URL}/tools/deep-link-open`, {
        fingerprint,
      });

      window.location.href = 'https://nosh.page.link/6ih7';
    })();
  }, []);

  return <></>;
};

export default Jump;
