import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useHistory, useLocation } from 'react-router';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-i18n';
import App from '../AppDownload/App';
import Loading from '../Components/Loading';
import { Home } from '../Home/Home';
import MenuShare from '../MenuShare';
import Cookie from '../MenuShare/Cookie';
import SaturdayPage from '../MenuShare/Saturday';
import RestaurantMeals from '../MenuShare/RestaurantMeals';
import MealDetail from '../MenuShare/MealDetail';
import OrderSummary from '../OrderSummary';
import { UserContext } from '../Providers/user.provider';
import ReferralStatic from '../Referral/ReferralStatic';
import DriverImage from '../DriverRoute/image';

import MarketingEntrance from '../Marketing/Entrance';
import PlaceOrder from '../PlaceOrder/PlaceOrder';
import Invite from '../Invite';
import ScheduleRestaurants from '../MenuToolingV2/Restaurant';
import ScheduleMenu from '../MenuToolingV2/Menu';
import Notices from '../Notices/Index';
import Policy from '../Policy/Policy';
import ResoMeals from '../ResoMeals/ResoMeals';
import WebsiteLinks from '../WebsiteLinks/WebsiteLinks';
import OrderSummaryReminder from '../OrderSummaryReminder';
import TheDayReminder from '../OrderSummaryReminder/theDayReminder';
import DirverRouteUpdate from '../DirverRouteUpdate/DirverRouteUpdate';

import DriverManager from '../DriverRoute';
import DriverAmount from '../DriverRoute/driver-amount';
import DriverRole from '../DriverRoute/role';
import DriverRoleReso from '../DriverRoute/role-reso';
import DriverRoleNS from '../DriverRoute/role-ns';
import DirverUpdate from '../DriverRoute/update';
import Instruction from '../DriverRoute/instruction';
import ResoInstruction from '../DriverRoute/resoInstruction';
import DriverRouteSetting from '../DriverRoute/setting';
import DriverRedundant from '../DriverRoute/redundant';
import DriverManagerTest from '../DriverRouteTest';
import DriverRoleResoTest from '../DriverRouteTest/role-reso';
import DriverRoleNSTest from '../DriverRouteTest/role-ns';
import DriverRedundantTest from '../DriverRouteTest/redundant';
// import Website from '../Website';
import TestPage from '../Test';
import Marketing from '../NoshWheel';
import Verify from '../Verify';
import Jump from '../Home/Jump';
import PoolJump from '../Home/PoolJump';
import NoshSimpleInvitations from '../NoshSimple/invitations';
import NoshSimpleMenuTooling from '../NoshSimpleMenuTooling';
import ImagesUpload from '../ImagesUpload';
import NoshSimpleImagesUpload from '../NoshSimpleImagesUpload';

// nosh-simple
import NoshSimpleDriverManager from '../NoshSimpleDriverRoute';
import NoshSimpleDriver from '../NoshSimpleDriverRoute/driver';

const base = '/:locale(en|zh)?';

const Routes = (): JSX.Element => {
  const { isLoading } = useContext(UserContext);
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const routes: string[] = ['/', '/verify', '/waitlist', '/survey', '/promo'];

  const redirect = (path: string) => {
    if (history.location.pathname === path) {
      if (location.search && path === '/') {
        history.push(`/${i18n.language}/${location.search}`);
      } else {
        history.push(`/${i18n.language}${path}`);
      }
    }
  };

  if (routes.includes(history.location.pathname)) {
    redirect(history.location.pathname);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Switch>
        {/* <Route path={base} exact component={Website} /> */}
        <Route path={base} exact component={Home} />
        <Route path={`${base}/test`} component={TestPage} />
        <Route path={`${base}/cashback`} component={Marketing} />
        <Route path={`${base}/app/:platform`} strict exact component={App} />
        <Route path={`${base}/menu-share`} component={MenuShare} />
        <Route path={`${base}/restaurant-meals`} component={RestaurantMeals} />
        <Route path={`${base}/cookie/:hashKey`} component={Cookie} />
        <Route path={`${base}/crumbl/:hashKey`} component={Cookie} />
        <Route path={`${base}/saturday`} component={SaturdayPage} />
        <Route path={`${base}/referral-static`} component={ReferralStatic} />
        <Route path={`${base}/marketing`} component={Marketing} />
        <Route path={`${base}/lunch/tap-win`} component={Marketing} />
        <Route path={`${base}/ns/instruction`} component={Instruction} />
        <Route path={`${base}/reso/instruction`} component={ResoInstruction} />
        <Route path={`${base}/verify`} component={Verify} />
        <Route path={`${base}/t/jXNEfyzwKb`} component={Jump} />
        <Route path={`${base}/pool/:hashKey`} component={PoolJump} />
        <Route
          path={`${base}/marketing-entrance`}
          component={MarketingEntrance}
        />
        <Route path={`${base}/meal-detail`} component={MealDetail} />
        <Route
          path={`${base}/order-summary/:restaurantId/:orderType/:hashKey`}
          component={OrderSummary}
        />
        <Route
          path={`${base}/order-summary-reminder/:hashKey`}
          component={OrderSummaryReminder}
        />
        <Route
          path={`${base}/order-summary-the-day-reminder/:restaurantId/:hashKey`}
          component={TheDayReminder}
        />
        <Route path={`${base}/driver-image`} component={DriverImage} />

        {/* new driver shift */}
        <Route
          path={`${base}/driver/coordinator-view`}
          component={DriverManager}
        />
        <Route path={`${base}/driver/driver-amount`} component={DriverAmount} />
        <Route
          path={`${base}/driver/coordinator/:hashKey`}
          component={DriverManager}
        />
        <Route
          path={`${base}/driver/redundant/:hashKey`}
          component={DriverRedundant}
        />
        <Route path={`${base}/driver/role/:hashKey`} component={DriverRole} />
        <Route
          path={`${base}/driver/reso/:hashKey`}
          component={DriverRoleReso}
        />
        <Route path={`${base}/driver/ns/:hashKey`} component={DriverRoleNS} />
        <Route
          path={`${base}/driver/update/c056a39d4f`}
          component={DirverUpdate}
        />
        <Route
          path={`${base}/driver/setting/c086a39d4f`}
          component={DriverRouteSetting}
        />
        {/* test-link */}
        <Route
          path={`${base}/driver-test/coordinator/:hashKey`}
          component={DriverManagerTest}
        />
        <Route
          path={`${base}/driver-test/reso/:hashKey`}
          component={DriverRoleResoTest}
        />
        <Route
          path={`${base}/driver-test/ns/:hashKey`}
          component={DriverRoleNSTest}
        />
        <Route
          path={`${base}/driver-test/redundant/:hashKey`}
          component={DriverRedundantTest}
        />
        {/* ------- end ------- */}

        <Route path={`${base}/place-order`} component={PlaceOrder} />
        <Route path={`${base}/invite`} component={Invite} />
        <Route path={`${base}/i`} component={Invite} />
        <Route path={`${base}/reso-meals`} component={ResoMeals} />
        <Route path={`${base}/images-upload`} component={ImagesUpload} />
        <Route
          path={`${base}/nosh-simple-images-upload`}
          component={NoshSimpleImagesUpload}
        />
        <Route
          path={`${base}/website-links/44a5d7e9`}
          component={WebsiteLinks}
        />
        <Route
          path={`${base}/menu-tooling-v2/restaurants`}
          component={ScheduleRestaurants}
        />
        <Route path={`${base}/menu-tooling-v2/menu`} component={ScheduleMenu} />
        <Route
          path={`${base}/driver-route-update`}
          component={DirverRouteUpdate}
        />
        <Route path={`${base}/notices`} component={Notices} />
        <Route path={`${base}/policy`} component={Policy} />
        <Route path={`${base}/start`} component={Home} />
        <Route
          path={`${base}/nosh-simple/invitations`}
          component={NoshSimpleInvitations}
        />
        <Route
          path={`${base}/nosh-simple/menu-tooling`}
          component={NoshSimpleMenuTooling}
        />
        <Route
          path={`${base}/nosh-simple/coordinator/:hashKey`}
          component={NoshSimpleDriverManager}
        />
        <Route
          path={`${base}/nosh-simple/driver/:hashKey`}
          component={NoshSimpleDriver}
        />

        <Redirect from="*" to="/" />
      </Switch>
    </>
  );
};

export default Routes;
