import React, { FC } from 'react';
import styles from './meal-card.module.css';

export interface MealCardProps {
  meal: any;
  showClose: boolean;
  onClose?: () => void;
}

export const MealCard: FC<MealCardProps> = (props: MealCardProps) => {
  return (
    <div className={styles.mealItem2}>
      <div className={styles.mealContent}>
        {props.showClose && (
          <div className={styles.close} onClick={props.onClose}>
            &#215;
          </div>
        )}
        <img
          src={`${props.meal.imageLink}`}
          className={styles.mealImg}
          alt=""
        />
        <div className={styles.mealTitle}>
          <div className={styles.mealName}>{props.meal.title}</div>
          <div className={styles.mealPrice1}>${props.meal.price}</div>
        </div>
        <div className={styles.mealTags}>
          {!!props.meal.dietaryRestrictio.includes('Gluten-Free') && (
            <div className={`${styles.tag} ${styles.gluten}`}>
              <div className={styles.tagTxt}>GLUTEN-FREE</div>
            </div>
          )}
          {!!props.meal.dietaryRestrictio.includes('Vegetarian') && (
            <div className={`${styles.tag} ${styles.veg}`}>
              <div className={styles.tagTxt}>VEGETARIAN</div>
            </div>
          )}
          {!!props.meal.dietaryRestrictio.includes('Vegan') && (
            <div className={`${styles.tag} ${styles.vegan}`}>
              <div className={styles.tagTxt}>VEGAN</div>
            </div>
          )}
          {!!props.meal.dietaryRestrictio.includes('Dairy-Free') && (
            <div className={`${styles.tag} ${styles.dairy}`}>
              <div className={styles.tagTxt}>NO DAIRY</div>
            </div>
          )}
          {!!props.meal.dietaryRestrictio.includes('Keto') && (
            <div className={`${styles.tag} ${styles.keto}`}>
              <div className={styles.tagTxt}>KETO</div>
            </div>
          )}
          {!!props.meal.dietaryRestrictio.includes('Shellfish-Free') && (
            <div className={`${styles.tag} ${styles.shellfish}`}>
              <div className={styles.tagTxt}>NO SHELLFISH</div>
            </div>
          )}
          {!!props.meal.dietaryRestrictio.includes('Low-Calorie') && (
            <div className={`${styles.tag} ${styles.calorie}`}>
              <div className={styles.tagTxt}>LOW CALORIE</div>
            </div>
          )}
          {!!props.meal.dietaryRestrictio.includes('Low-Carb') && (
            <div className={`${styles.tag} ${styles.carb}`}>
              <div className={styles.tagTxt}>LOW CARB</div>
            </div>
          )}
          {!!props.meal.dietaryRestrictio.includes('High-Protein') && (
            <div className={`${styles.tag} ${styles.protein}`}>
              <div className={styles.tagTxt}>HIGH PROTEIN</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MealCard;
