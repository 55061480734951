import React, { useEffect, useRef, useState } from 'react';
import 'react-awesome-animated-number/dist/index.css';
import styles from './noshsimple-menu-tooling.module.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import { v4 } from 'uuid';
import MealCard from './components/MealCard';
import toast, { Toaster } from 'react-hot-toast';
import { Button, Select } from 'antd';
import Loading from '../Components/Loading';
import { SIMPLE_URL } from '../utils/Constants';

const { Option } = Select;

const NoshSimpleMenuTooling = (): JSX.Element => {
  const [menuLoading, setMenuLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [currentRotate, setCurrenRotate] = useState('');
  const [rotates] = useState(['0', '1', '2', '3']);
  const _rotates = useRef('0');
  const [weeksMenu, setWeeksMenu] = useState<any[]>([]);
  const [baseMeals, setBaseMeals] = useState<any[]>([]);
  const [meals, setMeals] = useState<any[]>([]);
  const _meals = useRef<any[]>([]);
  const [categoryArr] = useState([
    'Salad / Soups',
    'Sandwiches / Burger',
    'Entrees - Healthy',
    'Entrees - Wholesome',
    'Sides',
    'Family Pack',
    'By the Pound',
  ]);
  const [category, setCategory] = useState('Salad / Soups');
  const _category = useRef('Salad / Soups');

  useEffect(() => {
    (async () => {
      setMenuLoading(true);

      await getMeals();
      await getRotate();
      await getMenuByRotates();

      setMenuLoading(false);
    })();
  }, []);

  const getRotate = async () => {
    const { data } = await axios.get(`${SIMPLE_URL}/rotate`);
    setCurrenRotate(data.currentRotate);
    _rotates.current = data.currentRotate;
    // setRotates(data.allRotate);
    return data.currentRotate;
  };

  const getMeals = async () => {
    const { data } = await axios.get(`${SIMPLE_URL}/meals`);
    setBaseMeals(data.meals);
    setMeals(data.meals);
    _meals.current = data.meals;
  };

  const getMenuByRotates = async () => {
    setMenuLoading(true);
    const { data } = await axios.get(
      `${SIMPLE_URL}/schedule-days?rotateWeek=${_rotates.current}&category=${_category.current}`
    );
    setMenuLoading(false);
    if (data) {
      data.forEach((week) => {
        const meals: any[] = [];
        week.meals.forEach((m, i) => {
          const meal = _meals.current.find((v) => v.id === m);
          if (meal) {
            meals.push({
              ...meal,
              dropId: v4(),
              sort: i,
            });
          }
        });
        week.meals = meals;
      });
      setWeeksMenu(data);
    } else {
      setWeeksMenu([
        { rotate: _rotates.current, dayOfWeek: 1, meals: [] },
        { rotate: _rotates.current, dayOfWeek: 2, meals: [] },
        { rotate: _rotates.current, dayOfWeek: 3, meals: [] },
        { rotate: _rotates.current, dayOfWeek: 4, meals: [] },
        { rotate: _rotates.current, dayOfWeek: 5, meals: [] },
        { rotate: _rotates.current, dayOfWeek: 6, meals: [] },
        { rotate: _rotates.current, dayOfWeek: 7, meals: [] },
      ]);
    }
  };

  const saveWeeksMenu = async () => {
    const weekMenu = JSON.parse(JSON.stringify(weeksMenu));
    weekMenu.forEach((v) => {
      v.meals = v.meals.map((l) => {
        return {
          id: l.id,
          restaurantId: l.restaurantId,
          sort: l.sort,
        };
      });
    });
    setSaveLoading(true);
    const { data } = await axios.post(`${SIMPLE_URL}/schedule-days/save`, {
      rotateWeek: currentRotate,
      weekMenu,
      category: _category.current,
    });
    setSaveLoading(false);
    if (data.status === 'success') {
      toast.success(`Weeks menu saved!`);
    } else {
      toast.error(data.message);
    }
  };

  const SearchMealContainer = () => {
    return (
      <div className={styles.leftContainer}>
        <div className={styles.leftHead}>
          <div className={styles.resoName}>Meals</div>
          <input
            className={styles.input}
            onChange={(e) => {
              const m = baseMeals.filter(
                (v) =>
                  !!v.title && v.title.toLowerCase().includes(e.target.value)
              );
              setMeals(m);
            }}
          />
        </div>
        <div className={styles.leftMealList}>
          <SearchedMealList />
        </div>
      </div>
    );
  };

  const SearchedMealList = () => {
    return (
      <Droppable droppableId="searchMeals" isDropDisabled={true}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {meals.map((meal: any, index) => (
              <Draggable key={meal.id} draggableId={`${meal.id}`} index={index}>
                {(provided, snapshot) => (
                  <React.Fragment>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                    >
                      <MealCard meal={meal} showClose={false} />
                    </div>
                    {snapshot.isDragging && (
                      <div className="react-beatiful-dnd-copy">
                        <MealCard meal={meal} showClose={false} />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    );
  };

  const HeadMenu = () => {
    return (
      <div className={styles.rightHead}>
        <div className={styles.rightHeadTxt}>
          Current Promo Week
          <input
            className={styles.input}
            style={{ width: 50, marginLeft: 10, marginTop: 10 }}
            disabled
            value={currentRotate}
            onChange={(e) => setCurrenRotate(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', paddingRight: 10 }}>
          <div style={{ fontSize: 13 }}>
            Category:
            <Select
              defaultValue={category}
              style={{ width: 200, marginRight: 10, marginLeft: 10 }}
              onChange={(e) => {
                _category.current = e;
                setCategory(e);
                getMenuByRotates();
              }}
            >
              {categoryArr.map((v) => (
                <Option value={v} key={v}>
                  {v}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ fontSize: 13 }}>
            Promo Weeks:
            {currentRotate !== '' && (
              <Select
                defaultValue={currentRotate}
                style={{ width: 80, marginRight: 10, marginLeft: 10 }}
                onChange={(e) => {
                  _rotates.current = e;
                  setCurrenRotate(e);
                  getMenuByRotates();
                }}
              >
                {rotates.map((v) => (
                  <Option value={v} key={v}>
                    {v}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          <Button
            type="primary"
            danger
            loading={saveLoading}
            style={{ borderRadius: 4 }}
            onClick={() => {
              saveWeeksMenu();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  const MenuList = () => {
    return (
      <div className={styles.droppable}>
        {weeksMenu.map((menu, index) => {
          return (
            <React.Fragment key={index}>
              <div className={styles.dropList}>
                <div className={styles.listDate}>
                  <div className={styles.listDateRow}>
                    <div className={styles.promoWeek}>
                      <span style={{ fontWeight: 'normal' }}>promo week:</span>
                      {menu.rotate}
                    </div>
                    <div>
                      <span style={{ fontWeight: 'normal' }}>day: </span>
                      {menu.dayOfWeek}
                    </div>
                  </div>
                </div>
                <Droppable droppableId={`drop-${index}`} key={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={styles.mealList}
                    >
                      <div>
                        {!!menu.meals &&
                          menu.meals.map((m, inx) => (
                            <Draggable
                              key={m.dropId}
                              draggableId={`${m.dropId}`}
                              index={inx}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <MealCard
                                    meal={m}
                                    showClose
                                    onClose={() => removeMeal(index, inx)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    const [, dMenuInd] = destination.droppableId.split('-');
    const dTargetMenu = weeksMenu[dMenuInd].meals;
    const [, sMenuInd] =
      source.droppableId === 'searchMeals'
        ? [0, 0]
        : source.droppableId.split('-');
    const sTargetMenu = weeksMenu[sMenuInd].meals;

    // 同一列互换位置
    if (sInd === dInd) {
      const swapedList = swap(dTargetMenu, source.index, destination.index);
      dTargetMenu.meals = swapedList;
      return;
    }

    // 待放置的位置是否有相同的菜
    const mealId =
      source.droppableId === 'searchMeals'
        ? meals[source.index].id
        : sTargetMenu[source.index].id;
    const hasMeal = dTargetMenu.find((v) => v.id === mealId);
    if (hasMeal) {
      return;
    }

    // 从查询列表复制一份到目标列
    if (source.droppableId === 'searchMeals') {
      const meal = meals[source.index];
      dTargetMenu.splice(destination.index, 0, {
        ...meal,
        dropId: v4(),
      });
      return;
    }

    // 不同列直接调换
    const [removed] = sTargetMenu.splice(source.index, 1);
    dTargetMenu.splice(destination.index, 0, removed);
  };

  const swap = (list: any[], startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  const sortMeals = (weekMenu: any) => {
    weekMenu.forEach((w) => {
      w.meals.forEach((m, i) => {
        m.sort = i;
      });
    });
  };

  const removeMeal = (index, rIndex) => {
    weeksMenu[index].meals.splice(rIndex, 1);
    sortMeals(weeksMenu);
    setWeeksMenu([...weeksMenu]);
  };

  return (
    <DragDropContext
      onDragEnd={(e) => {
        onDragEnd(e);
        sortMeals(weeksMenu);
        setWeeksMenu([...weeksMenu]);
      }}
    >
      <Toaster position="top-center" />
      <div className={styles.container}>
        {SearchMealContainer()}
        <div className={styles.rightContainer}>
          {HeadMenu()}
          <MenuList />
        </div>
      </div>
      {menuLoading && (
        <div className={styles.mask}>
          <Loading />
        </div>
      )}
    </DragDropContext>
  );
};

export default NoshSimpleMenuTooling;
