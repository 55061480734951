import React, { useEffect, useState } from 'react';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import 'react-awesome-animated-number/dist/index.css';
import styles from './menu-tooling-v2.module.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Popover, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { v4 } from 'uuid';
import MealCard from './components/MealCard';
import toast, { Toaster } from 'react-hot-toast';
import { Button, Select } from 'antd';
import { BASE_URL } from '../utils/Constants';

const { Option } = Select;

export type MealType = {
  id: string;
  title: string;
  tags: string[];
  protein: string;
  description: string;
  imgFilename: string;
  firebaseImgFilename: string;
  rate: string | null;
  reviewPortion: string;
  restaurantId: string;
  restaurantName: string;
  dropId?: string;
  mealTypeId?: string;
  doordashPrice: number;
  isOriginalPrice: boolean;
  estimatedMealPrice: number;
};

const mealGroupType = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

const ScheduleRestaurants = (): JSX.Element => {
  const [searchedMeals, setSearchedMeals] = useState<MealType[]>([]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [ratings, setRatings] = useState<any[]>([]);

  const url = BASE_URL;
  // const url = 'http://localhost:8000/api';

  useEffect(() => {
    getRestaurants();
    getMealsRatings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRestaurants = async () => {
    const { data } = await axios.get(`${url}/restaurants/list`);
    const reso = data.data;
    reso.sort((a, b) => {
      return `${a.title}`.localeCompare(b.title);
    });
    setRestaurants(reso);
  };

  const getMealsRatings = async () => {
    const { data } = await axios.get(`${url}/meals/all-meals-ratings`);
    setRatings(data);
  };

  const searchMeal = async (resoId: string) => {
    const { data } = await axios.get(
      `${url}/meal-by-restaurant?restaurantId=${resoId}`
    );
    const meals = data;
    meals.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
    setSearchedMeals(meals);

    const res = await axios.get(
      `${url}/schedules-restaurant/get?restaurantId=${resoId}`
    );
    if (res.data) {
      const m = JSON.parse(res.data.schedules);
      const s: unknown[] = [];
      m.forEach((v) => {
        const mm: unknown[] = [];
        v.meals.forEach((l) => {
          const mealInfo = meals.find((o) => o.id === l);
          if (mealInfo) {
            mm.push({
              ...mealInfo,
              dropId: v4(),
            });
          }
        });

        const warns = checkMenuRules(mm);
        s.push({
          type: v.type,
          meals: mm,
          warns,
        });
      });
      sortMealType(s);
      setSchedules(s);
    } else {
      setSchedules([]);
    }
  };

  const checkMenuRules = (meals: any) => {
    let warns = '';
    meals.forEach((v) => {
      const goodRatings = ratings.filter(
        (o) => o.Order.MealId === v.id && o.mealRating === 'LIKE'
      );
      const badRatings = ratings.filter(
        (o) => o.Order.MealId === v.id && o.mealRating === 'DISLIKE'
      );
      const ratingPercent =
        (goodRatings.length / (goodRatings.length + badRatings.length)) * 100;
      warns = `${
        goodRatings.length + badRatings.length >= 10 && ratingPercent < 50
          ? '❌'
          : '✅'
      } Do not display any dish with a 50% or below total review\n`;
    });

    const firstStandard = meals.findIndex(
      (v) => v.reviewPortion === 'standard'
    );
    const firstBig = meals.findIndex((v) => v.reviewPortion === 'big');
    const firstLight = meals.findIndex((v) => v.reviewPortion === 'light');
    const flag =
      firstStandard < firstBig ||
      firstLight < firstBig ||
      firstLight < firstStandard;

    warns += `${
      flag ? '❌' : '✅'
    } show larger portion size dishes before smaller.\n`;

    const salesMeals = meals.filter((v) => v.estimatedMealPrice === 9.99);
    const marketingMeals = meals.filter((v) => v.estimatedMealPrice !== 9.99);
    warns += `${
      salesMeals.length && marketingMeals.length ? '✅' : '❌'
    } both types of dishes must be present for EACH restaurant.\n`;

    const scale = salesMeals.length / marketingMeals.length;
    warns += `${
      scale === 0.5 || scale === 1 ? '✅' : '❌'
    } "$9.99 fixed price lunch special" vs. "Carve-Out $0 fees in-store prices" dishes balance should be either 2:4 or 3:3.\n`;

    return warns;
  };

  const saveRestaurantSchedule = async () => {
    if (!schedules.length) {
      toast.error(`No meals has been save`);
      return;
    }

    const reqData = schedules.map((v) => {
      return {
        type: v.type,
        meals: v.meals.map((o) => o.id),
      };
    });

    setSaveLoading(true);
    const { data } = await axios.post(`${url}/schedules-restaurant/save`, {
      restaurantId,
      schedules: reqData,
    });
    setSaveLoading(false);
    if (data) {
      toast.success(`Schedule saved!`);
    } else {
      toast.error(data.message);
    }
  };

  const SearchMealContainer = () => {
    return (
      <div className={styles.leftContainer2}>
        <div className={styles.leftHead}>
          <div className={styles.resoName}>Restaurant</div>
          <Select
            defaultValue=""
            style={{
              width: 240,
              marginRight: 10,
              marginLeft: 10,
              marginBottom: 10,
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                !!option!.children &&
                ((option!.children as unknown) as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              );
            }}
            onChange={async (e) => {
              setLoading(true);
              setRestaurantId(e);
              await searchMeal(e);
              setLoading(false);
            }}
          >
            {restaurants.map((v, i) => {
              return (
                <Option value={v.id} key={i}>
                  {v.title}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className={styles.leftMealList}>
          {loading && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
          {SearchedMealList()}
        </div>
      </div>
    );
  };

  const SearchedMealList = () => {
    return (
      <Droppable droppableId="searchMeals" isDropDisabled={true}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {searchedMeals.map((meal: MealType, index) => (
              <Draggable key={meal.id} draggableId={`${meal.id}`} index={index}>
                {(provided, snapshot) => (
                  <React.Fragment>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                    >
                      <MealCard meal={meal} showClose={false} />
                    </div>
                    {snapshot.isDragging && (
                      <div className="react-beatiful-dnd-copy">
                        <MealCard meal={meal} showClose={false} />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    );
  };

  const MenuList = () => {
    return (
      <div className={styles.droppable2}>
        {schedules.map((v, i) => {
          return (
            <React.Fragment key={i}>
              <div className={styles.dropList2} key={i}>
                <div className={styles.listDate}>
                  <div className={styles.listDateRow}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ fontWeight: 'normal' }}>type:</span>
                      <span>{v.type}</span>
                      {!!v.warns && (
                        <div>
                          <Popover
                            content={
                              <div style={{ width: 300 }}>
                                <p>
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    {v.warns}
                                  </span>
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleOutlined
                              style={{
                                color: 'red',
                                marginLeft: 10,
                                marginBottom: 8,
                              }}
                            />
                          </Popover>
                        </div>
                      )}
                    </div>
                    <div
                      className={styles.close}
                      onClick={() => {
                        schedules.splice(i, 1);
                        schedules.forEach((v, i) => {
                          v.type = mealGroupType[i];
                        });
                        setSchedules([...schedules]);
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                  </div>
                </div>
                <Droppable
                  droppableId={`group-${i}`}
                  key={i}
                  direction="horizontal"
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={styles.mealList2}
                    >
                      {v.meals.map((meal, inx) => (
                        <Draggable
                          key={meal.dropId}
                          draggableId={`${meal.dropId}`}
                          index={inx}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <MealCard
                                meal={meal}
                                showClose
                                onClose={() => removeMeal(i, inx)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const removeMeal = (index, mealIndex) => {
    schedules[index].meals.splice(mealIndex, 1);
    sortMealType(schedules);
    setSchedules([...schedules]);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    const [, dTypeInd] = destination.droppableId.split('-');
    const dTargetMenu = schedules[dTypeInd].meals;
    const [, sTypeInd] =
      source.droppableId === 'searchMeals'
        ? [0, 0]
        : source.droppableId.split('-');
    const sTargetMenu = schedules[sTypeInd].meals;
    // 同一列互换位置
    if (sInd === dInd) {
      const swapedList = swap(dTargetMenu, source.index, destination.index);
      dTargetMenu.meals = swapedList;
      return;
    }

    // 待放置的位置是否有相同的菜
    const mealId =
      source.droppableId === 'searchMeals'
        ? searchedMeals[source.index].id
        : sTargetMenu[source.index].id;
    const hasMeal = dTargetMenu.find((v) => v.id === mealId);
    if (hasMeal) return;

    // 从查询列表复制一份到目标列
    if (source.droppableId === 'searchMeals') {
      const meal = searchedMeals[source.index];
      dTargetMenu.splice(destination.index, 0, {
        ...meal,
        dropId: v4(),
      });
      return;
    }

    // 不同列直接调换
    const [removed] = sTargetMenu.splice(source.index, 1);
    dTargetMenu.splice(destination.index, 0, removed);
  };

  const swap = (list: MealType[], startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  const sortMealType = (mealGroup = schedules) => {
    mealGroup.forEach((group) => {
      group.meals.forEach((meal, i) => {
        meal.mealTypeId = i + 1;
      });
    });
  };

  const HeadMenu = () => {
    return (
      <div className={styles.rightHead}>
        <div className={styles.rightHeadTxt}></div>
        <div style={{ display: 'flex', paddingRight: 10 }}>
          <Button
            type="primary"
            style={{ borderRadius: 4, marginRight: 10 }}
            onClick={() => {
              if (schedules.length >= 10) return;
              schedules.push({
                type: '',
                meals: [],
              });
              schedules.forEach((v, i) => {
                v.type = mealGroupType[i];
              });
              setSchedules([...schedules]);
            }}
          >
            Add
          </Button>
          <Button
            type="primary"
            danger
            loading={saveLoading}
            style={{ borderRadius: 4 }}
            onClick={() => {
              saveRestaurantSchedule();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <DragDropContext
      onDragEnd={(e) => {
        onDragEnd(e);
        sortMealType();
      }}
    >
      <Toaster position="top-center" />
      {/* add rotates */}
      <div className={styles.container}>
        {SearchMealContainer()}
        <div className={styles.rightContainer}>
          {HeadMenu()}
          {MenuList()}
        </div>
      </div>
    </DragDropContext>
  );
};

export default ScheduleRestaurants;
