import React, { useState } from 'react';
import styles from './invitations.module.css';
import 'react-awesome-animated-number/dist/index.css';
import { getMobileOrPC, SIMPLE_URL } from '../utils/Constants';
import axios from 'axios';
import { JsonToTable } from 'react-json-to-table';
import { Spin } from 'antd';

const Invitations = (): JSX.Element => {
  const isPC = getMobileOrPC();
  const [referralValue, setReferralValue] = useState('');
  const [result, setResult] = useState<any>();
  const [loading, setLoading] = useState(false);

  const search = async () => {
    setLoading(true);
    const { data } = await axios.get(
      `${SIMPLE_URL}/waitlist/invitations-by-referral?referralValue=${referralValue}`
    );
    setResult(data);
    setLoading(false);
  };

  return (
    <div
      className={styles.container}
      style={{
        maxWidth: isPC ? '450px' : '50000px',
      }}
    >
      <div className={styles.row}>
        <input
          className={styles.input}
          placeholder="Enter ReferralValue"
          value={referralValue}
          onChange={(e) => setReferralValue(e.target.value)}
        />
        <div
          className={styles.btn}
          onClick={() => {
            search();
          }}
        >
          search
        </div>
      </div>
      {loading && <Spin size="large" className={styles.loading} />}
      <div className={styles.row} style={{ marginTop: 40 }}>
        <div style={{ width: 450, overflowX: 'auto' }}>
          <JsonToTable json={result} />
        </div>
      </div>
    </div>
  );
};

export default Invitations;
