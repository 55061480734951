import moment, { Moment } from 'moment-timezone';
import TimeConstants from './TimeContants';

export const getNextOrderWindow = (
  currentTime: Moment,
  holidays: string[],
  isExpress: boolean
): Moment => {
  let date = currentTime.clone();
  if (date.isAfter(getCutoffTime(currentTime, isExpress))) {
    date = date.add({ day: 1 });
  }
  return accountForNonWorkDays(date, holidays);
};

export const accountForNonWorkDays = (
  date: Moment,
  holidays: string[]
): Moment => {
  let dd = date;
  while (isHolidayOrWeekend(date, holidays)) {
    dd = dd.add({ day: 1 });
    dd.set({
      hour: TimeConstants.EarlyPickupTime().get('hour'),
      minute: TimeConstants.EarlyPickupTime().get('minute'),
    });
  }

  return dd;
};

export const isHolidayOrWeekend = (
  time: moment.Moment,
  holidays: string[]
): boolean => {
  const day = time.day();
  let isHoliday = day === 0;
  holidays.forEach((holidayStr) => {
    const holiday = moment(holidayStr);
    isHoliday = isHoliday || holiday.isSame(time, 'day');
  });
  return isHoliday;
};

export const getCutoffTime = (current: Moment, isExpress: boolean) => {
  return isExpress
    ? TimeConstants.LunchExpressReservationCutoffTime(current)
    : TimeConstants.PUCutoffTime(current);
};
