import {
  BarsOutlined,
  CommentOutlined,
  DollarOutlined,
  HeartOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './mealdetail.css';

const MealDetail = (): JSX.Element => {
  const history = useHistory();
  const url = 'https://backend.noshpass.com/api';
  const googlePlay =
    'https://play.google.com/store/apps/details?id=com.ricepass.mobile.nosh&referrer=micro-site';
  const appStore =
    'https://apps.apple.com/us/app/noshpass-lunch-express/id1590803053';
  const params = new URLSearchParams(useLocation().search);
  const userAgentInfo = navigator.userAgent;

  const [mealDetail, setMealDetail] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isPC, setIsPc] = useState(true);

  useEffect(() => {
    const Agents = [
      'Android',
      'iPhone',
      'SymbianOS',
      'Windows Phone',
      'iPad',
      'iPod',
    ];
    let flag = true;
    for (const item of Agents) {
      if (userAgentInfo.indexOf(item) > 0) {
        flag = false;
        break;
      }
    }
    setIsPc(flag);
    getMealDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getMealDetail = async () => {
    setLoading(true);
    const { data } = await axios.get(`${url}/meals/detail/${params.get('id')}`);
    setMealDetail(data);
    setLoading(false);
  };

  return (
    <div>
      {!mealDetail ? (
        <></>
      ) : (
        <div
          className="container-detail"
          style={{ maxWidth: isPC ? '450px' : '50000px' }}
        >
          <div className="meal-back" onClick={() => history.go(-1)}>
            <LeftOutlined style={{ color: '#FFFFFF' }} />
          </div>
          <img
            className="m-head-img"
            alt=""
            src={mealDetail.firebaseImgFilename}
          />
          <div className="m-title">
            <div className="m-meal-title">{mealDetail.title}</div>
            <div className="m-meal-resturant">
              {mealDetail.Restaurant.title}
              {!!mealDetail.tagsEn.length && (
                <div className="meal-cusine">{mealDetail.tagsEn[0]}</div>
              )}
            </div>
          </div>
          <div className="meal-section">
            <div className="meal-detail-section-title">
              <BarsOutlined />
              <div className="sectiom-title-txt">Details</div>
            </div>
            <div className="section-content">{mealDetail.description}</div>
          </div>
          {!mealDetail.isOriginalPrice && (
            <div className="meal-section">
              <div className="meal-detail-section-title">
                <DollarOutlined />
                <div className="sectiom-title-txt">Cost</div>
              </div>
              <div className="section-content flexrow">
                <div className="meal-cost">
                  <img
                    className="cost-img"
                    alt=""
                    src={require('../assets/images/menu-share/bg_left_tip.png')}
                  />
                  <div className="cost-title">Market Price</div>
                  <div className="cost-meal">
                    <span className="cost-pay base-pay">
                      ${mealDetail.estimatedMealPrice.toFixed(2)}{' '}
                    </span>
                    <span>/lunch</span>
                  </div>
                </div>
                <div className="meal-cost2">
                  <img
                    className="cost-img2"
                    alt=""
                    src={require('../assets/images/menu-share/bg_right_tip.png')}
                  />
                  <div className="cost-title2">You Pay</div>
                  <div className="cost-meal">
                    <span className="cost-pay">1</span>
                    <img
                      className="cost-credit"
                      alt=""
                      src={require('../assets/images/menu-share/1credit.png')}
                    />
                    Credit
                  </div>
                </div>
              </div>
              <div className="meal-cost3">
                <div>
                  As low as <span className="meal-cost4">$8.99/credit</span>
                </div>
              </div>
            </div>
          )}
          <div className="meal-section">
            <div className="meal-detail-section-title">
              <HeartOutlined />
              <div className="sectiom-title-txt">Restaurant Story</div>
            </div>
            <div className="section-content">
              {mealDetail.Restaurant.description}
            </div>
          </div>
          {(mealDetail.reviewEn?.length || 0) > 30 && (
            <div className="meal-section">
              <div className="meal-detail-section-title">
                <CommentOutlined />
                <div className="sectiom-title-txt">NoshPass Says</div>
              </div>
              <div className="section-content">{mealDetail.reviewEn}</div>
            </div>
          )}
          <div style={{ width: '100%', height: '70px' }}></div>
          <div
            className="meal-bottom"
            style={{ width: isPC ? '450px' : '100%' }}
          >
            <div
              className="meal-bottom-btn"
              onClick={() => {
                const u = navigator.userAgent;
                if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
                  window.open(googlePlay);
                } else {
                  window.open(appStore);
                }
              }}
            >
              Download and Reserve
              <RightOutlined className="rightOutline" />
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="loading">
          <Spin
            spinning={true}
            delay={100}
            size="large"
            className="spin"
          ></Spin>
        </div>
      )}
    </div>
  );
};

export default MealDetail;
