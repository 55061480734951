import React from 'react';
import './noservice.css';

const HolidayNoService = (): JSX.Element => {
  return (
    <div className="no-service">
      <img
        className="holiday-img"
        alt=""
        src={require('../assets/images/menu-share/holiday.png')}
      />
      <div className="happy-holiday">We’re off today!</div>

      <div>
        Enjoy your day off. Return soon for more deliveries without the fees.{' '}
        <span role="img" aria-label="hurray">
          🎉
        </span>
        .
      </div>
    </div>
  );
};

export default HolidayNoService;
