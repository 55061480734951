import Axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { JsonToTable } from 'react-json-to-table';
import AndroidDownload from '../../assets/images/android_download.png';
import IOSDownload from '../../assets/images/ios_download.png';
import styles from '../driver.module.scss';
import AppButton from './appButton';
import toast, { Toaster } from 'react-hot-toast';
import { Collapse } from 'antd';

const Restaurant = (props: {
  driverRoutes: any;
  isRedundant?: boolean;
}): JSX.Element => {
  const { Panel } = Collapse;
  const url = 'https://noshinc.com/api';
  const [shiftLoading, setShiftLoading] = useState(false);
  const [driverRoutes, setDriverRoutes] = useState(props.driverRoutes);

  const convertKeys = (data) => {
    return _.mapKeys(data, (v, key) => _.startCase(_.lowerCase(key)));
  };

  const editShift = async (hashKey: string, type: string) => {
    if (window.confirm(`Are you sure to confirm it?`)) {
      setShiftLoading(true);
      const { data } = await Axios.post(`${url}/driver/routes/shift/edit`, {
        hashKey,
        type,
      });
      setShiftLoading(false);
      if (!data) {
        toast.error(`Someting wrong, Please try again`);
        return;
      }
      const item = driverRoutes.find((v) => v.hashKey === hashKey);
      item[type] = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
      setDriverRoutes([...driverRoutes]);
    }
  };

  const editUhual = async (d: any, type: string) => {
    if (!!d.endTime || !!d[`${type}`]) return;

    if (window.confirm(`Are you sure to confirm it?`)) {
      const loading =
        type === 'u1ArrivedTime' ? 'u1ArrivedLoading' : 'u1LeftLoading';
      d[loading] = true;
      setDriverRoutes([...driverRoutes]);
      const { data } = await Axios.post(`${url}/driver/routes/shift/edit`, {
        hashKey: d.hashKey,
        type,
      });
      if (!data) {
        d[loading] = false;
        setDriverRoutes([...driverRoutes]);
        toast.error(`Someting wrong, Please try again`);
        return;
      }

      d[`${type}`] = moment.tz('US/Central').format('YYYY-MM-DD HH:mm:ss');
      d[loading] = false;
      setDriverRoutes([...driverRoutes]);
    }
  };

  const editSite = async (d: any, reso: any, type: string) => {
    if (!!d.endTime || !!reso[`${type}Time`]) return;

    if (window.confirm(`Are you sure to confirm it?`)) {
      reso[`${type}Loading`] = true;
      setDriverRoutes([...driverRoutes]);

      const { data } = await Axios.post(`${url}/driver/routes/site/edit`, {
        hashKey: d.hashKey,
        id: reso.id,
        editType: `${type}Time`,
        siteType: 'reso',
      });
      if (!data) {
        toast.error(`Someting wrong, Please try again`);
        reso[`${type}Loading`] = false;
        setDriverRoutes([...driverRoutes]);
        return;
      }

      reso[`${type}Time`] = moment
        .tz('US/Central')
        .format('YYYY-MM-DD HH:mm:ss');
      reso[`${type}Loading`] = false;
      setDriverRoutes([...driverRoutes]);
    }
  };

  const editText = (d: any, reso: any, type: string) => {
    if (!!d.endTime)
      return reso[`${type}Time`]
        ? `${type.toUpperCase()} (${moment
            .tz(reso[`${type}Time`], 'US/Central')
            .format('HH:mm')})`
        : type.toUpperCase();

    return reso[`${type}Time`]
      ? `${type.toUpperCase()} (${moment
          .tz(reso[`${type}Time`], 'US/Central')
          .format('HH:mm')})`
      : type.toUpperCase();
  };

  const editClass = (d: any, reso: any, type: string) => {
    if (!!d.endTime && !!reso[`${type}Time`]) {
      if (type === 'delay') {
        return `${styles.arrvieBtn} ${styles.timeOut}`;
      }

      if (type === 'arrived' || type === 'left') {
        const isTimeOut = moment.tz(reso[`${type}Time`], 'US/Central').isAfter(
          moment.tz(d.date, 'US/Central').set({
            hour: 11,
            minute: type === 'arrived' ? 10 : 50,
          })
        );

        return `${styles.arrvieBtn} ${
          isTimeOut ? styles.timeOut : styles.onTime
        }`;
      }
    }

    if (!!d.endTime) {
      return styles.inactiveBtn;
    }

    const style = {
      delay: styles.anticipateBtn,
      arrived: styles.arrvieBtn,
      left: styles.leftBtn,
    }[type];

    return style;
  };

  const uhualText = (d: any, type: string) => {
    // `u1${type}Time`: u1ArrivedTime or u1LeftTime
    if (!!d.endTime)
      return d[`u1${type}Time`]
        ? `${type.toUpperCase()} (${moment
            .tz(d[`u1${type}Time`], 'US/Central')
            .format('HH:mm')})`
        : '';

    return d[`u1${type}Time`]
      ? `${type.toUpperCase()} (${moment
          .tz(d[`u1${type}Time`], 'US/Central')
          .format('HH:mm')})`
      : type.toUpperCase();
  };

  const uhualClass = (d: any, type: string) => {
    if (!!d.endTime || !!d[`u1${type}Time`]) {
      const isTimeOut = moment.tz(d[`u1${type}Time`], 'US/Central').isAfter(
        moment.tz(d.date, 'US/Central').set({
          hour: 10,
          minute: type === 'Arrive' ? 45 : 50,
        })
      );

      return `${styles.uhualBtn} ${isTimeOut ? styles.timeOut : styles.onTime}`;
    }

    const style = {
      Arrived: styles.uhualBtn,
      Left: styles.uhualLeftBtn,
    }[type];

    return style;
  };

  return (
    <>
      <Toaster position="top-center" />
      {driverRoutes.map((d, i) => {
        const s = d.orderInRestaurant
          .map((o) => o.Order[0].label?.slice(0, 1))
          .join(',')
          .trim();
        return (
          <div key={i}>
            {props.isRedundant ? (
              <h3 className={styles.h3}>
                Redundant Driver Shift Details [
                {moment.tz(d.date, 'US/Central').format('MMM DD')}]
              </h3>
            ) : (
              <h3 className={styles.h3}>
                Driver Shift Details <br />
                [RP#{s ? ` ${s}` : ''}] [
                {moment.tz(d.date, 'US/Central').format('MMM DD')}]
              </h3>
            )}

            {/* start/end shift */}
            {!d.startTime && !props.isRedundant && (
              <AppButton
                text="START SHIFT"
                className={styles.startShift}
                loading={shiftLoading}
                onClick={() => editShift(d.hashKey, 'startTime')}
              />
            )}
            {/* {!!d.startTime && !d.endTime && (
              <AppButton
                text="END SHIFT"
                className={styles.endShift}
                loading={shiftLoading}
                onClick={() => editShift(d.hashKey, 'endTime')}
              />
            )} */}

            {!!d.loginDetails && (
              <div className={styles.section}>
                <div className={styles.sectionTitle}>TMS Login Details</div>
                <JsonToTable
                  json={convertKeys(
                    _.omit(d.loginDetails, 'appUrl', 'checkinToken')
                  )}
                />
                <div className={styles.downloadBtnContainer}>
                  <img
                    src={IOSDownload}
                    className={styles.downloadAppBtn}
                    alt=""
                    onClick={() => window.open(d.loginDetails.appUrl.IOS)}
                  />
                  <img
                    src={AndroidDownload}
                    className={styles.downloadAppBtn}
                    alt=""
                    onClick={() => window.open(d.loginDetails.appUrl.Android)}
                  />
                </div>
              </div>
            )}

            {/* uhual */}
            <div className={styles.section}>
              <div className={styles.sectionTitle}>UHUAL</div>
              {!!d.startTime && (
                <div className={styles.nsBtnList}>
                  <AppButton
                    text={uhualText(d, 'Arrived')}
                    className={uhualClass(d, 'Arrived')}
                    loading={d.u1ArrivedLoading}
                    onClick={() => editUhual(d, 'u1ArrivedTime')}
                  />
                  <AppButton
                    text={uhualText(d, 'Left')}
                    className={uhualClass(d, 'Left')}
                    loading={d.u1LeftLoading}
                    onClick={() => editUhual(d, 'u1LeftTime')}
                  />
                </div>
              )}
            </div>

            {/* resturant */}
            {!d.orderInRestaurant.length && (
              <div className={styles.section}>
                <div className={styles.sectionTitle}>
                  Orders to Collect from Restaurant
                </div>
                No Order Yet
              </div>
            )}
            {!!d.orderInRestaurant.length &&
              d.orderInRestaurant.map((v, inx) => {
                const resoJson = JSON.parse(JSON.stringify(v));
                delete resoJson.id;
                delete resoJson.drivingUhaul;
                delete resoJson.isCluster;
                delete resoJson.arrivedTime;
                delete resoJson.leftTime;
                delete resoJson.arrivedLoading;
                delete resoJson.leftLoading;
                delete resoJson.delayLoading;
                delete resoJson.delayTime;

                resoJson.restaurantPhone = resoJson.restaurantPhone.split(',');
                resoJson.restaurantPhone.length = 1;
                resoJson.restaurantPhone = resoJson.restaurantPhone.join(',');
                resoJson.Order.forEach((o) => {
                  delete o.deliveryStatus;
                  delete o.name;
                  delete o.phone;
                });
                return (
                  <div
                    className={styles.section}
                    key={inx}
                    style={{ padding: 0 }}
                  >
                    <Collapse
                      bordered={false}
                      ghost
                      expandIconPosition="right"
                      style={{ color: 'red' }}
                    >
                      <Panel
                        header={
                          <div style={{ color: '#f06a20' }}>
                            RESTAURANT {inx + 1}
                          </div>
                        }
                        key={inx}
                        className={styles.sectionTitle}
                      >
                        {!!d.startTime && (
                          <div className={styles.btnList}>
                            <AppButton
                              text={editText(d, v, 'arrived')}
                              className={editClass(d, v, 'arrived')}
                              loading={v.arrivedLoading}
                              onClick={() => editSite(d, v, 'arrived')}
                            />
                            <AppButton
                              text={editText(d, v, 'left')}
                              className={editClass(d, v, 'left')}
                              loading={v.leftLoading}
                              onClick={() => editSite(d, v, 'left')}
                            />
                            <AppButton
                              text={editText(d, v, 'delay')}
                              className={editClass(d, v, 'delay')}
                              loading={v.delayLoading}
                              onClick={() => editSite(d, v, 'delay')}
                            />
                          </div>
                        )}
                        <JsonToTable json={convertKeys(resoJson)} />
                      </Panel>
                    </Collapse>
                  </div>
                );
              })}
            <hr />
          </div>
        );
      })}
    </>
  );
};

export default Restaurant;
