import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './styles.css';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import NoInServiceArea from './NoInServiceArea';
import amplitude from 'amplitude-js';
import {
  appStore,
  BASE_URL,
  getMobileOrPC,
  googlePlay,
} from '../utils/Constants';

const SaturdayPage = (): JSX.Element => {
  const [mealList, setMealList] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  const isPC = getMobileOrPC();

  const url = BASE_URL;
  // const url = 'http://localhost:8000/api';

  const history = useHistory();

  useEffect(() => {
    refreshMenu();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshMenu = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${url}/saturday-menu`);
      setMealList(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const gotoAppStore = (position: 'header' | 'footer') => {
    return (
      <div className="app-store">
        <img
          className="app-store-img"
          onClick={() => {
            amplitude
              .getInstance()
              .logEvent(
                position === 'header'
                  ? 'home_screen_header_google'
                  : 'home_screen_footer_google'
              );
            window.open(googlePlay);
          }}
          alt="google-play"
          src={require('../assets/images/menu-share/google-play.png')}
        />
        <img
          className="app-store-img"
          onClick={() => {
            amplitude
              .getInstance()
              .logEvent(
                position === 'header'
                  ? 'home_screen_header_apple'
                  : 'home_screen_footer_apple'
              );
            window.open(appStore);
          }}
          alt="app-store"
          src={require('../assets/images/menu-share/app-store.png')}
        />
      </div>
    );
  };

  const mealTagComponents = (tags, type: string) => {
    return (
      !!tags &&
      Array.isArray(tags) &&
      tags.includes(type) && (
        <span className="protion">
          <img
            className="card-portion"
            alt=""
            src={require(`../assets/images/menu-share/${type.toLowerCase()}.png`)}
          />
        </span>
      )
    );
  };

  return (
    <div
      className="container-share"
      style={{ maxWidth: isPC ? '450px' : '50000px' }}
    >
      <div className="container-head">
        <div className="head-logo">
          <img
            className="share-logo-img"
            alt=""
            src={require('../assets/images/menu-share/plain-logo.png')}
          />
          <span>Delivery without the Fees.</span>
        </div>
        <div className="head" style={{ height: 20 }}>
          <img
            className="head-img"
            alt=""
            src={require('../assets/images/menu-share/head-img1.png')}
          />
        </div>
        {gotoAppStore('header')}
      </div>
      <div className="content">
        <div className="list-container">
          <div className="list">
            {!!mealList && !mealList.length && <NoInServiceArea />}
            {!!mealList &&
              mealList.length &&
              mealList.map((val) => {
                return (
                  <div
                    className="list-card"
                    key={val?.id}
                    onClick={() => {
                      amplitude
                        .getInstance()
                        .logEvent('home_screen_list_card', {
                          mealId: val.id,
                        });
                      history.push(`meal-detail?id=${val.id}`);
                    }}
                  >
                    {val.color && (
                      <div
                        className="card-color"
                        style={{ backgroundColor: val.color }}
                      >
                        <span>{'Lunch ' + val.mealSetChar}</span>
                        {!!val.tags && val.tags.length && (
                          <span className="card-cusine">{val.tags[0]}</span>
                        )}
                      </div>
                    )}
                    <div className="card-img-container">
                      <div className="card-sales">
                        <div className="tag-origin-price">
                          <span className="card-price">
                            ${val.estimatedMealPrice.toFixed(1)}
                          </span>
                        </div>
                        <img
                          alt=""
                          className="card-sales-img"
                          src={require('../assets/images/menu-share/priceLow.png')}
                        />
                      </div>
                      <img
                        className="card-img"
                        alt=" "
                        src={val.firebaseImgFilename}
                      />
                    </div>
                    <div className="card-resturant">{val.title}</div>
                    <div className="card-meal">
                      <span>{val.Restaurant.title}</span>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {mealTagComponents(val.tags, 'Halal')}
                        {mealTagComponents(val.tags, 'Gluten')}
                        {mealTagComponents(val.tags, 'Veg')}
                        <span className="protion" style={{ marginLeft: 6 }}>
                          Portion:
                          <img
                            className="card-portion"
                            alt=""
                            src={require(`../assets/images/menu-share/portion-${val.reviewPortion}.png`)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            {!!mealList?.length && (
              <div className="bottom">
                <div className="bottom-txt1">Want lunch for $7.99?</div>
                <div className="bottom-txt2">Download NoshPass Now!</div>
                {gotoAppStore('footer')}
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading">
          <Spin
            spinning={true}
            delay={100}
            size="large"
            className="spin"
          ></Spin>
        </div>
      )}
    </div>
  );
};

export default SaturdayPage;
